import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Flex,
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  Text,
  Stack,
  Link,
  useBreakpointValue
} from '@chakra-ui/react'
import { RiNotificationLine, RiInformationLine } from 'react-icons/ri'
import { format, utcToZonedTime } from "date-fns-tz";

import { api } from '../../services/api';

type NotificationData = {
  id: string;
  read: boolean;
  title: string;
  content: string;
  created_at: string;
  createdAtFormatted: string;
}

export function NotificationNav() {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
})

  const hasUnread = useMemo(() => {
    let hasUnread = false
    notifications.map(notification => {
      if (!notification.read) hasUnread = true
      return notification
    })
    return hasUnread
  }, [notifications])

  const handleAllMarkAsRead = useCallback(async () => {
    await api.post(`/notifications/read/all`);

    api.get<NotificationData[]>('/notifications').then(response => {
      const { data } = response;

      const notificationData = data.map(notification => {
        return {
          ...notification,
          createdAtFormatted: format(
            utcToZonedTime(notification.created_at, 'America/Sao_Paulo'),
            'dd/MM/yyyy HH:mm'
          )
        }
      })

      setNotifications(notificationData)
    })
  }, [])

  useEffect(() => {
    api.get<NotificationData[]>('/notifications').then(response => {
      const { data } = response;

      const notificationData = data.map(notification => {
        return {
          ...notification,
          createdAtFormatted: format(
            utcToZonedTime(notification.created_at, 'America/Sao_Paulo'),
            'dd/MM/yyyy HH:mm'
          )
        }
      })

      setNotifications(notificationData)
    })
  }, [])

  return (
    <HStack
      zIndex="99"
      py="1"
      color="blue.900"
      borderColor="blue.900"
    >
      <Menu placement="bottom-end">
        <MenuButton
          position="relative"
          _hover={{
            color: "green.500"
          }}
        >
          <Icon
            as={RiNotificationLine}
            fontSize="20"
            fill={isWideVersion ? 'blue.900' : 'white'}
          />
          {hasUnread &&
            <Text
              position="absolute"
              top="1px"
              right="-1px"
              borderRadius="50%"
              bg="red.500"
              w="2"
              h="2"
            />
          }
        </MenuButton>
        <MenuList
          w={["100vw", null, null, "400px"]}
          maxH="400px"
          p="4"
          color="blue.900"
          overflow="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: "var(--chakra-colors-gray-400)",
              borderRadius: '25px',
            },
          }}
        >
          <Flex>
            <Text fontWeight={'bold'}>Notificações</Text>
            <Button
              variant="link"
              ml="auto"
              color="green.500"
              fontSize="xs"
              textTransform={'uppercase'}
              onClick={() => handleAllMarkAsRead()}
            >
              Marcar todas como lidas
            </Button>
          </Flex>
          <MenuDivider />
          <Stack
            divider={<MenuDivider />}
          >
            {notifications.length > 0
              ? notifications.map(notification => (
                <Link
                  to={`/notification/${notification.id}`}
                  href={`/notification/${notification.id}`}
                >
                  <Flex
                    key={notification.id}
                    align={'center'}
                  >
                    <Box position="relative" mr="4">
                      {!notification.read
                        && <Text
                            position="absolute"
                            top="1px"
                            right="-1px"
                            borderRadius="50%"
                            bg="red.500"
                            w="2"
                            h="2"
                          />
                      }
                      <Icon
                        as={RiInformationLine}
                        fontSize="28"
                      />
                    </Box>
                    <Flex flexDir="column">
                      <Text fontSize="sm" fontWeight={'medium'}>{notification.title}</Text>
                      <Text fontSize="xs">{notification.createdAtFormatted}</Text>
                    </Flex>
                  </Flex>
                </Link>
              ))
              : (
                <Text>Sem notificações</Text>
              )
            }
          </Stack>
        </MenuList>
      </Menu>
    </HStack>
  )
}
