import {
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react"

interface MyCreditsHeaderProps {
  balance: number;
}

export function MyCreditsHeader({ balance }: MyCreditsHeaderProps) {
  return (
    <StatGroup flexDir={["column", "row"]}>
      <Stat
        p="4"
        w="100%"
        color="blue.900"
        boxShadow="lg"
      >
        <StatLabel>Saldo de Créditos</StatLabel>
        <StatNumber>{balance}</StatNumber>
      </Stat>
    </StatGroup>
  )
}
