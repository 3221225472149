import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
} from "@chakra-ui/react"
import {
  RiMailCheckLine,
  RiMailCloseLine
} from "react-icons/ri";

type mailLog = {
  id: string;
  error: boolean;
  content: string;
  createdAtFormatted: string;
}

interface MailLogTableProps {
  mailLogs: mailLog[];
}

export function MailLogTable({ mailLogs }: MailLogTableProps) {
  return (
    <Box maxW="100%" overflow="auto">
      <Table
        maxW="100%"
        overflow="auto"
        variant="simple"
        color="blue.900"
      >
        <Thead>
          <Tr>
            <Th>Status</Th>
            <Th>Data do envio</Th>
            <Th>Mensagem</Th>
          </Tr>
        </Thead>
        <Tbody>
          {mailLogs.map(mailLog => (
            <Tr key={mailLog.id}>
              <Td>{
                mailLog.error
                  ? <Icon as={RiMailCloseLine} mr="1" fill="red" fontSize="30" />
                  : <Icon as={RiMailCheckLine} mr="1" fill="green" fontSize="30" />
              }</Td>
              <Td color="blue.900">{mailLog.createdAtFormatted}</Td>
              <Td>{mailLog.content}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
