import axios, { AxiosError } from 'axios';

let localToken = localStorage.getItem('@praktika:token');
let localRefreshToken = localStorage.getItem('@praktika:refresh_token')
let isRefreshing = false;
let failedRequestsQueue: any[] = []

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localToken}`
  }
});

api.interceptors.response.use(
  (response) => {
    return response;
  }, (error: AxiosError) => {
  if (error.response?.status === 401) {
    if (error.response.data?.code === 'token.expired') {
      localToken = localStorage.getItem('@praktika:token');
      localRefreshToken = localStorage.getItem('@praktika:refreshToken')

      const originalConfig = error.config;

      if (!isRefreshing) {
        isRefreshing = true;

        api.post('/refresh-token', {
          token: localRefreshToken,
        }).then(response => {
          localStorage.setItem('@praktika:token', response.data.token)
          localStorage.setItem('@praktika:refreshToken', response.data.refresh_token)

          api.defaults.headers['Authorization'] = `Bearer ${response.data.token}`

          failedRequestsQueue.forEach(request => request.resolve(response.data.token))
          failedRequestsQueue = [];
        }).catch(err => {
          failedRequestsQueue.forEach(request => request.reject(err))
          failedRequestsQueue = [];

          localStorage.setItem('@praktika:signOut', 'true')
        }).finally(() => {
          isRefreshing = false
        });
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          resolve: (token: string) => {
            originalConfig.headers['Authorization'] = `Bearer ${token}`

            resolve(api(originalConfig))
          },
          reject: (err: AxiosError) => {
            reject(err)
          }
        })
      })
    }
  }

  return Promise.reject(error);
})
