import { Badge, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { DashboardDrawer } from "../../../components/CustonDrawer/DashboardDrawer";

type teacher = {
  name: string,
  email: string,
}
interface ClassTabProps {
  startHour: string;
  endHour: string;
  title: string;
  teacher: teacher;
  isCanceled?: boolean;
  eventId: string;
  eventDescription: string;
}

export function ClassTab({
  startHour,
  endHour,
  title,
  teacher,
  isCanceled = false,
  eventId,
  eventDescription
}: ClassTabProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Stack
        w="100%"
        px="4"
        py="2"
        direction={["column", "column"]}
        spacing="0"
        border="1px"
        borderRadius="5"
        boxShadow="lg"
        onClick={onOpen}
        cursor="pointer"
      >
        <Text fontSize="xl" color="blue.700">
          <strong>{title}</strong>
        </Text>
        <Text fontSize="sm" color="blue.700">
          {startHour} - {endHour}
          {isCanceled && <Badge ml="2" variant="solid" colorScheme="red">Cancelada</Badge>}
        </Text>
      </Stack>
      <DashboardDrawer
        isOpen={isOpen}
        onClose={onClose}
        eventId={eventId}
        title={title}
        description={eventDescription}
        teacher={teacher}
      />
    </>
  )
}
