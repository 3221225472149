import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Spinner,
  useBreakpointValue,
} from '@chakra-ui/react';
import { RiMenuLine } from 'react-icons/ri';

import logoPraktika from '../../assets/praktika-branco.png';
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext';

import { Profile } from './Profile';

interface HeaderProps {
  title?: string;
  isLoading?: boolean;
  isFetching?: boolean;
}

export function Header({ title, isLoading = false, isFetching = false }: HeaderProps) {
  const { onOpen } = useSidebarDrawer()

  const isWideVersion = useBreakpointValue({
      base: false,
      lg: true,
  })

  return (
    <Flex
      as="header"
      w="100%"
      h="20"
      minH="20"
      mx="auto"
      my="2"
      px={["6", null, null, "0"]}
      justifyContent="space-between"
      align="center"
    >
      { !isWideVersion &&
        <IconButton
          aria-label="Open navigation"
          icon={<Icon as={RiMenuLine} />}
          fontSize="24"
          variant="unstyled"
          onClick={onOpen}
          mr="2"
        />
      }

      <Flex
        justifyContent="space-between"
        w='100%'
        align="center"
      >
        { !isWideVersion && <Box /> }

        { !isWideVersion && (
          <Box>
            <Image
              src={logoPraktika}
              alt="PrAktiKa"
              w="180px"
            />
          </Box>
        )}
        { isWideVersion &&
          <Heading color="blue.900">
            {title}
            { !isLoading && isFetching && <Spinner size="sm" color="gray.500" ml="4" />}
          </Heading>
        }

        <Profile showProfileData={isWideVersion} />
      </Flex>
    </Flex>
  )
}
