import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Flex,
  Icon,
  Text,
  Textarea,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { RiUserUnfollowLine } from "react-icons/ri";

import { ButtonDefault } from "../../ButtonDefault";
import { useCallback, useRef, useState } from "react";
import { useMutation } from "react-query";
import { api } from "../../../services/api";

interface DashboardDrawerContentProps {
  user: {
    id: string;
    name: string;
    mail: string;
  };
  subject?: string;
  eventId: string;
}

interface RemoveScheduleProps {
  eventId: string;
  userId: string;
}

export function DashboardDrawerContent({ user, subject, eventId }: DashboardDrawerContentProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  const toast = useToast();
  const [mailDescription, setMailDescription] = useState('');

  const handleMailDescription = useCallback((event) => {
    setMailDescription(event.target.value);
  }, []);

  const removeSchedule = useMutation(async ({
    eventId,
    userId
  }: RemoveScheduleProps) => {
    await api.post('/schedules/remove/student', { eventId, userId, mailDescription });
  });

  const handleRemove = useCallback(async () => {
    try {
      await removeSchedule.mutateAsync({ eventId, userId: user.id });

      onClose()
      window.location.reload()
    } catch {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }, [onClose, toast, removeSchedule, eventId, user]);

  return (
    <>
      <Box>
        <Flex
          align="center"
          justifyContent={"space-between"}
        >
          <Flex>
            <Avatar size="md" name={user.name} />

            <Box ml="2">
              <Text color="blue.900" fontWeight="bold">
                {user.name}
              </Text>
              <Text color="blue.800" fontSize="small">
                {user.mail}
              </Text>
            </Box>
          </Flex>

          <ButtonDefault
            w={"30px"}
            h={"35px"}
            attencion
            leftIcon={<Icon fontSize="15" as={RiUserUnfollowLine} />}
            iconSpacing={0}
            onClick={onOpen}
          />
        </Flex>
        {!!subject &&
          <Box
            mt="2"
            ml="2"
          >
            <Text
              fontWeight="bold"
              fontSize="sm"
              color="gray.300"
            >
              Assunto desejado
            </Text>
            <Text>
              {subject}
            </Text>
          </Box>
        }
      </Box>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        isCentered
        size="xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="bold" textColor="blue.900">
              Remover aluno - {user.name}
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" textColor="blue.900">
              Tem certeza que deseja remover o aluno dessa aula?
            </AlertDialogBody>
            <AlertDialogBody textColor="red">
              Não será possível desfazer esta ação depois.
            </AlertDialogBody>

            <Box p="5">
              <Textarea
                onChange={handleMailDescription}
                color="blue.800"
                resize="none"
                placeholder="Mensagem padrão:&#10;Gostaríamos de informar que os administradores identificaram que esta aula pode não ser adequada ao seu nível atual.&#10;Com o objetivo de garantir que você sempre desfrute da melhor experiência de aprendizado possível, tomamos a decisão de cancelar sua inscrição para esta aula específica.&#10;"
                required
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: "var(--chakra-colors-gray-200)",
                    borderRadius: '25px',
                  },
                }}
              />
            </Box>

            <AlertDialogFooter>
              <ButtonDefault text="Cancelar" w="100px" info onClick={onClose} />
              <ButtonDefault text="Remover" w="100px" attencion onClick={handleRemove} ml={3} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
