import { Tag as ChakraTag, useBreakpointValue } from "@chakra-ui/react";

interface TagProps {
  id: string;
  tagName: string;
}

export function Tag({ id, tagName }: TagProps) {
  const isWideVersion = useBreakpointValue({
    base: true,
    sm: false,
  })

  if (isWideVersion) {
    return (
      <ChakraTag id={id} size="sm">
        {tagName}
      </ChakraTag>
    )
  }

  return (
    <ChakraTag id={id} size="md">
      {tagName}
    </ChakraTag>
  )
}
