import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  SimpleGrid,
  Spinner,
  Textarea,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState, useRef, } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query';
import { Select as ChakraReactSelect } from "chakra-react-select";
import { format } from "date-fns";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import { utcToZonedTime } from "date-fns-tz";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type CouponData = {
  id: string;
  code: string;
}

type PromotionData = {
  id: string;
  message: string;
  promotion_date: string;
  coupon_id: string;
  coupon: CouponData;
  promotionDateFormatted: string;
}

type UpdatePromotionFormData = {
  name: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type CouponSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

const updatePromotionFormSchema = Yup.object().shape({
  message: Yup.string().required('Mensagem obrigatória'),
  promotion_date: Yup.date()
    .required('Data obrigatória')
    .test(
      'min-date',
      `A data não deve ser inferior a ${format(new Date(), 'dd/MM/yyyy')}`,
      async (value) => {
        if (value) {
          return value && format(value, 'yyyy-MM-dd') >= format(new Date(), 'yyyy-MM-dd')
        }

        return true
      }
    ),
})

export function UpdatePromotion() {
  const toast = useToast()

  const couponInputRef = useRef<CouponSelectInputProps>();

  const cancelRef = useRef(null)

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [couponsData, setCouponsData] = useState<CouponData[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [promotionData, setPromotionData] = useState({} as PromotionData);

  const { id: promotionId } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true)

    try {
      api.get<PromotionData>(`promotions/${promotionId}`).then(
        response => {
          const { data } = response;
          const promotionDateUTC = utcToZonedTime(data.promotion_date, 'America/Sao_Paulo');

          setPromotionData({
            ...data,
            promotionDateFormatted: format(promotionDateUTC, 'yyyy-MM-dd'),
          })

          setIsLoading(false)
        }
      );
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsLoading(false)
    }
  }, [toast, promotionId])

  useEffect(() => {
    api.get("/coupons/list/available").then(response => setCouponsData(response.data))
  }, [])

  const updatePromotion = useMutation(async (promotion: UpdatePromotionFormData) => {
    const response = await api.post(`/promotions/update/${promotionId}`, promotion)

    return response.data.promotion;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('promotions')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(updatePromotionFormSchema),
    context: { promotion_id: promotionId }
  })

  const { errors } = formState

  const handleUpdatePromotion: SubmitHandler<UpdatePromotionFormData> = async (values: any) => {
    try {

      await updatePromotion.mutateAsync({
        ...values,
        promotion_date: utcToZonedTime(
          values.promotion_date,
          'America/Sao_Paulo'
        ).toISOString(),
        coupon_id: couponInputRef.current?.state.value.value,
      });

      toast({
        title: "Promoção alterada",
        description: "Alteração realizada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleDelete = async () => {
    setIsOpenAlert(false)
    setIsSubmitting(true)
    try {
      await api.delete(`/promotions/${promotionId}`)

      queryClient.invalidateQueries('promotions')

      toast({
        title: "Promoção excluída",
        description: "Promoção excluído com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setIsSubmitting(false)

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <>
      <Flex direction="column" h="100vh">
        { !isWideVersion && <Header /> }

        <ContentBase>
          <Sidebar />

          <MainBase mainTitle="Alterar promoção">
            <Box
              as="form"
              h={["", "80vh"]}
              overflow="auto"
              paddingRight="1"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: "var(--chakra-colors-gray-400)",
                  borderRadius: '25px',
                },
              }}
              onSubmit={handleSubmit(handleUpdatePromotion)}
            >
              {isLoading
              ? (
                <Flex justify="center">
                  <Spinner color="blue.900" size="xl" />
                </Flex>
              )
              : (
                <VStack spacing="8" color="blue.900">
                  <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                    <FormControl
                      alignItems="center"
                      _disabled={{
                        color: "gray.200"
                      }}
                      isRequired
                    >
                      <FormLabel htmlFor="message">
                        Mensagem
                      </FormLabel>
                      <Textarea
                        placeholder="Preencha com a mensagem adicional que será enviada no e-mail de newsletter"
                        error={errors.message}
                        defaultValue={promotionData.message}
                        css={{
                          '&::-webkit-scrollbar': {
                            width: '4px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: "var(--chakra-colors-gray-200)",
                            borderRadius: '25px',
                          },
                        }}
                        {...register('message')}
                      />
                    </FormControl>
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="200px" spacing={["6", "8"]} w="100%">
                    <Input
                      nameInput="promotion_date"
                      label="Data da promoção"
                      type="date"
                      labelSize="lg"
                      error={errors.expirarion_date}
                      defaultValue={promotionData.promotionDateFormatted}
                      isRequired
                      _disabled={{
                        color: "gray.200"
                      }}
                      {...register('promotion_date')}
                    />
                    <FormControl
                      alignItems="center"
                      _disabled={{
                        color: "gray.200"
                      }}
                    >
                      <FormLabel htmlFor="coupon" mb="4">
                        Cupom
                      </FormLabel>
                      <ChakraReactSelect
                        options={
                          couponsData.map(coupon => {
                            return (
                              { value: coupon.id, label: coupon.code }
                            )
                          })
                        }
                        defaultValue={!promotionData.coupon_id
                          ? ''
                          :{
                            label: promotionData.coupon.code,
                            value: promotionData.coupon_id
                          }
                        }
                        placeholder="Selecione o cupom"
                        closeMenuOnSelect={true}
                        error={errors.coupon}
                        ref={couponInputRef}
                      />
                    </FormControl>
                  </SimpleGrid>
                </VStack>
              ) }

              <Flex
                mt="8"
                justify={["normal", "space-between"]}
                flexDir={["column", "row"]}
              >
                <HStack spacing="4">
                  <ButtonDefault
                    text="Excluir"
                    w={["100%", "150px"]}
                    attencion
                    onClick={() => setIsOpenAlert(true)}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />
                </HStack>

                <Flex
                  w={["100%", "auto"]}
                  mt={["6", "0"]}
                  flexDir={["column", "row"]}
                >
                  <Box w={["100%", "auto"]}>
                    <ButtonDefault
                      text="Cancelar"
                      w={["100%", "150px"]}
                      info
                      isLoading={formState.isSubmitting}
                      onClick={() => window.close()}
                    />
                  </Box>

                  <ButtonDefault
                    type="submit"
                    text="Salvar"
                    w={["100%", "150px"]}
                    ml={["0", "4"]}
                    mt={["6", "0"]}
                    isLoading={formState.isSubmitting}
                  />
                </Flex>
              </Flex>
            </Box>
          </MainBase>
        </ContentBase>
      </Flex>

      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpenAlert(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="bold" textColor="blue.900">
              Excluir promoção
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" textColor="blue.900">
              Tem certeza que deseja excluir esta promoção?
            </AlertDialogBody>
            <AlertDialogBody textColor="red">
              Não será possível desfazer esta ação depois.
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonDefault text="Cancelar" w="100px" info onClick={() => setIsOpenAlert(false)} />
              <ButtonDefault text="Excluir" w="100px" attencion onClick={handleDelete} ml={3} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
