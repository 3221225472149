import { api } from "../../services/api"

async function CouponCodeExist(value?: string, id?: string): Promise<boolean> {
  if (value) {
    const { data } = await api.get(`/coupons/valid`, {
      params: {
        field: 'code',
        value,
        id,
      }
    })

    return !data
  }

  return true
}

export { CouponCodeExist }
