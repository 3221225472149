import {
  Flex,
  Text,
  useDisclosure
 } from "@chakra-ui/react";
 import ReactHtmlParser from "react-html-parser"

import { Footer } from "./Footer";
import { Header } from "./Header";
import { CardModal } from "./Modal";

interface ClassCardProps {
  canceled?: boolean;
  waiting?: boolean;
  eventId: string;
  title: string;
  description: string;
  date: string;
  startHour: string;
  duration: string;
  credit: string;
  levels?: {
    id: string;
    level: {
      name: string;
      color: string;
      variant: string;
    };
  }[]
  disabled?: boolean;
  eventHasSubject?: boolean;
  startDateISO: string;
  dayOfWeek: string;
  setHighlight?: boolean;
  forTeachers?: boolean;
  descriptionFormatted: string;
  classSubject?: string | null
}

export function ClassCard({
  canceled = false,
  waiting = false,
  disabled = false,
  eventId,
  title,
  levels,
  description,
  date,
  startHour,
  duration,
  credit,
  eventHasSubject = false,
  startDateISO,
  dayOfWeek,
  setHighlight = false,
  forTeachers = false,
  descriptionFormatted,
  classSubject = null
}: ClassCardProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const formattedDescription = ReactHtmlParser(descriptionFormatted)

  return (
    <Flex
      as="article"
      border={ setHighlight ? "2px" : "1px" }
      boxShadow="lg"
      borderColor={ setHighlight ? "green.500" : "none" }
      borderRadius="5"
      paddingX={["3", "4"]}
      py="2"
      bg={
        disabled
        ? "gray.50"
        : setHighlight
          ? "green.50"
          : "white"
      }
      flexDir="column"
      justifyContent="space-between"
    >
      <Header
        title={title}
        date={date}
        hour={startHour}
        duration={duration}
        levels={levels}
        dayOfWeek={dayOfWeek}
        forTeachers={forTeachers}
      />

      <Text
        color="blue.900"
        fontSize={["lg", "md"]}
        my="4"
        noOfLines={2}
      >
        {formattedDescription || description}
      </Text>

      <Footer
        onOpen={onOpen}
        canceled={canceled}
        waiting={waiting}
        disabled={disabled}
        credit={credit}
      />

      <CardModal
        isOpen={isOpen}
        onClose={onClose}
        eventId={eventId}
        title={title}
        description={descriptionFormatted || description}
        duration={duration}
        waiting={waiting}
        canceled={canceled}
        startDate={date}
        startHour={startHour}
        credit={credit}
        eventHasSubject={eventHasSubject}
        startDateISO={startDateISO}
        classSubject={classSubject}
      />
    </Flex>
  );
}
