import { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext';

type UseCanParams = {
  isAdmin?: boolean;
  isTeacher?: boolean;
  isCompany?: boolean;
  not?: boolean;
}

export function useCan({ isAdmin = false, isTeacher = false, isCompany = false, not = false }: UseCanParams) {
  const { user, isAuthentivated } = useContext(AuthContext)

  if (!isAuthentivated) {
    return false;
  }

  if (!not && isAdmin) {
    if (!user.isAdmin) {
      return false
    }
  }

  if (!not && isTeacher) {
    if ((!user.isAdmin && !user.isTeacher)) {
      return false
    }
  }

  if (!not && isCompany) {
    if ((!user.isAdmin && !user.isCompany)) {
      return false
    }
  }

  if (not && isCompany) {
    if (user.isCompany) {
      return false
    }
  }

  return true;
}
