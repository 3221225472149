function RemoveAccent(text: string): string {
  const textWithoutAccent = text
    .replace(/[ÀÁÂÃÄÅ]/,"A")
    .replace(/[àáâãäå]/,"a")
    .replace(/[ÉÈÊË]/,"E")
    .replace(/[éèêë]/, 'e')
    .replace(/[ÍÌÎÏ]/, 'i')
    .replace(/[íìîï]/, 'i')
    .replace(/[ÓÒÕÔÖ]/, 'o')
    .replace(/[óòõôö]/, 'o')
    .replace(/[ÚÙÛÜ]/, 'u')
    .replace(/[úùûü]/, 'u')
    .replace(/[Ç]/,"C")
    .replace(/[ç]/,"c");

  return textWithoutAccent
}

export { RemoveAccent }
