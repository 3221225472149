import { Box, Text, Textarea } from "@chakra-ui/react";
import ReactHtmlParser from "react-html-parser"

interface WaitingBodyProps {
  description: string;
  hasBalance: boolean;
  handleChangeSugestion: (event: any) => void;
}

export function WaitingBody({ description, hasBalance, handleChangeSugestion }: WaitingBodyProps) {
  const formattedDescription = ReactHtmlParser(description)

  return (
    <>
      <Box marginY="1rem">
        <Text marginTop=".8rem" fontWeight={"bold"} fontSize={"sm"}>DESCRIÇÃO DA AULA</Text>
        <Text marginTop=".3rem" paddingX=".3rem">
          {formattedDescription}
        </Text>
      </Box>

      <Text
        my="4"
        color="yellow.500"
        fontSize="md"
      >
        A turma para esta aula está completa. Caso queira, pode se inscrever na nossa lista de espera. Caso alguma vaga seja aberta, vamos avisar você por e-mail.
      </Text>

      <Textarea
        mb="4"
        onChange={handleChangeSugestion}
        placeholder="(Opcional) Digite aqui alguma sugestão para a aula. Por exemplo: qual melhor dia e horário para você, o que gostaria de ver na aula etc"
        resize="none"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: "var(--chakra-colors-gray-200)",
            borderRadius: '25px',
          },
        }}
      />

      { !hasBalance &&
        <>
          <Text fontSize="md">Lembro que você <strong>não possui saldo suficiente</strong> para fazer essa aula.</Text>
          <Text fontSize="md">Que tal comprar mais créditos para, caso surja alguma vaga, já consiga realiza a inscrição?</Text>
        </>
      }
    </>
  )
}
