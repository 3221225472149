import {
  Box,
  Flex,
  Text,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { Queue, useQueues } from "../../../services/hooks/useQueues";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import MainActions from "../../../components/MainActions";
import { Column } from "react-table";
import { MainTable } from "../../../components/MainTable";

export function QueuesList() {
  const [searchField, setSearchField] = useState('');
  const [allQueues, setAllQueues] = useState<Queue[]>([]);

  const { data, isLoading, isFetching, error } = useQueues()

  const fileName = `Fila-de-espera-${Date.now()}.csv`;
  const csvHeaders = [
    {
      label: 'Aula',
      key: 'event.title'
    },
    {
      label: 'Data de Início',
      key: 'startDateFormated'
    },
    {
      label: 'Usuário',
      key: 'user.name'
    },
    {
      label: 'E-mail',
      key: 'user.email'
    },
    {
      label: 'Sugestão',
      key: 'sugestion'
    },
  ]

  useEffect(() => {
    if (data) {
      setAllQueues(data)
    }
  }, [data])

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    if (data) {
      if (searchField) {
        const queuesFiltered = data.filter( queue =>
          queue.event.title.toLowerCase().match(searchField.toLocaleLowerCase())
          || queue.user.name.toLowerCase().match(searchField.toLocaleLowerCase())
        )

        setAllQueues(queuesFiltered)

        return
      }

      setAllQueues(data)
    }
  }, [searchField, data])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const columns: Column<Queue>[] = [
    {
      Header: "Aula",
      //  @ts-ignore TYPE NEEDS FIXING
      accessor: "event.title",
      isSortable: true,
    },
    {
      Header: "Data de Início",
      //  @ts-ignore TYPE NEEDS FIXING
      accessor: "event.start_date",
      formattedValue: "startDateFormated",
      isSortable: true,
    },
    {
      Header: "Aluno",
      //  @ts-ignore TYPE NEEDS FIXING
      accessor: "user.name",
      isSortable: true,
    },
    {
      Header: "E-mail",
      //  @ts-ignore TYPE NEEDS FIXING
      accessor: "user.email",
      isSortable: true,
    },
    {
      Header: "Sugestões",
      accessor: "sugestion",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
  ];

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Fila de espera"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <MainActions
            handleChangeSearch={handleChangeSearch}
            handleOnPressEnter={handleOnPressEnter}
            handleSearch={handleSearch}
            searchPlaceHolder="Digite o título da aula ou o nome do usuário que deseja procurar..."
            csvDownload={{
              csvData: allQueues,
              csvHeaders: csvHeaders,
              csvFileName: fileName,
            }}
          />

          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner />
              </Flex>
            ) : error ? (
              <Flex>
                <Text>Falha ao obter lista de filas de espera</Text>
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={allQueues}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
