import { useQuery } from "react-query";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { api } from "../api";

export type Event = {
  id: string;
  title: string;
  description: string;
  start_date: string;
  startDateFormated: string;
  startHourFormated: string;
  end_date: string;
  endHourFormatted: string;
  endDateFormated: string;
  teacher_id: string;
  name: string;
  is_canceled: boolean;
  credit: string;
  levels: string;
  request_subject: boolean;
  has_highlight: boolean;
  for_teachers: boolean;
  class_subject_id?: string;
  subject?: string;
}

export async function getEvents(): Promise<Event[]> {
  const response = await api.get<any>('/events')

  const { data } = response

  const events = data.map((event: Event) => {
    const startUTCDate = utcToZonedTime(event.start_date, 'America/Sao_Paulo');
    const endUTCDate = utcToZonedTime(event.end_date, 'America/Sao_Paulo');

    return {
      ...event,
      startDateFormated: format(startUTCDate, 'dd/MM/yyyy'),
      startHourFormated: format(startUTCDate, 'HH:mm'),
      endHourFormatted: format(endUTCDate, 'HH:mm'),
    }
  })

  return events;
}

export function useEvents() {
  return useQuery(['events'], () => getEvents(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
