import {
  Box,
  Checkbox,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  SimpleGrid,
  Text,
  useToast
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";

import { ButtonDefault } from "../../ButtonDefault";
import { Input } from "../../Form/Input";
import { useAuth } from "../../../services/hooks/useAuth";
import { useMutation } from "react-query";
import { queryClient } from "../../../services/queryClient";
import { ProfileFormData } from "../../../pages/Profile";
import { useCoupon } from "../../../services/hooks/useCoupons";

interface PurchaseModalPros {
  isOpen: boolean;
  onClose: () => void;
  product_id: string;
  product_value: string;
}

type PurchaseFormData = {
  street_name: string;
  street_number: string;
  zip_code: string;
  area_code: string;
  phone: string;
  document: string;
}

const signUpFormSchema = Yup.object().shape({
  street_name: Yup.string(),
  street_number: Yup.string().when(['street_name'], {
    is: (street_name: string) => street_name.length > 0,
    then: Yup.string()
      .required('O número deve ser informado')
      .matches(/^\d+$/, 'Digitar somente números.')
  }),
  zip_code: Yup.string().when(['street_name'], {
    is: (street_name: string) => street_name.length > 0,
    then: Yup.string()
      .required('CEP obrigatório')
      .length(8, 'O CEP deve conter 8 dígitos')
  }),
  area_code: Yup.string()
    .when(['phone'], {
      is: (phone: string) => phone.length > 0,
      then: Yup.string().length(2, 'DDD deve conter 2 dígitos'),
    })
    .test(
      'areaLenght',
      'DDD deve conter 2 dígitos',
      async(value) => {
        if (value) {
          if (value.length !== 2 ) {
            return false
          }
        }

        return true
      }
    ),
  phone: Yup.string()
    .test(
      'phoneLenght',
      'O telefone deve conter 8 ou 9 dígitos',
      async(value, testContext) => {
        if (value) {
          if (value.length < 8 || value.length > 9) {
            return false
          }
        } else if (testContext.parent.area_code.length > 0) {
          return false
        }

        return true
      }
    )
    .test(
      'onlyNumber',
      'O telefone deve conter somente números',
      async(value, testContext) => {
        if (value) {
          if (!/^\d+$/.test(value)) {
            return false
          }
        }

        return true
      }
    ),
  document: Yup.string()
    .test(
      'onlyNumber',
      'O CPF deve conter somente números',
      async(value) => {
        if (value) {
          if (!/^\d+$/.test(value)) {
            return false
          }
        }

        return true
      }
    )
    .test(
      'cpfLength',
      'O CPF deve conter 11 dígitos',
      async(value) => {
        if (value) {
          if (value.length !== 11) {
            return false
          }
        }

        return true
      }
    ),
})

export function PurchaseModal({
  isOpen,
  onClose,
  product_id,
  product_value,
}: PurchaseModalPros) {
  const [isLoading, setIsLoading] = useState(false);
  const [saveInfo, setSaveInfo] = useState(false);

  const toast = useToast();

  const { user, updateUser } = useAuth();
  const { valid, coupon } = useCoupon();

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(signUpFormSchema)
  })

  const { errors } = formState;

  const updateProfile = useMutation(async (user: ProfileFormData) => {
    await api.post('profile', user)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('users')
    }
  });

  const toastCouponExpired = useCallback(async () => (
    toast({
      title: "Cupom expirou :(",
      description: "Infelizmente o cupom que estava utilizando expirou.",
      status: "warning",
      duration: 4000, // 4 seconds,
      isClosable: true,
      position: "top-right",
    })
  ), [toast]);

  const toastMessageError = useCallback(async () => (
    toast({
      title: "Opa...",
      description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
      status: "error",
      duration: 3000, // 3 seconds,
      isClosable: true,
      position: "top-right",
    })
  ), [toast]);

  const handleChangeCheckbox = useCallback(() => {
    setSaveInfo(!saveInfo)
  }, [saveInfo])

  const handlePurchase: SubmitHandler<PurchaseFormData> = async (values) => {
    setIsLoading(true)
    try {
      const {
        street_name,
        street_number,
        zip_code,
        area_code,
        phone,
        document,
      } = values;

      const { name, username, email, birth_date } = user;

      if (saveInfo) {
        await updateProfile.mutateAsync({
          ...values,
          name,
          username,
          email,
          birth_date,
        });

        updateUser({
          name,
          email,
          username,
          area_code: values.area_code,
          document: values.document,
          phone: values.phone,
          street_name: values.street_name,
          street_number: values.street_number,
          zip_code: values.zip_code,
        })
      }

      const couponStillValid = coupon.code ? await valid(coupon.code) : true

      if (!couponStillValid) {
        throw new Error('coupon_expired')
      }

      const { data } = await api.post('/purchase-orders/reference', {
        product_id,
        product_value,
        street_name,
        street_number,
        zip_code,
        area_code,
        phone,
        document,
        couponId: coupon.id
      })

      window.location.href = data.redirectURL;
    } catch (err: any) {
      if (err.message === 'coupon_expired') {
        toastCouponExpired()
      } else {
        toastMessageError()
      }
    }

    setIsLoading(false)
    onClose()
  }

  return (
    <Modal
      size="2xl"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(handlePurchase)}
      >
        <ModalHeader color="blue.900">
          <Heading>
            Dados para compra
          </Heading>
        </ModalHeader>
        <ModalCloseButton color="blue.900" />
        <ModalBody
          my="2"
          fontSize="xl"
          color="blue.900"
        >
          <Box
            h={["60vh", null, null, "100%"]}
            overflow={["auto", null, null, "inherit"]}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-200)",
                borderRadius: '25px',
              },
            }}
          >
            <Text mb="4">
              Para uma maior seguraça no processo de compra, por favor preencha as informações abaixo:
            </Text>

            <Stack w="100%" spacing="4">
              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="street_name"
                  label="Endereço"
                  error={errors.street_name}
                  defaultValue={user.street_name}
                  onSystem
                  {...register('street_name')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="street_number"
                  label="Número"
                  error={errors.street_number}
                  defaultValue={user.street_number}
                  onSystem
                  {...register('street_number')}
                />

                <Input
                  nameInput="zip_code"
                  label="CEP"
                  error={errors.zip_code}
                  defaultValue={user.zip_code}
                  onSystem
                  {...register('zip_code')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="area_code"
                  label="DDD"
                  error={errors.area_code}
                  defaultValue={user.area_code}
                  onSystem
                  {...register('area_code')}
                />

                <Input
                  nameInput="phone"
                  label="Telefone"
                  error={errors.phone}
                  defaultValue={user.phone}
                  onSystem
                  {...register('phone')}
                />

                <Input
                  nameInput="document"
                  label="CPF"
                  error={errors.document}
                  defaultValue={user.document}
                  onSystem
                  {...register('document')}
                />
              </SimpleGrid>
            </Stack>

            <Checkbox
              colorScheme="green"
              onChange={handleChangeCheckbox}
              mt="4"
            >
              Desejo manter esse dados salvos em meu cadastro.
            </Checkbox>
          </Box>
        </ModalBody>

        <ModalFooter flexDir={["column", "row"]}>
          <ButtonDefault
            w="100%"
            mr={["0", "3"]}
            mb={["2", "0"]}
            py="7"
            attencion
            fontSize="large"
            text="Cancelar"
            onClick={onClose}
            isLoading={isLoading}
          />
          <ButtonDefault
            type="submit"
            w="100%"
            py="7"
            info
            fontSize="large"
            text="Continuar"
            isLoading={isLoading}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
