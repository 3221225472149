import {
  Box,
  Flex,
  Spinner,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Column } from "react-table";

import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { ButtonDefault } from "../../components/ButtonDefault";
import { api } from "../../services/api";
import { GiftModal } from "../../components/GiftModal";
import ContentBase from "../../components/ContentBase";
import MainBase from "../../components/MainBase";
import MainActions from "../../components/MainActions";
import { MainTable } from "../../components/MainTable";
import { RemoveCreditModal } from "../../components/RemoveCreditModal";

export type Participation = {
  isChecked: boolean;
  user_id: string;
  name: string;
  email: string;
  created_at: string;
  participation: string;
  total_spent: string;
  registeredDate: string;
  gift_credits: string;
  last_gift: string;
  dateOfLastGift: string;
  company_name: string | null;
}

export function GiftSender() {
  const [searchField, setSearchField] = useState('');
  const [users, setUsers] = useState<Participation[]>([]);
  const [showUsers, setShowUsers] = useState<Participation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<Participation[]>([]);

  const { isOpen: isOpenGift, onOpen: onOpenGift, onClose: onCloseGift } = useDisclosure();
  const { isOpen: isOpenRemoveCredit, onOpen: onOpenRemoveCredit, onClose: onCloseRemoveCredit } = useDisclosure();

  useEffect(() => {
    setIsLoading(true)
    api.get<Participation[]>("/schedules/participations")
      .then(response => {
        const users = response.data.map((participations: Participation) => {
          const registeredUTCDate = utcToZonedTime(participations.created_at, 'America/Sao_Paulo');

          return {
            ...participations,
            isChecked: false,
            registeredDate: format(registeredUTCDate, 'dd/MM/yyyy'),
            dateOfLastGift: participations.last_gift
              ? format(utcToZonedTime(participations.last_gift, 'America/Sao_Paulo'), 'dd/MM/yyyy')
              : '  /  /    ',
          }
        });

        setUsers(users);
        setShowUsers(users);
        setIsLoading(false);
      })
  }, []);

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value);
  }, []);

  const handleChangeCheckbox = useCallback((e, user_id) => {
    const newUsers = [...users];
    const newShowUsers = [...showUsers];

    const userIndex = newUsers.findIndex(user => user.user_id === user_id);
    const showUserIndex = newShowUsers.findIndex(user => user.user_id === user_id);

    newUsers[userIndex].isChecked = e.target.checked;
    newShowUsers[showUserIndex].isChecked = e.target.checked;

    setUsers(newUsers)
    setShowUsers(newShowUsers)
  }, [users, showUsers]);

  const columns: Column<Participation>[] = [
    {
      Header: "",
      accessor: "isChecked",
      //  @ts-ignore TYPE NEEDS FIXING
      type: "check",
      handlerCheck: handleChangeCheckbox,
      idCheckRef: 'user_id',
    },
    {
      Header: "Aluno",
      accessor: "name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "E-mail",
      accessor: "email",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Data Cadastro",
      accessor: "created_at",
      //  @ts-ignore TYPE NEEDS FIXING
      formattedValue: "registeredDate",
      isSortable: true,
    },
    {
      Header: "Aulas Matriculadas",
      accessor: "participation",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Total Crédito Gasto",
      accessor: "total_spent",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Total Crédito Presenteado",
      accessor: "gift_credits",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Data do Último Presente",
      accessor: "last_gift",
      //  @ts-ignore TYPE NEEDS FIXING
      formattedValue: "dateOfLastGift",
      isSortable: true,
    },
  ];

  const handleUncheck = useCallback(() => {
    const uncheckUsers = [...users]

    uncheckUsers.map(user => (user.isChecked = false) )

    setUsers(uncheckUsers)
  }, [users])

  const handleSendGift = useCallback(() => {
    const getSelectedUsers: Participation[] = [];

    users.map(user => {
      if(user.isChecked) {
        getSelectedUsers.push(user)
      }

      return true
    })

    setSelectedUsers(getSelectedUsers)

    onOpenGift()
  }, [users, onOpenGift]);

  const handleSelectAll = useCallback(() => {
    const userSelected = users.map(user => {
      user.isChecked = !user.isChecked

      return user
    })

    setSelectedUsers(userSelected)
  }, [users]);

  const handleRemoveCredit = useCallback(() => {
    const getSelectedUsers: Participation[] = [];

    users.map(user => {
      if(user.isChecked) {
        getSelectedUsers.push(user)
      }

      return true
    })

    setSelectedUsers(getSelectedUsers)

    onOpenRemoveCredit()
  }, [users, onOpenRemoveCredit]);

  const handleSearch = useCallback(() => {
    if (users) {
      if (searchField) {
        const usersFiltered = users.filter(user =>
          user.name.toLowerCase().match(searchField.toLocaleLowerCase()) ||
          (user.company_name ? user.company_name.toLowerCase().match(searchField.toLocaleLowerCase()) : false)
        )

        setShowUsers(usersFiltered)

        return
      }

      setShowUsers(users)
    }
  }, [searchField, users])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Gerenciar créditos"
          isLoading={isLoading}
        >
          <MainActions
            handleChangeSearch={handleChangeSearch}
            handleOnPressEnter={handleOnPressEnter}
            handleSearch={handleSearch}
            searchPlaceHolder="Digite o nome do aluno que deseja procurar..."
          >
            <ButtonDefault
              w={["100%", null, null, "200px"]}
              minW="100px"
              mt={["2", null, null, "0"]}
              ml={["0", null, null, "2"]}
              text="Inverter seleção"
              info
              onClick={handleSelectAll}
            />
            <ButtonDefault
              w={["100%", null, null, "100px"]}
              minW="100px"
              mt={["2", null, null, "0"]}
              ml={["0", null, null, "2"]}
              text="Retirar"
              attencion
              onClick={handleRemoveCredit}
            />
            <ButtonDefault
              w={["100%", null, null, "100px"]}
              minW="100px"
              mt={["2", null, null, "0"]}
              ml={["0", null, null, "2"]}
              text="Adicionar"
              info
              onClick={handleSendGift}
            />
          </MainActions>

          <Box
            w="100%"
            h={["", null, null, "74vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : (
              <MainTable
                columns={columns}
                data={showUsers}
              />
            )}
          </Box>
        </MainBase>
      </ContentBase>

      <GiftModal
        isOpen={isOpenGift}
        onClose={onCloseGift}
        selectedUsers={selectedUsers}
        uncheckUsers={handleUncheck}
      />

      <RemoveCreditModal
        isOpen={isOpenRemoveCredit}
        onClose={onCloseRemoveCredit}
        selectedUsers={selectedUsers}
        uncheckUsers={handleUncheck}
      />
    </Flex>
  );
}
