import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  VStack,
  SimpleGrid,
  Spinner,
  Switch,
  useToast,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
  RiMailSettingsLine
} from "react-icons/ri";
import {
  GiReceiveMoney
} from "react-icons/gi";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import { UserUsernameExist } from "../../../utils/validations/UserUsernameExist";
import { UserEmailExist } from "../../../utils/validations/UserEmailExist";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import { useAuth } from "../../../services/hooks/useAuth";
import { MailLogsDrawer } from "../../../components/CustonDrawer/MailLogDrawer";
import { UserCreditsDrawer } from "../../../components/CustonDrawer/UserCreditsDrawer"

type UserData = {
  name: string;
  username: string;
  email: string;
  is_admin: boolean;
  is_teacher: boolean;
  inactivation_date: string;
  inactivation_dateFormated: string;
  street_name: string;
  street_number: string;
  zip_code: string;
  area_code: string;
  phone: string;
  // document_type: string;
  document: string;
  is_company: boolean;
  receive_email: boolean;
  receive_newsletter: boolean;
  birth_date: string;
  birthDateFormatted: string;
}

type UpdateUserFormData = {
  name: string;
  email: string;
  username: string;
  is_admin: boolean;
  is_teacher: boolean;
  street_name: string;
  street_number: string;
  zip_code: string;
  area_code: string;
  phone: string;
  // document_type: string;
  document: string;
  is_company: boolean;
  birth_date: string;
}

type ValidationData = {
  ok: boolean;
  message: string;
}

const updateUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .email('Digite um e-mail válido')
    .required('E-mail obrigatório')
    .test(
      'email-exists',
      'E-mail já cadastrado, favor informar outro.',
      async (value, testContext: any) => (
        await UserEmailExist(value, testContext.options.context.user_id)
      )
    ),
  username: Yup.string()
    .required('Usuário obrigatório')
    .test(
      'username-exists',
      'Usuário indisponível, favor informar outro.',
      async (value, testContext: any) => (
        await UserUsernameExist(value, testContext.options.context.user_id)
      )
    ),
  is_admin: Yup.boolean(),
  is_teacher: Yup.boolean(),
  street_name: Yup.string(),
  street_number: Yup.string().when(['street_name'], {
    is: (street_name: string) => street_name.length > 0,
    then: Yup.string()
      .required('O número deve ser informado')
      .matches(/^\d+$/, 'Digitar somente números.')
  }),
  zip_code: Yup.string().when(['street_name'], {
    is: (street_name: string) => street_name.length > 0,
    then: Yup.string()
      .required('CEP obrigatório')
      .length(8, 'O CEP deve conter 8 dígitos')
  }),
  area_code: Yup.string()
    .when(['phone'], {
      is: (phone: string) => phone.length > 0,
      then: Yup.string().length(2, 'DDD deve conter 2 dígitos'),
    })
    .test(
      'areaLenght',
      'DDD deve conter 2 dígitos',
      async(value) => {
        if (value) {
          if (value.length !== 2 ) {
            return false
          }
        }

        return true
      }
    ),
  phone: Yup.string()
    .test(
      'phoneLenght',
      'O telefone deve conter 8 ou 9 dígitos',
      async(value, testContext) => {
        if (value) {
          if (value.length < 8 || value.length > 9) {
            return false
          }
        } else if (testContext.parent.area_code.length > 0) {
          return false
        }

        return true
      }
    )
    .test(
      'onlyNumber',
      'O telefone deve conter somente números',
      async(value, testContext) => {
        if (value) {
          if (!/^\d+$/.test(value)) {
            return false
          }
        }

        return true
      }
    ),
  document: Yup.string()
    .test(
      'onlyNumber',
      'O documento deve conter somente números',
      async(value) => {
        if (value) {
          if (!/^\d+$/.test(value)) {
            return false
          }
        }

        return true
      }
    ),
  is_company: Yup.boolean(),
  birth_date: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value; // Converte string vazia em null
    })
    .max(new Date(), 'A data de nascimento não pode ser maior que a data atual')
    .notRequired(),
})

export function UpdateUser() {
  const toast = useToast();
  const { impersonate } = useAuth();

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userData, setUserData] = useState({} as UserData);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenUserCredits, onOpen: onOpenUserCredits, onClose: onCloseUserCredits } = useDisclosure()

  const cancelRef = useRef(null)

  const { id: userId } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get<UserData>(`users/${userId}`).then(
        response => {
          const { data } = response;

          setUserData({
            ...data,
            inactivation_dateFormated: data.inactivation_date
              ? format(utcToZonedTime(data.inactivation_date, 'America/Sao_Paulo'), 'dd/MM/yyyy HH:mm')
              : '  /  /    ',
            birthDateFormatted: data.birth_date
              ? format(new Date(data.birth_date), 'yyyy-MM-dd')
              : '  /  /    ',
          })

          setIsLoading(false)
        }
      );
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsLoading(false)
    }
  }, [toast, userId])

  const updateUser = useMutation(async (user: UpdateUserFormData) => {
    const response = await api.post(`/users/update/${userId}`, user)

    return response.data.user;
  }, {
    onSuccess: () => {
      queryClient.refetchQueries('users')
    }
  });

  const deleteUser = useMutation(async (userId: string) => {
    await api.delete(`/users/${userId}`)
  }, {
    onSuccess: () => {
      queryClient.refetchQueries('users')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(updateUserFormSchema),
    context: { user_id: userId }
  })

  const { errors } = formState

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = async (values) => {
    try {
      await updateUser.mutateAsync(values);

      toast({
        title: "Usuário alterado",
        description: "Alteração realizada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleDelete = async () => {
    setIsOpenAlert(false)
    setIsSubmitting(true)
    try {
      const { data: canDelete } = await api.get<ValidationData>(`/users/valid/delete`, {
        params: {
          user_id: userId,
        }
      })

      if (!canDelete.ok) {
        queryClient.refetchQueries('users')
        toast({
          title: "Exclusão não é permitida",
          description: canDelete.message,
          status: "error",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })
        setIsSubmitting(false)

        return
      }

      await deleteUser.mutateAsync(userId);

      toast({
        title: "Usuário excluído",
        description: "Usuário excluído com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setIsSubmitting(false)
      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const handleDisabled = async () => {
    setIsSubmitting(true)
    try {
      await api.post(`/users/disabled/${userId}`)

      queryClient.refetchQueries('users')

      toast({
        title: "Usuário inativado",
        description: "Usuário inativado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const handleImpersonate = async () => {
    try {
      await impersonate(userId);
    } catch (err) {
      toast({
        title: "Impersonar usuário",
        description: "Houve um problema ao tentar impersonar o usuário",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleActivated = async () => {
    setIsSubmitting(true)
    try {
      await api.post(`/users/disabled/${userId}`)

      queryClient.refetchQueries('users')

      toast({
        title: "Usuário ativo",
        description: "Usuário está novamente ativo",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <>
      <Flex direction="column" h="100vh">
        { !isWideVersion && <Header /> }

        <ContentBase>
          <Sidebar />

          <MainBase mainTitle="Alterar usuário">
            <Box
              as="form"
              h={["", "80vh"]}
              overflow="auto"
              paddingRight="1"
              onSubmit={handleSubmit(handleUpdateUser)}
            >
              {isLoading
                ? (
                  <Flex justify="center">
                    <Spinner color="blue.900" size="xl" />
                  </Flex>
                )
                : (
                    <VStack spacing="8" color="blue.900">
                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <Input
                          nameInput="name"
                          label="Nome completo"
                          labelSize="lg"
                          error={errors.name}
                          defaultValue={userData.name}
                          isRequired
                          {...register('name')}
                        />
                        <Input
                          nameInput="email"
                          label="E-mail"
                          labelSize="lg"
                          error={errors.email}
                          defaultValue={userData.email}
                          isRequired
                          {...register('email')}
                        />
                      </SimpleGrid>

                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <Input
                          w="100%"
                          nameInput="username"
                          label="Usuário"
                          labelSize="lg"
                          error={errors.username}
                          defaultValue={userData.username}
                          isRequired
                          isReadOnly
                          _readOnly={{
                            color: "gray.200"
                          }}
                          {...register('username')}
                        />
                        <Input
                          nameInput="inactivation_date"
                          type="text"
                          label="Data inativação"
                          labelSize="lg"
                          isDisabled
                          _disabled={{
                            color: "gray.200"
                          }}
                          defaultValue={userData.inactivation_dateFormated}
                        />
                      </SimpleGrid>

                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <FormControl display="flex" alignItems="center">
                          <FormLabel htmlFor="is_admin" mb="0">
                            Administrador ?
                          </FormLabel>
                          <Switch
                            id="is_admin"
                            colorScheme="green"
                            defaultChecked={userData.is_admin}
                            {...register('is_admin')}
                          />
                        </FormControl>
                        <FormControl display="flex" alignItems="center">
                          <FormLabel htmlFor="is_teacher" mb="0">
                            Professor ?
                          </FormLabel>
                          <Switch
                            id="is_teacher"
                            colorScheme="green"
                            defaultChecked={userData.is_teacher}
                            {...register('is_teacher')}
                          />
                        </FormControl>
                        <FormControl display="flex" alignItems="center">
                          <FormLabel htmlFor="is_company" mb="0">
                            Empresa ?
                          </FormLabel>
                          <Switch
                            id="is_company"
                            colorScheme="green"
                            defaultChecked={userData.is_company}
                            {...register('is_company')}
                          />
                        </FormControl>
                      </SimpleGrid>

                      <div />

                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <Input
                          nameInput="street_name"
                          label="Endereço"
                          labelSize="lg"
                          error={errors.street_name}
                          {...register('street_name')}
                          defaultValue={userData.street_name}
                        />

                        <Input
                          nameInput="street_number"
                          type="number"
                          label="Número"
                          labelSize="lg"
                          error={errors.street_number}
                          {...register('street_number')}
                          defaultValue={userData.street_number}
                        />

                        <Input
                          nameInput="zip_code"
                          type="number"
                          label="CEP"
                          labelSize="lg"
                          error={errors.zip_code}
                          {...register('zip_code')}
                          defaultValue={userData.zip_code}
                        />
                      </SimpleGrid>

                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <Input
                          nameInput="area_code"
                          type="number"
                          label="DDD"
                          labelSize="lg"
                          error={errors.area_code}
                          {...register('area_code')}
                          defaultValue={userData.area_code}
                        />

                        <Input
                          nameInput="phone"
                          type="number"
                          label="Telefone"
                          labelSize="lg"
                          error={errors.phone}
                          {...register('phone')}
                          defaultValue={userData.phone}
                        />

                        <Input
                          nameInput="document"
                          type="number"
                          label="Documento (CPF, CNPJ, ...)"
                          labelSize="lg"
                          error={errors.document}
                          {...register('document')}
                          defaultValue={userData.document}
                        />

                        <Input
                          nameInput="birth_date"
                          label="Data de nascimento"
                          type="date"
                          labelSize="lg"
                          error={errors.birth_date}
                          {...register('birth_date')}
                          defaultValue={userData.birthDateFormatted}
                        />
                      </SimpleGrid>
                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <FormControl display="flex" alignItems="center">
                          <FormLabel htmlFor="receive_email" mb="0">
                            Receber E-mail ?
                          </FormLabel>
                          <Switch
                            id="receive_email"
                            colorScheme="green"
                            defaultChecked={userData.receive_email}
                            {...register('receive_email')}
                          />
                        </FormControl>

                        <FormControl display="flex" alignItems="center">
                          <FormLabel htmlFor="receive_newsletter" mb="0">
                            Receber Newsletter ?
                          </FormLabel>
                          <Switch
                            id="receive_newsletter"
                            colorScheme="green"
                            defaultChecked={userData.receive_newsletter}
                            {...register('receive_newsletter')}
                          />
                        </FormControl>
                      </SimpleGrid>
                    </VStack>
                )
              }

              <Flex
                mt="8"
                justify={["normal", "space-between"]}
                flexDir={["column", "row"]}
              >
                <HStack spacing="4">
                  <ButtonDefault
                    text="Excluir Usuário"
                    w={["100%", "150px"]}
                    attencion
                    onClick={() => setIsOpenAlert(true)}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />

                  {userData.inactivation_date
                  ? <ButtonDefault
                      text="Ativar Usuário"
                      w={["100%", "150px"]}
                      warning
                      onClick={handleActivated}
                      isLoading={formState.isSubmitting || isSubmitting}
                    />
                  : <ButtonDefault
                      text="Inativar Usuário"
                      w={["100%", "150px"]}
                      warning
                      onClick={handleDisabled}
                      isLoading={formState.isSubmitting || isSubmitting}
                    />
                  }

                  <ButtonDefault
                    text="Impersonar"
                    w={["100%", "150px"]}
                    info
                    onClick={handleImpersonate}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />

                  <ButtonDefault
                    w={["100%", "60px"]}
                    info
                    onClick={onOpen}
                    leftIcon={<Icon fontSize="20" as={RiMailSettingsLine} />}
                    iconSpacing={0}
                    title="Histórico de e-mails"
                  />

                  <ButtonDefault
                    w={["100%", "60px"]}
                    info
                    onClick={onOpenUserCredits}
                    leftIcon={<Icon fontSize="20" as={GiReceiveMoney} />}
                    iconSpacing={0}
                    title="Consultar créditos"
                  />
                </HStack>

                <Flex
                  w={["100%", "auto"]}
                  mt={["6", "0"]}
                  flexDir={["column", "row"]}
                >
                  <Box w={["100%", "auto"]}>
                    <ButtonDefault
                      text="Cancelar"
                      w={["100%", "150px"]}
                      info
                      isLoading={formState.isSubmitting || isSubmitting}
                      onClick={() => window.close()}
                    />
                  </Box>

                  <ButtonDefault
                    type="submit"
                    text="Salvar"
                    w={["100%", "150px"]}
                    ml={["0", "4"]}
                    mt={["6", "0"]}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />
                </Flex>
              </Flex>
            </Box>
          </MainBase>
        </ContentBase>
      </Flex>

      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpenAlert(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="bold" textColor="blue.900">
              Excluir usuário
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" textColor="blue.900">
              Tem certeza que deseja excluir este usuário?
            </AlertDialogBody>
            <AlertDialogBody textColor="red">
              Não será possível desfazer esta ação depois.
            </AlertDialogBody>

            <AlertDialogFooter flexDir={["column", "row"]}>
              <ButtonDefault
                text="Cancelar"
                w="100%"
                mb={["2", "0"]}
                info
                onClick={() => setIsOpenAlert(false)}
              />
              <ButtonDefault
                text="Excluir"
                w="100%"
                attencion
                onClick={handleDelete}
                ml={["0", "3"]}
              />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <MailLogsDrawer
        isOpen={isOpen}
        onClose={onClose}
        userId={userId}
      />
      <UserCreditsDrawer
        isOpen={isOpenUserCredits}
        onClose={onCloseUserCredits}
        userId={userId}
      />
    </>
  );
}
