import { ComponentWithAs, Flex, FlexProps } from "@chakra-ui/react"

const ContentBase: ComponentWithAs<"div", FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex w="100%" h="100%" mx="auto" bg="#F9FAFD">
      { children }
    </Flex>
  )
}

export default ContentBase;
