import {
  Text,
  Flex,
  Button,
  Divider,
  Image,
  Stack,
  HStack,
  useToast,
  StackDivider,
  Heading,
  List,
  ListItem,
  ListIcon,
  Link as LinkChakra,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { MdCheckCircle } from 'react-icons/md';

import logoWWA from '../../assets/wwa-branco.png';
import logoPraktika from '../../assets/praktika-branco.png';
import { Input } from '../../components/Form/Input';
import { useAuth } from "../../services/hooks/useAuth";
import { api } from '../../services/api';

type SignInFormData = {
    user: string;
    password: string;
}

const signInFormSchema = yup.object().shape({
  user: yup.string().required('Usuário ou e-mail obrigatório'),
  password: yup.string().required('Senha obrigatória'),
})

export function SignIn() {
  const { signIn } = useAuth();
  const toast = useToast();
  const [canRegister, setCantRegister] = useState(false);

  useEffect(() => {
    api.get('/parameters/consult/AvailableSignUp')
      .then(response => {
        setCantRegister(response.data.value === "true")
      })
  }, [setCantRegister])

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(signInFormSchema)
  })

  const handleSignIn: SubmitHandler<SignInFormData> = async (values: SignInFormData) => {
    try {
      localStorage.setItem('@praktika:signOut', 'false')
      await signIn(values);
    } catch (err) {
      toast({
        title: "Autenticação",
        description: "Usuário e/ou senha inválidos",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const { errors } = formState;

  return (
    <Flex
      w="100vw"
      h="100%"
      maxW="100vw"
      minH="100vh"
      justify="center"
      alignItems="center"
      flexDir={["column", null, null, "row"]}
      css={{
        'background-image': 'linear-gradient(135deg, #1e2f50 0%, #3e72ad 99%)'
      }}
    >
      <Flex
        flexDir="column"
        w={["100%", null, null, "50vw"]}
        alignItems={["center", null, null, "end"]}
        justifyContent="center"
        my="8"
        pr={["0", null, null, "16"]}
        pl={["0", null, null, "4"]}
      >
        <HStack
          divider={<StackDivider borderColor='gray.400' />}
          spacing="4"
        >
          <LinkChakra href="https://linktr.ee/WunderweltA" isExternal>
            <Image
              w={["150px", "200px"]}
              src={logoPraktika}
              alt="PrAktikA"
            />
          </LinkChakra>
          <LinkChakra href="https://linktr.ee/WunderweltA" isExternal>
            <Image
              src={logoWWA}
              alt="Wunderwelt-A"
              w={["150px", "220px"]}
            />
          </LinkChakra>
        </HStack>

        <Flex
          flexDir="column"
          w="100%"
          maxW="540px"
          mt="4"
          display={["none", null, null, "inherit"]}
        >
          <Heading my="4">Aprenda alemão com total autonomia</Heading>
          <Text
            fontSize="2xl"
            color="gray.50"
            my="2"
          >
            Plataforma de alemão voltada para aqueles que querem aprimorar o alemão ou ter um reforço em seus estudos
          </Text>

          <Text
            fontSize="lg"
            color="gray.100"
            my="2"
          >
            CADASTRE-SE GRATUITAMENTE E CONFIRA AS AULAS DISPONÍVEIS
          </Text>

          <List
            spacing={3}
            fontSize="2xl"
            color="gray.50"
            my="4"
          >
            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Aulas com professores nativos
            </ListItem>

            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Você define o assunto das aulas
            </ListItem>

            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Aulas particulares ou em grupo
            </ListItem>
          </List>

          <Text
            fontSize="lg"
            color="gray.100"
            my="4"
          >
            METODOLOGIA PARA VOCÊ ATINGIR SEUS OBJETIVOS
          </Text>
        </Flex>
      </Flex>

      <Divider
        h="80vh"
        orientation='vertical'
        display={["none", null, null, "inherit"]}
      />

      <Flex
        flexDir="column"
        w={["100%", null, null, "50vw"]}
        alignItems="center"
        justifyContent="center"
        p="8"
      >
        <Flex
          as="form"
          w="100%"
          maxWidth={400}
          p="8"
          alignItems="center"
          bg="#ffffff10"
          backdropBlur="10px"
          zIndex="1"
          borderRadius={8}
          flexDir="column"
          onSubmit={handleSubmit(handleSignIn)}
        >
          <Heading mb="10">
            Hallo,
            seja bem-vindo!
          </Heading>

          <Stack w="100%" spacing="4">
            <Input
              nameInput="user"
              type="user"
              label="Usuário ou e-mail"
              error={errors.user}
              {...register('user')}
            />
            <Input
              nameInput="password"
              type="password"
              label="Senha"
              error={errors.password}
              {...register('password')}
            />
          </Stack>

          <Flex alignSelf="end">
            <Link to="/forgot-password">
              <Text
                mt="8"
                fontSize="sm"
                bgColor="transparent"
                _hover={{
                  bgColor: "transparent",
                  color: "#50b683"
                }}
              >
                Esqueci minha senha
              </Text>
            </Link>
          </Flex>

          <Button
            type="submit"
            w="100%"
            minH="12"
            mt="6"
            bg="#50b683"
            size="lg"
            isLoading={formState.isSubmitting}
            _hover={{
              backgroundColor: "#46a174"
            }}
          >
            Entrar
          </Button>

          <Stack mt="4" mb="4" direction="row" w="100%" p={4} alignItems="center">
            <Divider/>
            <Text>ou</Text>
            <Divider/>
          </Stack>

          { canRegister &&
            <Link
              to="/signup"
            >
              <Text
                fontSize="xl"
                bgColor="transparent"
                color="#50b683"
                // rightIcon={<Icon fontSize="20" as={RiExternalLinkLine} />}
                _hover={{
                  bgColor: "transparent",
                  color: "#46a174"
                }}
              >
                CADASTRE-SE
              </Text>
            </Link>
          }
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        px="8"
      >
        <Flex
          flexDir="column"
          w="100%"
          maxW="540px"
          justifyContent="center"
          display={["inherit", null, null, "none"]}
        >
          <Heading
            my="4"
            textAlign="center"
          >
            Aprenda alemão com total autonomia
          </Heading>
          <Text
            fontSize="lg"
            color="gray.50"
            my="2"
            textAlign="center"
          >
            Plataforma de alemão voltada para aqueles que querem aprimorar o alemão ou ter um reforço em seus estudos
          </Text>

          <Text
            fontSize="md"
            color="gray.100"
            my="2"
            textAlign="center"
          >
            CADASTRE-SE GRATUITAMENTE E CONFIRA AS AULAS DISPONÍVEIS
          </Text>

          <List
            spacing={3}
            fontSize="lg"
            color="gray.50"
            my="4"
            alignSelf="center"
          >
            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Aulas com professores nativos
            </ListItem>

            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Você define o assunto das aulas
            </ListItem>

            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Aulas particulares ou em grupo
            </ListItem>
          </List>

          <Text
            fontSize="md"
            color="gray.100"
            my="4"
            textAlign="center"
          >
            METODOLOGIA PARA VOCÊ ATINGIR SEUS OBJETIVOS
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
