import { createContext, ReactNode, useState } from "react";

import { api } from "../services/api";
import { Coupon } from "../services/hooks/useCoupons";

export type CouponContextData = {
  valid: (code: string) => Promise<boolean>;
  coupon: Coupon;
};

interface CouponProviderProps {
  children: ReactNode;
}

export const CouponContext = createContext({} as CouponContextData)

export function CouponProvider({ children }: CouponProviderProps) {
  const [coupon, setCoupon] = useState({
    id: '',
    code: '',
    amount: '',
    limit: '',
    expiration_date: '',
    used: '',
    expirationFormated: '',
  });

  async function valid(code: string): Promise<boolean> {
    try {
      if (code) {
        const { data } = await api.get(`/coupons/available/${code}`)

        setCoupon(data)
      }

      return true
    } catch (err) {
      setCoupon({
        id: '',
        code: '',
        amount: '',
        limit: '',
        expiration_date: '',
        used: '',
        expirationFormated: '',
      })

      return false
    }
  }

  return (
    <CouponContext.Provider value={{ valid, coupon }}>
      {children}
    </CouponContext.Provider>
  )
}
