import { useQuery } from "react-query";

import { api } from "../api";

export type Product = {
  id: string;
  name: string;
  description: string;
  value: number;
  amount: number;
  is_active: boolean;
  original_value: number;
  original_amount: number;
  isActive: string;
}

export async function getProducts(): Promise<Product[]> {
  const response = await api.get<any>('/products')

  const { data } = response

  const products = data.map((product: Product) => {
    return {
      ...product,
      isActive: product.is_active ? 'Sim' : 'Não',
    }
  })

  return products;
}

export function useProducts() {
  return useQuery(['products'], () => getProducts(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
