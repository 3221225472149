import {
  Avatar,
  Box,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import { RiCoinsLine } from 'react-icons/ri';
import { Link } from "react-router-dom";

import { useAuth } from '../../services/hooks/useAuth';
import { NotificationNav } from './NotificationNav';

interface ProfileProps {
  showProfileData?: boolean;
}

export function Profile({ showProfileData }: ProfileProps) {
  const { user } = useAuth();

  return (
    <Flex
      align="center"
      bg={["", null, null, "white"]}
      border={["", null, null, "1px"]}
      borderRadius={["", null, null, "60px"]}
      px={["", null, null, "4"]}
      py={["", null, null, "2"]}
    >
      { showProfileData
      ? (
          <>
            <NotificationNav />
            <Box ml="3" mr="4" textAlign="right">
              <Text color="blue.900">
                <Icon as={RiCoinsLine} mx="1" fill="gold" fontSize="25" />
                {user.credit}
              </Text>
            </Box>
          </>
        )
      : (
          <>
            <NotificationNav />
            <Box mx="2">
              <Text color="white">
                <Icon as={RiCoinsLine} mr="1" fill="gold" fontSize="25" />
                {user.credit}
              </Text>
            </Box>
          </>
        )
      }

      <Link to='/profile' >
        <Avatar size="sm" name={user.name} />
      </Link>
    </Flex>
  )
}
