import { useEffect, useState } from "react";
import { format, utcToZonedTime } from "date-fns-tz";

import {
  Flex,
  Spinner,
  Stack,
  StackDivider,
  Text
} from "@chakra-ui/react";

import { api } from "../../../services/api";
import CustonDrawer from "..";
import { MyCreditsTable } from "../../MyCredits/Table";

interface UserCreditsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

type CreditProps = {
  id: string;
  amount: string;
  expiration_date: string;
  user_id: string;
  balance: string;
  purchase_id: string;
  created_at: string;
  createdAtFormatted?: string;
  expirationDateFormatted?: string;
}

export function UserCreditsDrawer({
  isOpen,
  onClose,
  userId,
} : UserCreditsDrawerProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [userCreditsData, setUserCreditsData] = useState([] as CreditProps[]);

  useEffect(() => {
    if (isOpen) {
      api.get<CreditProps[]>(`/credits/available/${userId}`).then(response => {
        const { data } = response;

        const creditsData = data.map(credit => {
          return {
            ...credit,
            expirationDateFormatted: format(
              utcToZonedTime(credit.expiration_date, 'America/Sao_Paulo'),
              'dd/MM/yyyy'
            ),
            createdAtFormatted: format(
              utcToZonedTime(credit.created_at, 'America/Sao_Paulo'),
              'dd/MM/yyyy'
            ),
          }
        })

        setUserCreditsData(creditsData);
        setIsLoading(false)
      })
    }
  }, [isOpen, userId])

  return (
    <CustonDrawer
      isOpen={isOpen}
      onClose={onClose}
      title="Créditos do aluno"
      size="lg"
    >
      { isLoading ? (
          <Flex justify="center">
            <Spinner color="blue.900" size="xl" />
          </Flex>
        ) : (
          <Stack
            spacing='24px'
            divider={<StackDivider borderColor='gray.200' />}
            mt='6px'
          >
            <Flex
              direction="column"
            >
              <Text
                fontSize="md"
              >
                Segue abaixo lista dos créditos referentes a esse aluno assim como suas respectivas datas de vencimento:
              </Text>

              <Flex
                mt="1"
              >
                {/* <MailLogTable
                  mailLogs={userCreditsData}
                /> */}
                <MyCreditsTable credits={userCreditsData} />
              </Flex>
            </Flex>
          </Stack>
        )
      }
    </CustonDrawer>
  )
}
