import { Switch } from 'react-router-dom';

import { ClassesList } from '../pages/ClassesList';
import { ClassesRegistered } from '../pages/ClassesRegistered';
import { UserList } from '../pages/CRUD/Users';
import { Dashboard } from '../pages/Dashboard';
import { Extract } from '../pages/Extract';
import { Purchase } from '../pages/Purchase';
import { ForgotPassword } from '../pages/ForgotPassword';
import { Profile } from '../pages/Profile';
import { ResetPassword } from '../pages/ResetPassword';
import { SignIn } from '../pages/SignIn';
import { SignUp } from '../pages/SignUp';
import { CreateUser } from '../pages/CRUD/Users/create';
import { UpdateUser } from '../pages/CRUD/Users/update';
import { LevelsList } from '../pages/CRUD/Levels';
import { CreateLevel } from '../pages/CRUD/Levels/create';
import { UpdateLevel } from '../pages/CRUD/Levels/update';
import { ParametersList } from '../pages/CRUD/Parameters';
import { UpdateParameter } from '../pages/CRUD/Parameters/update';
import { CouponsList } from '../pages/CRUD/Coupons';
import { CreateCoupon } from '../pages/CRUD/Coupons/create';
import { EventsList } from '../pages/CRUD/Events';
import { CreateEvent } from '../pages/CRUD/Events/create';
import { UpdateEvent } from '../pages/CRUD/Events/update';
import { UpdateCoupon } from '../pages/CRUD/Coupons/update';
import { SchedulesList } from '../pages/Report/Schedules';
import { QueuesList } from '../pages/Report/Queues';
import { TeacherEvents } from '../pages/Report/TeacherEvents';

import { Route } from './Route';
import { MonthlyEventsList } from '../pages/CRUD/Events/MonthlyEvents';
import { ProductsList } from '../pages/CRUD/Products';
import { UpdateProduct } from '../pages/CRUD/Products/update';
import { GiftSender } from '../pages/Gift';
import { Success } from '../pages/Success';
import { Pending } from '../pages/Pending';
import { Failure } from '../pages/Failure';
import { CouponProvider } from '../contexts/CouponContext';
import { Support } from '../pages/Support';
import { FQAsList } from '../pages/CRUD/FQAs';
import { CreateFQA } from '../pages/CRUD/FQAs/create';
import { UpdateFQA } from '../pages/CRUD/FQAs/update';
import { MyCredits } from '../pages/MyCredits';
import { PromotionsList } from '../pages/CRUD/Promotions';
import { CreatePromotion } from '../pages/CRUD/Promotions/create';
import { UpdatePromotion } from '../pages/CRUD/Promotions/update';
import { Notification } from '../pages/Notification';
import { SquadEvents } from '../pages/Report/SquadEvents';
import { Unsubscribe } from '../pages/Unsubscribe';
import { CompanyList } from '../pages/CRUD/Company';
import { CreateCompany } from '../pages/CRUD/Company/create';
import { UpdateCompany } from '../pages/CRUD/Company/update';
import { ClassSubjectsList } from '../pages/CRUD/ClassSubjects/intex';
import { CreateClassSubject } from '../pages/CRUD/ClassSubjects/create';
import { UpdateClassSubject } from '../pages/CRUD/ClassSubjects/update';
import { CreateProduct } from '../pages/CRUD/Products/create';

export function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={SignIn} />
            <Route path="/signup" exact component={SignUp} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/password/reset" exact component={ResetPassword} />

            {/* Rota aberta */}
            <Route path="/unsubscribe/:type" exact component={Unsubscribe} isOpen />

            {/* Geral */}
            <Route path="/dashboard" exact component={Dashboard} isPrivate isAdmin />
            <Route path="/classes-list" exact component={ClassesList} isPrivate />
            <Route path="/classes-registered" exact component={ClassesRegistered} isPrivate />
            <Route path="/gift" exact component={GiftSender} isPrivate isAdmin />

            {/* Cadastros */}
            <Route path="/monthly" exact component={MonthlyEventsList} isPrivate isAdmin />

            <Route path="/events" exact component={EventsList} isPrivate isAdmin />
            <Route path="/events/create" exact component={CreateEvent} isPrivate isAdmin />
            <Route path="/events/update/:id" exact component={UpdateEvent} isPrivate isAdmin />

            <Route path="/users" exact component={UserList} isPrivate isAdmin />
            <Route path="/users/create" exact component={CreateUser} isPrivate isAdmin />
            <Route path="/users/update/:id" exact component={UpdateUser} isPrivate isAdmin />

            <Route path="/company" exact component={CompanyList} isPrivate isAdmin />
            <Route path="/company/create" exact component={CreateCompany} isPrivate isAdmin />
            <Route path="/company/update/:id" exact component={UpdateCompany} isPrivate isAdmin />

            <Route path="/products" exact component={ProductsList} isPrivate isAdmin />
            <Route path="/products/create" exact component={CreateProduct} isPrivate isAdmin />
            <Route path="/products/update/:id" exact component={UpdateProduct} isPrivate isAdmin />

            <Route path="/levels" exact component={LevelsList} isPrivate isAdmin />
            <Route path="/levels/create" exact component={CreateLevel} isPrivate isAdmin />
            <Route path="/levels/update/:id" exact component={UpdateLevel} isPrivate isAdmin />

            <Route path="/class-subjects" exact component={ClassSubjectsList} isPrivate isAdmin />
            <Route path="/class-subjects/create" exact component={CreateClassSubject} isPrivate isAdmin />
            <Route path="/class-subjects/update/:id" exact component={UpdateClassSubject} isPrivate isAdmin />

            <Route path="/coupons" exact component={CouponsList} isPrivate isAdmin />
            <Route path="/coupons/create" exact component={CreateCoupon} isPrivate isAdmin />
            <Route path="/coupons/update/:id" exact component={UpdateCoupon} isPrivate isAdmin />

            <Route path="/promotions" exact component={PromotionsList} isPrivate isAdmin />
            <Route path="/promotions/create" exact component={CreatePromotion} isPrivate isAdmin />
            <Route path="/promotions/update/:id" exact component={UpdatePromotion} isPrivate isAdmin />

            <Route path="/parameters" exact component={ParametersList} isPrivate isAdmin />
            <Route path="/parameters/update/:id" exact component={UpdateParameter} isPrivate isAdmin />

            <Route path="/fqas" exact component={FQAsList} isPrivate isAdmin />
            <Route path="/fqas/create" exact component={CreateFQA} isPrivate isAdmin />
            <Route path="/fqas/update/:id" exact component={UpdateFQA} isPrivate isAdmin />

            {/* Consulta */}
            <Route path="/teachers" exact component={TeacherEvents} isPrivate isAdmin />
            <Route path="/schedules" exact component={SchedulesList} isPrivate isAdmin />
            <Route path="/queues" exact component={QueuesList} isPrivate isAdmin />
            <Route path="/squad-historic" exact component={SquadEvents} isPrivate isCompany />

            {/* Perfil */}
            <Route path="/profile" exact component={Profile} isPrivate />
            <Route path="/extract" exact component={Extract} isPrivate />
            <Route path="/my-credits" exact component={MyCredits} isPrivate />
            <Route path="/support" exact component={Support} isPrivate />

            <Route path="/notification/:id" exact component={Notification} isPrivate />

            {/* Payment Feedback */}
            <Route path="/success" exact component={Success} isPrivate />
            <Route path="/pending" exact component={Pending} isPrivate />
            <Route path="/failure" exact component={Failure} isPrivate />

            <CouponProvider>
              <Route path="/purchase" exact component={Purchase} isPrivate />
            </CouponProvider>
        </Switch>
    )
};
