import {
  Box,
  Flex,
  Text,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Column } from "react-table";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { Event, useEvents } from "../../../services/hooks/useEvents";
import MainBase from "../../../components/MainBase";
import ContentBase from "../../../components/ContentBase";
import MainActions from "../../../components/MainActions";
import { MainTable } from "../../../components/MainTable";
import { RemoveAccent } from "../../../utils/RemoveAccent";

export function EventsList() {
  const [searchField, setSearchField] = useState('');
  const [allEvents, setAllEvents] = useState<Event[]>([]);

  const { data, isLoading, isFetching, error } = useEvents()

  const fileName = `Lista-de-aulas-${Date.now()}.csv`;
  const csvHeaders = [
    {
      label: 'Título',
      key: 'title'
    },
    {
      label: 'Nível',
      key: 'levels'
    },
    {
      label: 'Tema',
      key: 'subject'
    },
    {
      label: 'Data',
      key: 'startDateFormated'
    },
    {
      label: 'Início',
      key: 'startHourFormated'
    },
    {
      label: 'Término',
      key: 'endHourFormated'
    },
    {
      label: 'Professor',
      key: 'name'
    },
  ]

  useEffect(() => {
    if (data) {
      setAllEvents(data)
    }
  }, [data])

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    if (data) {
      if (searchField) {
        const searchFieldWithoutAccent = RemoveAccent(searchField.toLowerCase())
        const eventsFiltered = data.filter( event =>
          RemoveAccent(event.title.toLowerCase()).match(searchFieldWithoutAccent)
          || RemoveAccent(event.description.toLowerCase()).match(searchFieldWithoutAccent)
        )

        setAllEvents(eventsFiltered)

        return
      }

      setAllEvents(data)
    }
  }, [searchField, data])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const columns: Column<Event>[] = [
    {
      Header: "Título",
      accessor: "title",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
      setStatus: [
        {
          field: "is_canceled",
          text: "CANCELADA",
          color: "red"
        },
        {
          field: "for_teachers",
          text: "PROFESSORES",
          color: "blue"
        },
      ]
    },
    {
      Header: "Nível",
      accessor: "levels",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Tema",
      accessor: "subject",
    },
    {
      Header: "Data",
      accessor: "start_date",
      //  @ts-ignore TYPE NEEDS FIXING
      formattedValue: "startDateFormated",
      isSortable: true,
    },
    {
      Header: "Início",
      accessor: "startHourFormated",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Término",
      accessor: "endHourFormatted",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Professor",
      accessor: "name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
  ];

  const eventActions = [
    {
      type: 'copy',
      url: '/events/create?id=<id>',
    },
    {
      type: 'edit',
      url: '/events/update/<id>/',
    }
  ]

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Aulas"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <MainActions
            handleChangeSearch={handleChangeSearch}
            handleOnPressEnter={handleOnPressEnter}
            handleSearch={handleSearch}
            searchPlaceHolder="Digite o título ou descrição da aula que deseja procurar..."
            createLink="/events/create"
            csvDownload={{
              csvData: allEvents,
              csvHeaders: csvHeaders,
              csvFileName: fileName,
            }}
          />

          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : error ? (
              <Flex direction="column">
                <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter as aulas :(</Text>
                <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={allEvents}
                  actions={eventActions}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
