import { api } from "../../services/api"

async function LevelNameExist(value?: string, level_id?: string): Promise<boolean> {
  if (value) {
    const { data } = await api.get(`/levels/valid`, {
      params: {
        field: 'name',
        value,
        level_id,
      }
    })

    return !data
  }

  return true
}

export { LevelNameExist }
