import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  DrawerProps,
  ComponentWithAs,
  Text,
} from "@chakra-ui/react";

const CustonDrawer: ComponentWithAs<"div", DrawerProps> = ({
  children,
  isOpen,
  onClose,
  title,
  closeOnOverlayClick,
  ...rest
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="md"
      {...rest}
    >
      <DrawerOverlay />
      <DrawerContent
        color="blue.900"
      >
        <DrawerCloseButton />
        <DrawerHeader
          borderBottomWidth='1px'
        >
          <Text>{title}</Text>
        </DrawerHeader>

        <DrawerBody
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: "var(--chakra-colors-gray-400)",
              borderRadius: '25px',
            },
          }}
        >
          {children}
        </DrawerBody>

        <DrawerFooter borderTopWidth='1px'>
          <Button
            colorScheme='blue'
            mr={3}
            onClick={onClose}>
            Fechar
          </Button>
          {/* <Button colorScheme='blue'>Submit</Button> */}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default CustonDrawer
