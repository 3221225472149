import {
  Box,
  Flex,
  Text,
  Spinner,
  useBreakpointValue
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { User, useUsers } from "../../../services/hooks/useUsers";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import MainActions from "../../../components/MainActions";
import { MainTable } from "../../../components/MainTable";
import { Column } from "react-table";
import { RemoveAccent } from "../../../utils/RemoveAccent";

export function UserList() {
  const [searchField, setSearchField] = useState('');
  const [allUsers, setAllUsers] = useState<User[]>([]);

  const fileName = `Lista-de-usuarios-${Date.now()}.csv`;
  const csvHeaders = [
    {
      label: 'Nome',
      key: 'name'
    },
    {
      label: 'E-mail',
      key: 'email'
    },
    {
      label: 'Aluno ?',
      key: 'isStudentFormatted'
    },
    {
      label: 'Administrador ?',
      key: 'isAdminFormated'
    },
    {
      label: 'Professor ?',
      key: 'isTeacherFormated'
    },
    {
      label: 'Data de Nascimento',
      key: 'birthDateFormatted'
    },
    {
      label: 'Data de Bloqueio',
      key: 'inactivationDateFormated'
    },
    {
      label: 'Data de Criação',
      key: 'createdAtFormatted'
    },
    {
      label: 'Saldo Crédito',
      key: 'credit'
    },
  ]

  const { data, isLoading, isFetching, error } = useUsers()

  useEffect(() => {
    if (data) {
      setAllUsers(data)
    }
  }, [data])

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    if (data) {
      if (searchField) {
        const searchFieldWithoutAccent = RemoveAccent(searchField.toLowerCase())
        const usersFiltered = data.filter(
          user => RemoveAccent(user.name.toLowerCase()).match(searchFieldWithoutAccent)
        )

        setAllUsers(usersFiltered)

        return
      }

      setAllUsers(data)
    }
  }, [searchField, data])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const columns: Column<User>[] = [
    {
      Header: "Nome",
      accessor: "name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "E-mail",
      accessor: "email",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Receber E-mail?",
      accessor: "receiveEmail",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Aluno ?",
      accessor: "isStudentFormatted",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Administrador ?",
      accessor: "isAdminFormated",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Professor ?",
      accessor: "isTeacherFormated",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Data de Bloqueio",
      accessor: "inactivation_date",
      //  @ts-ignore TYPE NEEDS FIXING
      formattedValue: "inactivationDateFormated",
      isSortable: true,
    },
    {
      Header: "Data de Criação",
      accessor: "created_at",
      //  @ts-ignore TYPE NEEDS FIXING
      formattedValue: "createdAtFormatted",
      isSortable: true,
    },
    {
      Header: "Saldo Crédito",
      accessor: "credit",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
  ];

  const actions = [
    {
      type: 'edit',
      url: '/users/update/<id>',
    }
  ]

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Usuários"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <MainActions
            handleChangeSearch={handleChangeSearch}
            handleOnPressEnter={handleOnPressEnter}
            handleSearch={handleSearch}
            searchPlaceHolder="Digite o nome do usuário que deseja procurar..."
            createLink="/users/create"
            csvDownload={{
              csvData: allUsers,
              csvHeaders: csvHeaders,
              csvFileName: fileName,
            }}
          />

          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : error ? (
              <Flex direction="column">
                <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter os usuários :(</Text>
                <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={allUsers}
                  actions={actions}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
