import { useQuery } from "react-query";

import { api } from "../api";

export type Company = {
  id: string;
  company_id: string;
  name: string;
}

export async function getCompanies(): Promise<Company[]> {
  const response = await api.get<any>('/company-members/companies')

  const { data } = response

  return data;
}

export function useCompanyMembers() {
  return useQuery(['companies'], () => getCompanies(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
