import { createContext, ReactNode, useState, useCallback, useEffect } from "react";

import { api } from "../services/api";

type User = {
  name: string;
  username: string;
  email: string;
  isAdmin: boolean;
  isTeacher: boolean;
  balance: number;
  street_name: string;
  street_number: string;
  zip_code: string;
  area_code: string;
  phone: string;
  document: string;
  credit: string;
  receive_newsletter: boolean;
  receive_email: boolean;
  isCompany: boolean;
  birth_date: string | null;
}

type SignInCredentials = {
  user: string;
  password: string;
}

export type AuthContextData = {
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut: () => void;
  user: User;
  isAuthentivated: boolean;
  updateUser: (user: Partial<User>) => void;
  impersonate: (user_id: string) => Promise<void>;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState(() => {
    const user = localStorage.getItem('@praktika:user')

    if (user) {
      return JSON.parse(user);
    }

    return {} as User;
  });

  let isAuthentivated = !!user.username;

  useEffect(() => {
    window.onstorage = () => {
      const doSignOut = localStorage.getItem('@praktika:signOut') === 'true';

      if (doSignOut) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isAuthentivated = false;
        signOut();
      }
    };
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('@praktika:token');

    if (token) {
      api.get('/profile').then(response => {
        const {
          name,
          username,
          email,
          isAdmin,
          isTeacher,
          balance,
          street_name,
          street_number,
          zip_code,
          area_code,
          phone,
          document,
          credit,
          receive_newsletter,
          receive_email,
          isCompany,
          birth_date,
        } = response.data;

        setUser({
          name,
          email,
          isAdmin,
          isTeacher,
          username,
          balance,
          street_name,
          street_number,
          zip_code,
          area_code,
          phone,
          document,
          credit,
          receive_newsletter,
          receive_email,
          isCompany,
          birth_date,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
        isAuthentivated = !!user.user;
      })
      .catch(() => {
        isAuthentivated = false;
        signOut();
      })
    }
  }, [])

  const signOut = () => {
    localStorage.removeItem('@praktika:token');
    localStorage.removeItem('@praktika:refreshToken');
    localStorage.removeItem('@praktika:user');
    localStorage.removeItem('@praktika:scrollSidebar');

    localStorage.setItem('@praktika:signOut', 'true')

    setUser({})
  }

  const updateUser = useCallback((updateData: Partial<User>) => {
    api.get('/profile').then(response => {
      const {
        name,
        username,
        email,
        isAdmin,
        isTeacher,
        balance,
        street_name,
        street_number,
        zip_code,
        area_code,
        phone,
        document,
        credit,
        receive_newsletter,
        receive_email,
        isCompany,
        birth_date
      } = response.data;
      console.log('Update - AuthContext', birth_date)
      setUser({
        name,
        email,
        isAdmin,
        isTeacher,
        username,
        balance,
        street_name,
        street_number,
        zip_code,
        area_code,
        phone,
        document,
        credit,
        receive_newsletter,
        receive_email,
        isCompany,
        birth_date,
      })
    })
  }, [])

  async function signIn({ user: username, password }: SignInCredentials) {
    try {
      const response = await api.post('sessions', {
        username,
        password
      })

      const { token, refresh_token, user } = response.data;

      localStorage.setItem('@praktika:token', token)
      localStorage.setItem('@praktika:refreshToken', refresh_token)
      localStorage.setItem('@praktika:user', JSON.stringify(user))

      localStorage.setItem('@praktika:signOut', 'false')

      isAuthentivated = !!user.username;

      api.defaults.headers['Authorization'] = `Bearer ${token}`

      setUser(user)
    } catch (err) {
      throw new Error('Invalid user');
    }
  }

  async function impersonate(user_id: string) {
    try {
      const response = await api.post('users/impersonate', { user_id })

      const { token, refresh_token, user } = response.data;

      localStorage.setItem('@praktika:token', token)
      localStorage.setItem('@praktika:refreshToken', refresh_token)
      localStorage.setItem('@praktika:user', JSON.stringify(user))

      localStorage.setItem('@praktika:signOut', 'false')

      isAuthentivated = !!user.username;

      api.defaults.headers['Authorization'] = `Bearer ${token}`

      setUser(user)
    } catch (err) {
      throw new Error('Invalid user');
    }
  }

  return (
    <AuthContext.Provider value={{ signIn, signOut, isAuthentivated, user, updateUser, impersonate }}>
      {children}
    </AuthContext.Provider>
  )
}
