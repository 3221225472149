import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { useCallback } from "react";

import { api } from "../../services/api";

import { ButtonDefault } from "../ButtonDefault";
import { useAuth } from "../../services/hooks/useAuth";

interface UnsubscribeAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function UnsubscribeAccountModal({
  isOpen,
  onClose
}: UnsubscribeAccountModalProps) {
  const { signOut } = useAuth();

  const toast = useToast();

  const handleUnsubscribeUser = useCallback(async () => {
    try {
      await api.delete('/profile');

      toast({
        title: "Usuário excluído",
        description: "Sua conta foi excluída",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      });

      signOut()

      onClose();
    } catch {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [onClose, toast, signOut]);

  return (
    <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="blue.900">Exclusão da conta</ModalHeader>
          <ModalCloseButton color="blue.900" />
          <ModalBody my="2" fontSize="xl" color="blue.900">
            Tem certeza que deseja excluir a sua conta?
            Uma vez realizado esse processo ele não poderá ser desfeito.
          </ModalBody>

          <ModalFooter
            flexDir={["column", "row"]}
            justifyContent="space-between"
          >
            <ButtonDefault
              onClick={handleUnsubscribeUser}
              text="Confirmar exclusão"
              w="100%"
              minH="50px"
              mt="6"
              size="xs"
              unfocused
              textDecor="underline"
            />
            <ButtonDefault
              onClick={onClose}
              text="Cancelar"
              w="100%"
              minH="50px"
              mt="6"
              size="lg"
              attencion
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}
