import {
  Box,
  HStack,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { RiTimerLine } from 'react-icons/ri';

import { Tag } from "./Tag";

interface HeaderProps {
  title: string;
  date: string;
  hour: string;
  duration: string;
  levels?: {
    id: string;
    level: {
      name: string;
      color: string;
      variant: string;
    };
  }[]
  dayOfWeek: string;
  forTeachers?: boolean;
}

export function Header({
  title,
  date,
  hour,
  duration,
  levels,
  dayOfWeek,
  forTeachers = false,
}: HeaderProps) {
  return (
    <Stack
      alignItems="flex-start"
      spacing="auto"
      color="blue.900"
      // bg="blue.700"
      borderTopRadius="5"
    >
      <HStack>
        <Text fontSize="xl" textAlign="left">
          <strong>{title}</strong>
        </Text>
        <HStack>
          {forTeachers &&
            <Tag
              key="forTeachers"
              id="forTeachers"
              tagName="PROFESSORES"
              tagColor="blue"
              tagVariant="solid"
            />
          }
          {levels?.map(level => (
            <Tag
              key={level.id}
              id={level.id}
              tagName={level.level.name}
              tagColor={level.level.color}
              tagVariant={level.level.variant}
            />
          ))}
        </HStack>
      </HStack>
      <Stack
        spacing="1"
        alignItems="left"
        direction={['column', 'row']}
      >
        <Text
          fontSize={["md", "small"]}
          color="blue.800"
        >
          {dayOfWeek}, {date} - {hour} (<strong>horário de Brasília</strong>)
        </Text>

        <Box display="flex" flexDir="row" alignItems="center">
          <Tooltip hasArrow label='Duração'>
            <Box>
              <RiTimerLine size="20"/>
            </Box>
          </Tooltip>

          <Text
            fontSize={["md", "small"]}
            color="blue.800"
          >
            {duration}min
          </Text>
        </Box>
      </Stack>
    </Stack>
  )
}
