import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { TextAreaStyled } from "./styles";

interface TextAreaEditorProps {
  editorState: EditorState;
  onEditorStateChange: (editorStates: EditorState) => void;
  readOnly?: boolean;
}

export function TextAreaEditor({ editorState, onEditorStateChange, readOnly = false }: TextAreaEditorProps) {
  return (
    <TextAreaStyled>
      <Editor
        editorState={editorState}
        toolbarClassName="textAreaToolbarClassName"
        wrapperClassName="textAreaWrapperClassName"
        editorClassName="textAreaEditorClassName"
        editorStyle={{
          height: '100px',
          border: '1px solid',
          borderColor: '#B3B5C6',
        }}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'history'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
        }}
        readOnly={readOnly}
      />
    </TextAreaStyled>
  )
}
