interface ICheckErrorCode {
  title: string;
  message: string;
  status: string;
}

function CheckErrorCode(code: string | null): ICheckErrorCode {
  let title: string = ""
  let message: string = ""
  let status: string = ""

  switch (code) {
    case 'enough.hours':
      title = 'Crédito insuficiente'
      message = 'Infelizmente não foi possível realizar a inscrição devido a quantidade de créditos insuficientes'
      status = 'warning'
      break;
    case 'SC0001':
      title = 'Aula não tem mais vagas'
      message = 'Infelizmente as vagas para esta aula se esgotaram...'
      status = 'warning'
      break;
    case 'SC0002':
      title = 'Você já tem uma aula nesse dia'
      message = 'Visando um melhor aprendizado, não recomendamos que o aluno participe de mais de uma aula no mesmo dia.'
      status = 'warning'
      break;
    case 'SC0003':
      title = 'Aula cancelada'
      message = 'Infelizmente esta aula foi cancelada pela administração...'
      status = 'warning'
      break;
    default:
      title = "Opa..."
      message = "Ocorreu uma instabilidade. Por favor tente novamente mais tarde."
      status = 'error'
      break;
  }

  return {
    title,
    message,
    status
  }
}

export { CheckErrorCode }
