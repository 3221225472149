import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react"
import { differenceInDays } from "date-fns";

type CreditProps = {
  id: string;
  amount: string;
  expiration_date: string;
  user_id: string;
  balance: string;
  purchase_id: string;
  created_at: string;
  createdAtFormatted?: string;
  expirationDateFormatted?: string;
}

interface MyCreditsTableProps {
  credits: CreditProps[];
}

export function MyCreditsTable({ credits }: MyCreditsTableProps) {
  return (
    <Box maxW="100%" overflow="auto">
      <Table maxW="100%" overflow="auto" variant="simple" mt="8" color="blue.900">
        <Thead>
          <Tr>
            <Th>Créditos Adquiridos</Th>
            <Th>Créditos Disponíveis</Th>
            <Th>Data da Compra</Th>
            <Th>Data de Validade</Th>
          </Tr>
        </Thead>
        <Tbody>
          {credits.map(credit => {
            const difDays = differenceInDays(new Date(credit.expiration_date), new Date())

            return(
              <Tr key={credit.id}>
                <Td>{credit.amount}</Td>
                <Td fontWeight="bold">{credit.balance}</Td>
                <Td>{credit.createdAtFormatted}</Td>
                <Td
                  fontWeight="bold"
                  color={Number(credit.balance) <= 0
                    ? "blue.900"
                      :difDays <= 30
                        ? "red.500"
                          : difDays > 30 && difDays <= 60
                            ? "yellow.500"
                              : "green.500" }
                >
                  {credit.expirationDateFormatted}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  );
}
