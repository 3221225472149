import {
  Box,
  Flex,
  Text,
  Spinner,
  useBreakpointValue
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { Product, useProducts } from "../../../services/hooks/useProducts";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import MainActions from "../../../components/MainActions";
import { Column } from "react-table";
import { MainTable } from "../../../components/MainTable";
import { RemoveAccent } from "../../../utils/RemoveAccent";

export function ProductsList() {
  const [searchField, setSearchField] = useState('');
  const [allProducts, setAllProducts] = useState<Product[]>([]);

  const { data, isLoading, isFetching, error } = useProducts()

  useEffect(() => {
    if (data) {
      setAllProducts(data)
    }
  }, [data])

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    if (data) {
      if (searchField) {
        const searchFieldWithoutAccent = RemoveAccent(searchField.toLowerCase())
        const productsFiltered = data.filter(
          product => RemoveAccent(product.description.toLowerCase()).match(searchFieldWithoutAccent)
        )

        setAllProducts(productsFiltered)

        return
      }

      setAllProducts(data)
    }
  }, [searchField, data])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const columns: Column<Product>[] = [
    {
      Header: "Ativo ?",
      accessor: "isActive",
    },
    {
      Header: "Produto",
      accessor: "name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Descrição",
      accessor: "description",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Valor",
      accessor: "value",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Quantidade",
      accessor: "amount",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
  ];

  const actions = [
    {
      type: 'edit',
      url: '/products/update/<id>',
    }
  ]

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Produtos"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <MainActions
            handleChangeSearch={handleChangeSearch}
            handleOnPressEnter={handleOnPressEnter}
            handleSearch={handleSearch}
            searchPlaceHolder="Digite a descrição que deseja procurar..."
            createLink="/products/create"
          />

          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : error ? (
              <Flex direction="column">
                <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter os produtos :(</Text>
                <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={allProducts}
                  actions={actions}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
