import {
  Input,
  InputGroup,
  InputLeftElement
} from "@chakra-ui/react";
import { RiSearchLine } from "react-icons/ri";

interface SearchInputProps {
  handleChangeSearch: (event: any) => void;
  handleOnPressEnter: (event: any) => void;
  searchPlaceHolder: string;
  showIcon?: boolean;
}

export function SearchInput({
  handleChangeSearch,
  handleOnPressEnter,
  searchPlaceHolder,
  showIcon = true,
} : SearchInputProps) {
  return (
    <InputGroup
      w={["100%", null, null, "700px"]}
      mr={["0", null, null, "2"]}
    >
      { showIcon &&
        <InputLeftElement
          pointerEvents="none"
          children={<RiSearchLine fill="var(--chakra-colors-blue-900)" />}
        />
      }
      <Input
        name="filter"
        type="text"
        color="blue.900"
        borderColor="gray.200"
        placeholder={searchPlaceHolder}
        focusBorderColor="blue.500"
        onChange={handleChangeSearch}
        onKeyPress={handleOnPressEnter}
        _hover={{
          borderColor: "blue.500"
        }}
      />
    </InputGroup>
  )
}
