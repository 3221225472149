import {
  ComponentWithAs,
  Flex,
  FlexProps,
  Heading,
  Icon,
  Spinner,
  useBreakpointValue
} from "@chakra-ui/react"
import { IconType } from "react-icons/lib";
import { ButtonDefault } from "../ButtonDefault";
import { Header } from "../Header";

type buttonAdditionalProps = {
  name: string;
  onClick?: () => void;
  icon?: IconType;
}

interface MainBaseProps extends FlexProps {
  mainTitle?: string;
  isLoading?: boolean;
  isFetching?: boolean;
  buttonAdditional?: buttonAdditionalProps;
}

const MainBase: ComponentWithAs<"div", MainBaseProps> = ({
  children, isLoading, isFetching, mainTitle, buttonAdditional, ...rest
}) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex
      flex="1"
      direction="column"
      mt={["1", null, null, "2"]}
      px={["3", null, null, "4"]}
      overflow={["auto", null, null, null, "inherit"]}
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: "var(--chakra-colors-gray-400)",
          borderRadius: '25px',
        },
      }}
      {...rest}
    >
      { isWideVersion && <Header title={mainTitle} isLoading={isLoading} isFetching={isFetching} /> }

      { mainTitle &&
        <Flex
          justifyContent={["center", null, null, "end"]}
          alignItems="center"
        >
          { !isWideVersion &&
            <Heading
              color="blue.900"
              fontSize={"xl"}
            >
              { mainTitle }
              { !isLoading && isFetching && <Spinner size="sm" color="gray.500" ml="4" />}
            </Heading>
          }
          { buttonAdditional &&
            <ButtonDefault
              w="120px"
              text={buttonAdditional.name}
              info
              onClick={buttonAdditional.onClick}
              leftIcon={<Icon fontSize="20" as={buttonAdditional.icon} />}
            />
          }
        </Flex>
      }
      {children}
    </Flex>
  )
}

export default MainBase;
