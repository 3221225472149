import {
  Box,
  Flex,
  VStack,
  SimpleGrid,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type CreateFQAFormData = {
  title: string;
  description?: string;
  embed_id?: string;
}

const createFQAFormSchema = yup.object().shape({
  title: yup.string()
    .required('Nome obrigatório'),
  description: yup.string(),
  embed_id: yup.string(),
})

export function CreateFQA() {
  const history = useHistory();
  const toast = useToast()

  const createFQA = useMutation(async (fqa: CreateFQAFormData) => {
    const response = await api.post('fqas', fqa)

    return response.data.level;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('fqas')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createFQAFormSchema)
  })

  const { errors } = formState

  const handleCreateFQA: SubmitHandler<CreateFQAFormData> = async (values) => {
    try {
      await createFQA.mutateAsync({
        ...values,
      });

      toast({
        title: "FQA incluído",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/fqas')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase mainTitle="Incluir F.Q.A.">
          <Box
            as="form"
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handleCreateFQA)}
          >
            <VStack spacing="8" color="blue.900">
              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="title"
                  label="Título"
                  labelSize="lg"
                  error={errors.title}
                  isRequired
                  autoFocus
                  {...register('title')}
                />

                <Input
                  nameInput="description"
                  label="Descrição"
                  labelSize="lg"
                  error={errors.description}
                  {...register('description')}
                />

                <Input
                  nameInput="embed_id"
                  label="ID Video YouTube"
                  labelSize="lg"
                  error={errors.embed_id}
                  {...register('embed_id')}
                />
              </SimpleGrid>
            </VStack>

            <Flex mt="8">
              <Flex
                w="100%"
                spacing={["", "4"]}
                flexDir={["column", "row"]}
                justifyContent={["normal", "flex-end"]}
              >
                <Box w={["100%", "auto"]}>
                  <Link to="/fqas" >
                    <ButtonDefault text="Cancelar" w={["100%", "150px"]} info />
                  </Link>
                </Box>

                <ButtonDefault
                  type="submit"
                  text="Salvar"
                  w={["100%", "150px"]}
                  mt={["3", "0"]}
                  ml={["0", "4"]}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
