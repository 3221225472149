import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
    colors: {
        gray: {
            "900": "#181B23",
            "800": "#1F2029",
            "700": "#353646",
            "600": "#4B4D63",
            "500": "#616480",
            "400": "#797D9A",
            "300": "#9699B0",
            "200": "#B3B5C6",
            "100": "#D1D2DC",
            "50": "#EEEEF2",
            "10": "#F7FAFC",
        },
        blue: {
            "900": "#1e2f50",
            "800": "#22365e",
            "700": "#2c467a",
            "600": "#355494",
            "500": "#3e72ad",
            "400": "#4681c4",
            "300": "#4f92dd",
            "200": "#98c7fd",
        },
        gren: {
            "600": "#50b683",
            "500": "#46a174",
        }
    },
    fonts: {
        heading: 'Noto Sans',
        body: 'Roboto'
    },
    styles: {
        global: {
            body: {
                bg: 'blue.900',
                color: 'gray.50'
            }
        }
    },
    button: {
      focus: {
        outline: 'none'
      }
    }
})
