import {
  Box,
  Flex,
  Input,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState, useRef } from "react";
import { Select as ChakraReactSelect } from "chakra-react-select";
import { differenceInMinutes, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { ButtonDefault } from "../../../components/ButtonDefault";
import { DownloadCSV } from "../../../components/DownloadCSV";
import { api } from "../../../services/api";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import MainActions from "../../../components/MainActions";
import { MainTable } from "../../../components/MainTable";
import { Column } from "react-table";

type TeacherData = {
  id: string;
  name: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type TeacherSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

type TeacherEvent = {
  id: string;
  start_date: string;
  end_date: string;
  title: string;
  is_canceled: boolean;
  startDateFormated: string;
  startHourFormated: string;
  endHourFormatted: string;
  duration: string;
  canceled: string;
  user: {
    username: string;
  }
}

export function TeacherEvents() {
  const [teacherEvents, setTeacherEvents] = useState<TeacherEvent[]>([]);
  const [teachersData, setTeachersData] = useState<TeacherData[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [fileName, setFileName] = useState('Aulas-ministradas.csv');
  const [isLoading, setIsLoading] = useState(false);

  const teacherInputRef = useRef<TeacherSelectInputProps>();

  const csvHeaders = [
    {
      label: 'Data',
      key: 'startDateFormated'
    },
    {
      label: 'Professor',
      key: 'user.username'
    },
    {
      label: 'Aula',
      key: 'title'
    },
    {
      label: 'Cancelada',
      key: 'canceled'
    },
    {
      label: 'Início',
      key: 'startHourFormated'
    },
    {
      label: 'Término',
      key: 'endHourFormatted'
    },
    {
      label: 'Duração (min)',
      key: 'duration'
    },
  ]

  useEffect(() => {
    api.get("/users/list/teachers").then(response => setTeachersData([
      {
        id: "",
        name: "Todos Professores",
      },
      ...response.data
    ]))
  }, [])

  const handleChangeStartDate = useCallback((event) => {
    setStartDate(event.target.value)
  }, [])

  const handleChangeEndDate = useCallback((event) => {
    setEndDate(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    if (
      (
        teacherInputRef.current?.state.value &&
        !!teacherInputRef.current?.state.value.value
      )|| (startDate && endDate)
    ) {
      setIsLoading(true)

      let fileName = 'Aulas-ministradas'

      if (teacherInputRef.current?.state.value){
        fileName += `-${teacherInputRef.current?.state.value.label}`
      }

      if (startDate && endDate) {
        fileName += `-${startDate}-a-${endDate}`
      }

      fileName += '.csv'

      setFileName(fileName)

      const startUTCDate = startDate ? utcToZonedTime(startDate, 'America/Sao_Paulo') : '';
      const endUTCDate = endDate ? utcToZonedTime(endDate, 'America/Sao_Paulo') : '';

      api.get<TeacherEvent[]>('/events/teacher', {
        params: {
          teacher_id: teacherInputRef.current?.state.value ? teacherInputRef.current?.state.value.value : '',
          start_date: startUTCDate ? format(startUTCDate, 'yyyy-MM-dd') : startUTCDate,
          end_date: endUTCDate? format(endUTCDate, 'yyyy-MM-dd') : endUTCDate,
        }
      }).then(response => {
        let hoursRealized = 0
        let classesRealized = 0
        let classesCanceled = 0

        const eventsFormatted = response.data.map(event => {
          const startUTCDate = utcToZonedTime(event.start_date, 'America/Sao_Paulo');
          const endUTCDate = utcToZonedTime(event.end_date, 'America/Sao_Paulo');

          const duration = differenceInMinutes(endUTCDate, startUTCDate)

          if(event.is_canceled) {
            classesCanceled += 1
          } else {
            classesRealized += 1
            hoursRealized += duration
          }

          return {
            ...event,
            canceled: event.is_canceled ? 'Sim' : 'Não',
            startDateFormated: format(startUTCDate, 'dd/MM/yyyy'),
            startHourFormated: format(startUTCDate, 'HH:mm'),
            endHourFormatted: format(endUTCDate, 'HH:mm'),
            duration: `${String(duration)} min`,
          }
        })

        const eventsCancelled = [{
          id: 'cancelled-classes',
          user: {
            username: ''
          },
          title: '',
          canceled: '',
          startDateFormated: `Aulas canceladas - ${classesCanceled}`,
          startHourFormated: '',
          endHourFormatted: '',
          duration: '',
        }]

        const eventsWithTotal = [{
          id: 'realized-classes',
          user: {
            username: ''
          },
          title: '',
          canceled: '',
          startDateFormated: `Aulas realizadas - ${classesRealized}`,
          startHourFormated: '',
          endHourFormatted: '',
          duration: `${Math.floor(hoursRealized / 60)}h${hoursRealized % 60}m`,
        }].concat(eventsCancelled, eventsFormatted);

        setTeacherEvents(eventsWithTotal as TeacherEvent[]);

        setIsLoading(false)
      })
    }
  }, [teacherInputRef, startDate, endDate])

  const columns: Column<TeacherEvent>[] = [
    {
      Header: "Data",
      accessor: "startDateFormated",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Professor",
      //  @ts-ignore TYPE NEEDS FIXING
      accessor: "user.username",
      isSortable: true,
    },
    {
      Header: "Aula",
      accessor: "title",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Cancelada?",
      accessor: "canceled",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Início",
      accessor: "startHourFormated",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Término",
      accessor: "endHourFormatted",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Duração",
      accessor: "duration",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
  ];

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Aulas ministradas"
          isLoading={isLoading}
        >
          <MainActions>
            <Flex
              w="100%"
              direction={["column", null, null, "row"]}
            >
              <Box
                w="100%"
                mr={["0", null, null, "2"]}
                color="blue.900"
              >
                <ChakraReactSelect
                  color="blue.900"
                  options={
                    teachersData.map(teacher => {
                      return (
                        { value: teacher.id, label: teacher.name }
                      )
                    })
                  }
                  placeholder="Selecione o professor"
                  closeMenuOnSelect={true}
                  ref={teacherInputRef}
                />
              </Box>

              <Input
                w="350px"
                mr="2"
                name="startDate"
                type="date"
                color="blue.900"
                borderColor="blue.900"
                focusBorderColor="blue.500"
                _hover={{
                  borderColor: "blue.500"
                }}
                onChange={handleChangeStartDate}
              />

              <Input
                w="350px"
                name="endDate"
                type="date"
                color="blue.900"
                borderColor="blue.900"
                focusBorderColor="blue.500"
                _hover={{
                  borderColor: "blue.500"
                }}
                onChange={handleChangeEndDate}
              />
            </Flex>

            <ButtonDefault
              w={["100%", null, null, "120px"]}
              minW="100px"
              mx={[null, null, null, "2"]}
              mt={["2", null, null, "0"]}
              text="Procurar"
              onClick={handleSearch}
            />

            <DownloadCSV
              text={"Exportar"}
              data={teacherEvents}
              headers={csvHeaders}
              filename={fileName}
            />
          </MainActions>

          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={teacherEvents}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
