import {
  Heading,
  Flex,
  Icon,
  Link,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdOutlinePending } from 'react-icons/md';
import { useLocation, useHistory } from 'react-router-dom';

import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ButtonDefault } from '../../components/ButtonDefault';
import { api } from '../../services/api';
import ContentBase from '../../components/ContentBase';

export function Pending() {
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true)

    const queryParams = new URLSearchParams(location.search);
    const payment_id = queryParams.get('payment_id');

    window.history.replaceState(null, '', '/failure');

    if (!payment_id) {
      history.push('/classes-list')
    }

    try {
      api.post('/purchase-orders/payment', {
        action: 'payment.created',
        data: { id: payment_id }
      })
        .then(response => {
          setIsLoading(false)
        })
        .catch(err => setIsLoading(false));
    } catch (err: any) {
      setIsLoading(false)
    }
  }, [location.search, history])

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        { isLoading
        ? (
          <Flex flex="1" justifyContent="center" alignItems="center">
            <Spinner color="blue.900" size="xl" />
          </Flex>
        )
        : (
          <Flex flex="1"
            direction="column"
            marginY={["2", null, null, "4"]}
            px={["6", null, null, "8"]}
            overflow={["auto", null, null, null, "inherit"]}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
          >
            <Flex
              w="100%"
              h="100%"
              p="8"
              justifyContent="center"
              alignItems="center"
              borderRadius={8}
              boxShadow="lg"
              flexDir="column"
              bg="whitesmoke"
            >
              <Icon
                as={MdOutlinePending}
                color="yellow.400"
                fontSize={["100", "150"]}
                mb="5"
              />

              <Heading
                color="yellow.400"
                mb="5"
                fontSize={["3xl", "5xl"]}
                textAlign="center"
              >
                Estamos aguardando a confirmação do pagamento
              </Heading>

              <Text
                mb="10"
                color="blue.900"
                fontSize={["2xl", "3xl"]}
                textAlign="center"
              >
                Enquanto isso, que tal dar uma olhada nas aulas que estão disponíveis?
              </Text>

              <Link
                to="/classes-list"
                href="/classes-list"
                w={["100%", "350px"]}
              >
                <ButtonDefault
                  text="Aulas Disponíveis"
                  fontSize={["2xl", "3xl"]}
                  w={["100%", "350px"]}
                  h="70"
                />
              </Link>
            </Flex>
          </Flex>
        )}
      </ContentBase>
    </Flex>
  );
}
