import { useQuery } from "react-query";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { api } from "../api";

export type Schedule = {
  id: string;
  event: {
    id: string;
    title: string;
    start_date: string;
  };
  user: {
    id: string;
    name: string;
    email: string;
  };
  startDateFormated: string;
}

export async function getSchedules(): Promise<Schedule[]> {
  const response = await api.get<any>('/schedules')

  const { data } = response

  const schedules = data.map((schedule: Schedule) => {
    const startUTCDate = utcToZonedTime(schedule.event.start_date, 'America/Sao_Paulo');

    return {
      ...schedule,
      startDateFormated: format(startUTCDate, 'dd/MM/yyyy HH:mm')
    }
  })

  return schedules;
}

export function useSchedules() {
  return useQuery(['schedules'], () => getSchedules(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
