import {
  Box,
  Flex,
  HStack,
  VStack,
  SimpleGrid,
  Spinner,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type ParameterData = {
  reference: string;
  description: string;
  value: string;
}

type UpdateParameterFormData = {
  value: string;
}

const updateParameterFormSchema = Yup.object().shape({
  value: Yup.string()
    .required('Valor é obrigatório'),
})

export function UpdateParameter() {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [parameterData, setParameterData] = useState({} as ParameterData);

  const { id: parameterId } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get<ParameterData>(`parameters/${parameterId}`).then(
        response => {
          const { data } = response;

          setParameterData(data)
          setIsLoading(false)
        }
      );
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsLoading(false)
    }
  }, [toast, parameterId])

  const updateParameter = useMutation(async (parameter: UpdateParameterFormData) => {
    const response = await api.post(`/parameters/update/${parameterId}`, parameter)

    return response.data.parameter;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('parameters')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(updateParameterFormSchema),
    context: { parameter_id: parameterId }
  })

  const { errors } = formState

  const handleUpdateParameter: SubmitHandler<UpdateParameterFormData> = async (values) => {
    try {
      await updateParameter.mutateAsync(values);

      toast({
        title: "Parâmetro alterado",
        description: "Alteração realizada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase mainTitle="Alterar parâmetro">
          <Box
            as="form"
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handleUpdateParameter)}
          >
            {isLoading
              ? (
                <Flex justify="center">
                  <Spinner color="blue.900" size="xl" />
                </Flex>
              )
              : (
                  <VStack spacing="8" color="blue.900">
                    <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                      <Input
                        nameInput="reference"
                        label="Parâmetro"
                        labelSize="lg"
                        error={errors.reference}
                        defaultValue={parameterData.reference}
                        isDisabled
                        _disabled={{
                          color: "gray.200"
                        }}
                        {...register('reference')}
                      />
                      <Input
                        nameInput="value"
                        label="Valor"
                        labelSize="lg"
                        error={errors.value}
                        defaultValue={parameterData.value}
                        isRequired
                        {...register('value')}
                      />
                    </SimpleGrid>

                    <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                      <Input
                        nameInput="description"
                        label="Descrição"
                        labelSize="lg"
                        error={errors.description}
                        defaultValue={parameterData.description}
                        isDisabled
                        _disabled={{
                          color: "gray.200"
                        }}
                        {...register('description')}
                      />
                    </SimpleGrid>
                  </VStack>
              )
            }
            <Flex mt="8" justify="flex-end">
              <HStack spacing="4">
                <ButtonDefault
                  text="Cancelar"
                  w="150px"
                  info
                  isLoading={formState.isSubmitting}
                  onClick={() => window.close()}
                />
                <ButtonDefault
                  type="submit"
                  text="Salvar"
                  w="150px"
                  isLoading={formState.isSubmitting}
                />
              </HStack>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
