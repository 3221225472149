import { useQuery } from "react-query";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { api } from "../api";

export type Queue = {
  id: string;
  sugestion: string;
  event: {
    id: string;
    title: string;
    start_date: string;
  };
  user: {
    id: string;
    name: string;
    email: string;
  };
  startDateFormated: string;
}

export async function getQueues(): Promise<Queue[]> {
  const response = await api.get<any>('/queues')

  const { data } = response

  const queues = data.map((queue: Queue) => {
    const startUTCDate = utcToZonedTime(queue.event.start_date, 'America/Sao_Paulo');

    return {
      ...queue,
      startDateFormated: format(startUTCDate, 'dd/MM/yyyy HH:mm'),
    }
  })

  return queues;
}

export function useQueues() {
  return useQuery(['queues'], () => getQueues(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
