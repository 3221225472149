import { useQuery } from "react-query";

import { api } from "../api";

export type Parameter = {
  id: string;
  reference: string;
  description: string;
  value: string;
}

export async function getParameters(): Promise<Parameter[]> {
  const response = await api.get<any>('/parameters')

  const { data } = response

  return data;
}

export function useParameters() {
  return useQuery(['parameters'], () => getParameters(), {
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  })
}
