import { useEffect, useState } from "react";
import { format, utcToZonedTime } from "date-fns-tz";

import {
  Flex,
  Spinner,
  Stack,
  StackDivider,
  Text
} from "@chakra-ui/react";

import { api } from "../../../services/api";
import { MailLogTable } from "../../MailLogTable";
import CustonDrawer from "..";

interface MailLogsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

type IMailLog = {
  id: string;
  error: boolean;
  user_id: string;
  content: string;
  created_at: string;
  createdAtFormatted: string;
}

export function MailLogsDrawer({
  isOpen,
  onClose,
  userId,
} : MailLogsDrawerProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [mailLogsData, setMailLogsData] = useState([] as IMailLog[]);

  useEffect(() => {
    if (isOpen) {
      api.get<IMailLog[]>(`mail-logs/find/${userId}`).then(
        response => {
          const { data } = response;

          const mailLogData = data.map(mailLog => {
            return {
              ...mailLog,
              createdAtFormatted: format(
                utcToZonedTime(mailLog.created_at, 'America/Sao_Paulo'),
                'dd/MM/yyyy HH:mm'
              )
            }
          })

          setMailLogsData(mailLogData)

          setIsLoading(false)
        }
      );
    }
  }, [isOpen, userId])

  return (
    <CustonDrawer
      isOpen={isOpen}
      onClose={onClose}
      title="E-mail enviados"
      size="lg"
    >
      { isLoading ? (
          <Flex justify="center">
            <Spinner color="blue.900" size="xl" />
          </Flex>
        ) : (
          <Stack
            spacing='24px'
            divider={<StackDivider borderColor='gray.200' />}
            mt='6px'
          >
            <Flex
              direction="column"
            >
              <Text
                fontSize="md"
              >
                Segue abaixo lista do log dos ultimos 50 e-mails que foram enviado para este usuário:
              </Text>

              <Flex
                mt="1"
              >
                <MailLogTable
                  mailLogs={mailLogsData}
                />
              </Flex>
            </Flex>
          </Stack>
        )
      }
    </CustonDrawer>
  )
}
