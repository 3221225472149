import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  SimpleGrid,
  Spinner,
  Switch,
  Textarea,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query';
import { Select as ChakraReactSelect } from "chakra-react-select";
import { format, parseISO, isAfter } from "date-fns";

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import { utcToZonedTime } from "date-fns-tz";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import { TextAreaEditor } from "../../../components/TextAreaEditor";
import { ConvertDraftContentToText } from "../../../utils/ConvertDraftContentToText";

type LevelInfo = {
  value: string;
  label: string;
}

type EventData = {
  id: string;
  title: string;
  description: string;
  link: string;
  start_date: string;
  end_date: string;
  student_limit: string;
  instruction: string;
  is_canceled: boolean;
  teacher_id: string;
  name: string;
  startDateFormated: string;
  startHourFormated: string;
  endHourFormatted: string;
  credit: string;
  request_subject: boolean;
  minimum_number_of_students: string
  levels: LevelInfo[];
  has_highlight: boolean;
  for_teachers: boolean;
  description_formatted: string;
  class_subject_id?: string;
  subject?: string;
}

type UpdateEventFormData = {
  name: string;
}

type TeacherData = {
  id: string;
  name: string;
}

type LevelData = {
  id: string;
  name: string;
}

type ClassSubjectsData = {
  id: string;
  subject: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type TeacherSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

type LevelsSelectInputProps = {
  state: {
    value: SelectInputProps[]
  }
}

type ClassSubjectsSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

type ValidationData = {
  ok: boolean;
  message: string;
}

const updateEventFormSchema = Yup.object().shape({
  title: Yup.string().required('Título obrigatório'),
  link: Yup.string()
    .required('Link obrigatório'),
  date: Yup.date()
    .required('Data obrigatória')
    .test(
      'min-date',
      `A data não deve ser inferior a ${format(new Date(), 'dd/MM/yyyy')}`,
      async (value) => {
        if (value) {
          return value && format(value, 'yyyy-MM-dd') >= format(new Date(), 'yyyy-MM-dd')
        }

        return true
      }
    ),
  start_time: Yup.string()
    .required('Início obrigatório')
    .test(
      "start_time",
      'A hora de início não pode ser inferior a hora atual',
      async (value, testContext: any) => {
        const { date } = testContext.parent
        if(date && format(date, 'dd/MM/yyyy') === format(new Date(), 'dd/MM/yyyy')) {
          if (value) {
            return value >= format(new Date(), 'HH:mm')
          }
        }
        return true
      }
    ),
  end_time: Yup.string()
    .required("Término obrigatório")
    .test(
      "end_time",
      'A hora de término não pode ser inferior a hora de início',
      async (value, testContext: any) => {
        const { date, start_time } = testContext.parent
        if(date && format(date, 'dd/MM/yyyy') === format(new Date(), 'dd/MM/yyyy')) {
          if (start_time && value) {
            return value > start_time
          }
        }
        return true
      }
    ),
  credit: Yup.number()
    .min(0, 'Não é permitido quantidade de créditos negativo')
    .integer('A quantidade de créditos deve ser um valor inteiro'),
  request_subject: Yup.boolean(),
  has_highlight: Yup.boolean(),
  for_teachers: Yup.boolean(),
  student_limit: Yup.number()
    .min(0, 'Não é permitido quantidade de alunos negativo')
    .integer('A quantidade de horas deve ser um valor inteiro'),
  minimum_number_of_students: Yup.number()
    .min(0, 'Não é permitido quantidade negativa')
    .integer('O mínimo de alunos deve ser um valor inteiro')
    .test(
      "minimumValue",
      'O mínimo de alunos não pode ser superior ao limite de alunos',
      async (value, testContext: any) => {
        const { student_limit } = testContext.parent
        if(value && student_limit > 0) {
          return value <= student_limit
        }
        return true
      }
    ),
  instruction: Yup.string(),
})

export function UpdateEvent() {
  const toast = useToast()

  const teacherInputRef = useRef<TeacherSelectInputProps>();
  const levelsInputRef = useRef<LevelsSelectInputProps>();
  const classSubjectsInputRef = useRef<ClassSubjectsSelectInputProps>()

  const cancelRef = useRef(null)

  const [teachersData, setTeachersData] = useState<TeacherData[]>([]);
  const [levelsData, setLevelsData] = useState<LevelData[]>([]);
  const [classSubjectsData, setClassSubjectsData] = useState<ClassSubjectsData[]>([])

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [eventData, setEventData] = useState({} as EventData);
  const [onlyConsult, setOnlyConsult] = useState(false);
  const [hasStudent, setHasStudent] = useState(false);
  const [mailMessage, setMailMessage] = useState('');
  const [forTeachers, setForTeachers] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const onEditorStateChange = (editorStates: EditorState) => {
    setEditorState(editorStates);
  };

  const { id: eventId } = useParams<{ id: string }>();

  const location = useLocation();

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get<EventData>(`events/find/${eventId}`).then(
        response => {
          const { data } = response;
          const startUTCDate = utcToZonedTime(data.start_date, 'America/Sao_Paulo');
          const endUTCDate = utcToZonedTime(data.end_date, 'America/Sao_Paulo');

          setEventData({
            ...data,
            startDateFormated: format(startUTCDate, 'yyyy-MM-dd'),
            startHourFormated: format(startUTCDate, 'HH:mm'),
            endHourFormatted: format(endUTCDate, 'HH:mm'),
          })

          const contentBlock = htmlToDraft(data.description_formatted || data.description);

          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
          }

          setIsLoading(false)
        }
      );
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsLoading(false)
    }
  }, [toast, eventId, location.search])

  useEffect(() => {
    if (isAfter(new Date(), parseISO(eventData.start_date))) {
      setOnlyConsult(true);
    }
  }, [eventData])

  useEffect(() => {
    api.get("/users/list/teachers").then(response => setTeachersData(response.data))
  }, [])

  useEffect(() => {
    api.get("/levels").then(response => setLevelsData(response.data))
  }, [])

  useEffect(() => {
    api.get("/class-subjects").then(response => setClassSubjectsData(response.data))
  }, [])

  useEffect(() => {
    api.get<ValidationData>(`/events/valid/delete`, {
      params: {
        event_id: eventId,
      }
    }).then(response => {setHasStudent(!response.data.ok)})
  }, [eventId])

  useEffect(() => {
    setForTeachers(eventData.for_teachers)
  }, [eventData.for_teachers])

  const updateEvent = useMutation(async (event: UpdateEventFormData) => {
    const response = await api.post(`/events/update/${eventId}`, event)

    return response.data.event;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('events')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(updateEventFormSchema),
    context: { event_id: eventId }
  })

  const { errors } = formState

  const handleChangeMessage = useCallback((event) => {
    setMailMessage(event.target.value);
  }, []);

  const handleChangeForTeachers = useCallback((event) => {
    setForTeachers(event.target.checked);
  }, [])

  const handleUpdateEvent: SubmitHandler<UpdateEventFormData> = async (values: any) => {
    try {
      let levelsIds

      if (typeof levelsInputRef.current?.state.value === 'object') {
        levelsIds = levelsInputRef.current?.state.value.map(level => level.value)
      }

      const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      const editorContent = ConvertDraftContentToText(editorState)

      await updateEvent.mutateAsync({
        ...values,
        description: editorContent,
        description_formatted: body,
        start_date: utcToZonedTime(
          `${format( values.date, 'yyyy-MM-dd' )} ${values.start_time}:00`,
          'America/Sao_Paulo'
        ).toISOString(),
        end_date: utcToZonedTime(
          `${format( values.date, 'yyyy-MM-dd' )} ${values.end_time}:00`,
          'America/Sao_Paulo'
        ).toISOString(),
        teacher_id: teacherInputRef.current?.state.value.value,
        levels: levelsIds?.toString(),
        class_subject_id: classSubjectsInputRef.current?.state.value.value,
      });

      toast({
        title: "Aula alterada",
        description: "Alteração realizada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleDisabled = async () => {
    setIsSubmitting(true)
    try {
      await api.post(`/events/canceled/${eventId}`, { mailMessage })

      queryClient.invalidateQueries('events')

      toast({
        title: "Aula cancelada",
        description: "Aula cancelada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const handleActivated = async () => {
    setIsSubmitting(true)
    try {
      await api.post(`/events/activated/${eventId}`)

      queryClient.invalidateQueries('events')

      toast({
        title: "Aula ativa",
        description: "Aula está novamente ativa",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const handleDelete = async () => {
    setIsOpenAlert(false)
    setIsSubmitting(true)
    try {
      const { data: canDelete } = await api.get<ValidationData>(`/events/valid/delete`, {
        params: {
          event_id: eventId,
        }
      })

      if (!canDelete.ok) {
        toast({
          title: "Exclusão não é permitida",
          description: canDelete.message,
          status: "error",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })
        setIsSubmitting(false)

        return
      }

      await api.delete(`/events/${eventId}`)

      queryClient.invalidateQueries('events')

      toast({
        title: "Aula excluída",
        description: "Aula excluído com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setIsSubmitting(false)

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <>
      <Flex direction="column" h="100vh">
        { !isWideVersion && <Header /> }

        <ContentBase>
          <Sidebar />

          <MainBase mainTitle="Alterar aula">
            <Box
              as="form"
              h={["", "88vh"]}
              pb={"1rem"}
              overflow="auto"
              paddingRight="1"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: "var(--chakra-colors-gray-400)",
                  borderRadius: '25px',
                },
              }}
              onSubmit={handleSubmit(handleUpdateEvent)}
            >
              {isLoading
              ? (
                <Flex justify="center">
                  <Spinner color="blue.900" size="xl" />
                </Flex>
              )
              : (
                <VStack spacing="8" color="blue.900">
                  <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                    <Input
                      nameInput="title"
                      label="Título"
                      labelSize="lg"
                      error={errors.title}
                      defaultValue={eventData.title}
                      isRequired
                      isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                      _disabled={{
                        color: "gray.200"
                      }}
                      {...register('title')}
                    />
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                    <FormControl alignItems="center" isRequired>
                      <FormLabel htmlFor="link">
                        Descrição
                      </FormLabel>
                      <TextAreaEditor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        readOnly={onlyConsult || eventData.is_canceled}
                      />
                    </FormControl>
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                    <FormControl
                      alignItems="center"
                      isRequired
                      isDisabled={onlyConsult || eventData.is_canceled}
                      _disabled={{
                        color: "gray.200"
                      }}
                    >
                      <FormLabel htmlFor="link">
                        Link da aula
                      </FormLabel>
                      <Textarea
                        placeholder="https://web.zoom.us/&#10;ID da reunião: 999 9999 9999&#10;Senha de acesso: ********"
                        error={errors.link}
                        defaultValue={eventData.link}
                        css={{
                          '&::-webkit-scrollbar': {
                            width: '4px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: "var(--chakra-colors-gray-200)",
                            borderRadius: '25px',
                          },
                        }}
                        {...register('link')}
                      />
                    </FormControl>

                    <SimpleGrid minChildWidth="180px" spacing={["6", "8"]} w="100%">
                      <Input
                        nameInput="date"
                        label="Data"
                        type="date"
                        labelSize="lg"
                        error={errors.date}
                        defaultValue={eventData.startDateFormated}
                        isRequired
                        isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                        _disabled={{
                          color: "gray.200"
                        }}
                        {...register('date')}
                      />
                      <Input
                        nameInput="start_time"
                        type="time"
                        label="Horário do início"
                        labelSize="lg"
                        error={errors.start_time}
                        defaultValue={eventData.startHourFormated}
                        isRequired
                        isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                        _disabled={{
                          color: "gray.200"
                        }}
                        {...register('start_time')}
                      />
                      <Input
                        nameInput="end_time"
                        type="time"
                        label="Horário do término"
                        labelSize="lg"
                        error={errors.end_time}
                        defaultValue={eventData.endHourFormatted}
                        isRequired
                        isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                        _disabled={{
                          color: "gray.200"
                        }}
                        {...register('end_time')}
                      />
                    </SimpleGrid>
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="200px" spacing={["6", "8"]} w="100%">
                    <Input
                      nameInput="credit"
                      type="number"
                      label="Quantidade de créditos"
                      labelSize="lg"
                      error={errors.credit}
                      defaultValue={eventData.credit}
                      isRequired
                      isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                      _disabled={{
                        color: "gray.200"
                      }}
                      {...register('credit')}
                    />
                    <FormControl
                      alignItems="center"
                      isDisabled={onlyConsult || eventData.is_canceled}
                      _disabled={{
                        color: "gray.200"
                      }}
                    >
                      <FormLabel htmlFor="class-subject" mb="4">
                        Tema da aula
                      </FormLabel>
                      <ChakraReactSelect
                        options={
                          classSubjectsData.map(classSubject => {
                            return (
                              { value: classSubject.id, label: classSubject.subject }
                            )
                          })
                        }
                        placeholder="Selecione ..."
                        defaultValue={{
                          label: eventData.subject,
                          value: eventData.class_subject_id
                        }}
                        closeMenuOnSelect={true}
                        error={errors.classSubject}
                        ref={classSubjectsInputRef}
                      />
                    </FormControl>
                    <FormControl
                      display="flex"
                      alignItems="center"
                      isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                    >
                      <FormLabel htmlFor="request_subject" mb="0">
                        Solicitar assunto ?
                      </FormLabel>
                      <Switch
                        id="request_subject"
                        colorScheme="green"
                        defaultChecked={eventData.request_subject}
                        {...register('request_subject')}
                        isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                      />
                    </FormControl>
                    <FormControl
                      display="flex"
                      alignItems="center"
                      isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                    >
                      <FormLabel htmlFor="has_highlight" mb="0">
                        Destacar aula ?
                      </FormLabel>
                      <Switch
                        id="has_highlight"
                        colorScheme="green"
                        defaultChecked={eventData.has_highlight}
                        {...register('has_highlight')}
                        isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                      />
                    </FormControl>
                    <FormControl
                      display="flex"
                      alignItems="center"
                      isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                    >
                      <FormLabel htmlFor="for_teachers" mb="0">
                        Aula para professores ?
                      </FormLabel>
                      <Switch
                        id="for_teachers"
                        colorScheme="green"
                        defaultChecked={eventData.for_teachers}
                        {...register('for_teachers')}
                        isDisabled={onlyConsult || eventData.is_canceled || hasStudent}
                        onChange={handleChangeForTeachers}
                      />
                    </FormControl>
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                    <Input
                      nameInput="student_limit"
                      type="number"
                      label="Limite de alunos"
                      labelSize="lg"
                      error={errors.student_limit}
                      defaultValue={eventData.student_limit}
                      isDisabled={onlyConsult || eventData.is_canceled}
                      _disabled={{
                        color: "gray.200"
                      }}
                      {...register('student_limit')}
                    />
                    <Input
                      nameInput="minimum_number_of_students"
                      type="number"
                      label="Mínimo de alunos"
                      labelSize="lg"
                      error={errors.minimum_number_of_students}
                      defaultValue={eventData.minimum_number_of_students}
                      isDisabled={onlyConsult || eventData.is_canceled}
                      _disabled={{
                        color: "gray.200"
                      }}
                      {...register('minimum_number_of_students')}
                    />
                    <FormControl
                      alignItems="center"
                      isRequired
                      isDisabled={onlyConsult || eventData.is_canceled}
                      _disabled={{
                        color: "gray.200"
                      }}
                    >
                      <FormLabel htmlFor="teacher" mb="4">
                        Professor
                      </FormLabel>
                      <ChakraReactSelect
                        options={
                          teachersData.map(teacher => {
                            return (
                              { value: teacher.id, label: teacher.name }
                            )
                          })
                        }
                        defaultValue={{
                          label: eventData.name,
                          value: eventData.teacher_id
                        }}
                        placeholder="Selecione o professor"
                        closeMenuOnSelect={true}
                        error={errors.teacher}
                        ref={teacherInputRef}
                      />
                    </FormControl>

                    <FormControl
                      alignItems="center"
                      isDisabled={onlyConsult || eventData.is_canceled || hasStudent || forTeachers}
                      _disabled={{
                        color: "gray.200"
                      }}
                    >
                      <FormLabel htmlFor="teacher" mb="4">
                        Nível da aula
                      </FormLabel>
                      <ChakraReactSelect
                        name="levels"
                        isMulti
                        options={
                          levelsData.map(level => {
                            return (
                              { value: level.id, label: level.name }
                            )
                          })
                        }
                        defaultValue={
                          eventData.levels.map(level => ({
                            label: level.label,
                            value: level.value
                          }))
                        }
                        placeholder="Selecione ..."
                        closeMenuOnSelect={false}
                        error={errors.leves}
                        ref={levelsInputRef}
                      />
                    </FormControl>
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                    <FormControl
                      alignItems="center"
                      isDisabled={onlyConsult || eventData.is_canceled}
                      _disabled={{
                        color: "gray.200"
                      }}
                    >
                      <FormLabel htmlFor="is_teacher">
                        Instruções
                      </FormLabel>
                      <Textarea
                        placeholder="Preencha com os passos que o aluno deve realizar para se preparar para a aula"
                        error={errors.leves}
                        defaultValue={eventData.instruction}
                        css={{
                          '&::-webkit-scrollbar': {
                            width: '4px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: "var(--chakra-colors-gray-200)",
                            borderRadius: '25px',
                          },
                        }}
                        {...register('instruction')}
                      />
                    </FormControl>
                  </SimpleGrid>
                </VStack>
              ) }

              <Flex
                mt="8"
                justify={["normal", "space-between"]}
                flexDir={["column", "row"]}
              >
                <HStack spacing="4">
                  <ButtonDefault
                    text="Excluir Aula"
                    w={["100%", "150px"]}
                    attencion
                    onClick={() => setIsOpenAlert(true)}
                    isLoading={formState.isSubmitting || isSubmitting}
                    disabled={onlyConsult}
                  />

                  {eventData.is_canceled
                  ? <ButtonDefault
                      text="Ativar Aula"
                      w={["100%", "150px"]}
                      warning
                      onClick={handleActivated}
                      isLoading={formState.isSubmitting || isSubmitting}
                      disabled={onlyConsult}
                    />
                  : <ButtonDefault
                      text="Cancelar Aula"
                      w={["100%", "150px"]}
                      warning
                      onClick={hasStudent ? () => setIsOpenCancel(true) : handleDisabled}
                      isLoading={formState.isSubmitting || isSubmitting}
                    />
                  }
                </HStack>

                <Flex
                  w={["100%", "auto"]}
                  mt={["6", "0"]}
                  flexDir={["column", "row"]}
                >
                  <Box w={["100%", "auto"]}>
                    <ButtonDefault
                      text="Cancelar"
                      w={["100%", "150px"]}
                      info
                      isLoading={formState.isSubmitting}
                      onClick={() => window.close()}
                    />
                  </Box>

                  <ButtonDefault
                    type="submit"
                    text="Salvar"
                    w={["100%", "150px"]}
                    ml={["0", "4"]}
                    mt={["6", "0"]}
                    isLoading={formState.isSubmitting}
                    disabled={onlyConsult || eventData.is_canceled}
                  />
                </Flex>
              </Flex>
            </Box>
          </MainBase>
        </ContentBase>
      </Flex>

      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpenAlert(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="bold" textColor="blue.900">
              Excluir aula
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" textColor="blue.900">
              Tem certeza que deseja excluir esta aula?
            </AlertDialogBody>
            <AlertDialogBody textColor="red">
              Não será possível desfazer esta ação depois.
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonDefault text="Cancelar" w="100px" info onClick={() => setIsOpenAlert(false)} />
              <ButtonDefault text="Excluir" w="100px" attencion onClick={handleDelete} ml={3} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpenCancel}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpenCancel(false)}
        isCentered
        size="2xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="bold" textColor="blue.900">
              Cancelar aula
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" textColor="blue.900">
              Esta aula possui alunos matriculados. Para cancelar digite abaixo uma mensagem que será
              enviado para os alunos matriculados.

              <Textarea
                mt="4"
                onChange={handleChangeMessage}
                resize="none"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: "var(--chakra-colors-gray-200)",
                    borderRadius: '25px',
                  },
                }}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonDefault text="Cancelar" w="100px" info onClick={() => setIsOpenCancel(false)} />
              <ButtonDefault
                text="Confirmar"
                attencion
                onClick={handleDisabled}
                w="100px"
                ml={3}
              />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
