import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  VStack,
  SimpleGrid,
  useToast,
  useBreakpointValue,
  Spinner,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from "react-query";
import { Select as ChakraReactSelect } from "chakra-react-select";
import { Column } from "react-table";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import { MainTable } from "../../../components/MainTable";
import { SearchInput } from "../../../components/SearchInput";

type CreateCompanyFormData = {
  company_id: string;
  users: any[];
}

type SelectInputProps = {
  label: string;
  value: string;
}

type CompanyMember = {
  isChecked: boolean;
  id: string;
  name: string;
  email: string;
  created_at: string;
  registeredDate: string;
}

type CompanyData = {
  id: string;
  name: string;
}

type CompanySelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

export function CreateCompany() {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })
  const [companiesData, setCompaniesData] = useState<CompanyData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [users, setUsers] = useState<CompanyMember[]>([]);
  const [showUsers, setShowUsers] = useState<CompanyMember[]>([]);

  const companyInputRef = useRef<CompanySelectInputProps>();

  const history = useHistory();

  const toast = useToast()

  const createCompany = useMutation(async (companyMembers: CreateCompanyFormData) => {
    const response = await api.post('company-members', companyMembers)

    return response.data;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('company-members')
    }
  });

  const { handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  })

  const handleCreateCompany: SubmitHandler<CreateCompanyFormData> = async () => {
    try {
      if (!companyInputRef.current?.state.value.value) {
        toast({
          title: "Campos obrigatórios",
          description: "É necessário selecionar uma empresa.",
          status: "warning",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })

        return
      }

      const members: any[] = []

      users.map(user => {
        if (user.isChecked) {
          members.push(user.id)
        }

        return true
      })

      if (members.length <= 0) {
        toast({
          title: "Campos obrigatórios",
          description: "É necessário selecionar ao menos um aluno.",
          status: "warning",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })

        return
      }

      await createCompany.mutateAsync({
        company_id: companyInputRef.current.state.value.value,
        users: members
      })

      toast({
        title: "Nível incluído",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/company')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleChangeCheckbox = useCallback((e, id) => {
    const newUsers = [...users];
    const newShowUsers = [...showUsers];

    const userIndex = newUsers.findIndex(user => user.id === id);
    const showUserIndex = newShowUsers.findIndex(user => user.id === id);

    newUsers[userIndex].isChecked = e.target.checked;
    newShowUsers[showUserIndex].isChecked = e.target.checked;

    setUsers(newUsers)
    setShowUsers(newShowUsers)
  }, [users, showUsers]);

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value);
  }, []);

  const handleSearch = useCallback(() => {
    if (users) {
      if (searchField) {
        const usersFiltered = users.filter(user =>
          user.name.toLowerCase().match(searchField.toLocaleLowerCase())
        )

        setShowUsers(usersFiltered)

        return
      }

      setShowUsers(users)
    }
  }, [searchField, users])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSearch();
    }
  }, [handleSearch])

  useEffect(() => {
    api.get("/users/list/company-available").then(response => setCompaniesData(response.data))
  }, [])

  useEffect(() => {
    setIsLoading(true)
    api.get<CompanyMember[]>("/company-members/members/available")
      .then(response => {
        const members = response.data.map((member: CompanyMember) => {
          const registeredUTCDate = utcToZonedTime(member.created_at, 'America/Sao_Paulo');

          return {
            ...member,
            isChecked: false,
            registeredDate: format(registeredUTCDate, 'dd/MM/yyyy'),
          }
        });

        setUsers(members);
        setShowUsers(members);
        setIsLoading(false);
      })
  }, []);

  const columns: Column<CompanyMember>[] = [
    {
      Header: "",
      accessor: "isChecked",
      //  @ts-ignore TYPE NEEDS FIXING
      type: "check",
      handlerCheck: handleChangeCheckbox,
      idCheckRef: 'id',
    },
    {
      Header: "Aluno",
      accessor: "name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "E-mail",
      accessor: "email",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Data Cadastro",
      accessor: "created_at",
      //  @ts-ignore TYPE NEEDS FIXING
      formattedValue: "registeredDate",
      isSortable: true,
    },
  ];

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase mainTitle="Incluir grupo">
          <Box
            as="form"
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handleCreateCompany)}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : (
              <>
                <VStack spacing="8" color="blue.900" w="50%" mb="2rem">
                  <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                    <FormControl alignItems="center" isRequired>
                      <FormLabel htmlFor="company" mb="4">
                        Empresa
                      </FormLabel>
                      <ChakraReactSelect
                        options={
                          companiesData.map(company => {
                            return (
                              { value: company.id, label: company.name }
                            )
                          })
                        }
                        placeholder="Selecione a empresa"
                        defaultValue={''}
                        closeMenuOnSelect={true}
                        ref={companyInputRef}
                      />
                    </FormControl>
                  </SimpleGrid>
                </VStack>

                <Box>
                  <Flex
                    w={"100%"}
                    direction={["column", null, null, "row"]}
                    mb="10px"
                  >
                    <SearchInput
                      handleChangeSearch={handleChangeSearch}
                      handleOnPressEnter={handleOnPressEnter}
                      searchPlaceHolder="Digite o nome do aluno que deseja procurar..."
                      showIcon={false}
                    />

                    <ButtonDefault
                      w={["100%", null, null, "100px"]}
                      minW="100px"
                      mt={["2", null, null, "0"]}
                      text="Procurar"
                      onClick={handleSearch}
                    />
                  </Flex>
                  <MainTable
                    columns={columns}
                    data={showUsers}
                  />
                </Box>

                <Flex mt="8">
                  <Flex
                    w="100%"
                    spacing={["", "4"]}
                    flexDir={["column", "row"]}
                    justifyContent={["normal", "flex-end"]}
                  >
                    <Box w={["100%", "auto"]}>
                      <Link to="/company" >
                        <ButtonDefault text="Cancelar" w={["100%", "150px"]} info />
                      </Link>
                    </Box>

                    <ButtonDefault
                      type="submit"
                      text="Salvar"
                      w={["100%", "150px"]}
                      mt={["3", "0"]}
                      ml={["0", "4"]}
                      isLoading={formState.isSubmitting}
                    />
                  </Flex>
                </Flex>
              </>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
