import { api } from "../../services/api"

async function ClassSubjectExist(value?: string, class_subject_id?: string): Promise<boolean> {
  if (value) {
    const { data } = await api.get(`/class-subjects/valid`, {
      params: {
        field: 'subject',
        value,
        class_subject_id,
      }
    })

    return !data
  }

  return true
}

export { ClassSubjectExist }
