import {
  Box,
  Flex,
  Spinner,
  useBreakpointValue,
  useToast
} from "@chakra-ui/react";
import { format, utcToZonedTime } from "date-fns-tz";
import { useState, useEffect } from "react";
import ContentBase from "../../components/ContentBase";

import { ExtractTable } from "../../components/Extract/ExtractTable";
import { Header } from "../../components/Header";
import MainBase from "../../components/MainBase";
import { Sidebar } from "../../components/Sidebar";
import { api } from "../../services/api";

type Statement = {
  id: string;
  type: string;
  amount: string;
  description: string;
  operation_date: string;
  operation_dateFormated: string;
}

type Statements = {
  balance: number;
  expirationDate: string;
  statement: Statement[];
}

export function Extract() {
  const [statements, setStatements] = useState<Statement[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const toast = useToast();

  useEffect(() => {
    try {
      setLoading(true)
      api.get<Statements>("/statements?withStatement=true").then(response => {
        const { statement } = response.data;

        const statementsData = statement.map(statementData => {
          return {
            ...statementData,
            operation_dateFormated: format(
              utcToZonedTime(statementData.operation_date, 'America/Sao_Paulo'),
              'dd/MM/yyyy HH:mm'
            )
          }
        })
        setStatements(statementsData);
        setLoading(false)
      })
    } catch (err) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setLoading(false)
    }
  }, [toast])

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Histórico"
          isLoading={isLoading}
        >
          <Box
            h={["", "86vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
          >
            { isLoading ?
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            :
              <>
                <ExtractTable statements={statements} />
              </>
            }
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  )
}
