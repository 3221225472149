import { useCallback, useState, useEffect, useMemo } from "react";
import {
  Flex,
  SimpleGrid,
  Box,
  Text,
  useBreakpointValue
} from "@chakra-ui/react";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR"
import DayPicker, { DayModifiers } from 'react-day-picker';
import { utcToZonedTime } from "date-fns-tz";

import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { api } from "../../services/api";

import { ClassTab } from "./ClassTab";
import { Calendar } from "./styles";

import 'react-day-picker/lib/style.css';
import ContentBase from "../../components/ContentBase";
import MainBase from "../../components/MainBase";

type MonthAvailabilityItem = {
  day: number;
  available: boolean;
}

type Event = {
  id: string;
  title: string;
  description: string;
  start_date: string;
  startHourFormated: string;
  end_date: string;
  endHourFormatted: string;
  is_canceled: boolean;
  user: {
    name: string;
    email: string;
  }
  description_formatted: string;
}

export function Dashboard() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthAvailability, setMonthAvailability] = useState<MonthAvailabilityItem[]>([]);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, [])

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
  }, []);

  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    api.get('/events/availability', {
      params: {
        year: currentMonth.getFullYear(),
        month: currentMonth.getMonth() + 1,
      }
    }).then(response => {
      setMonthAvailability(response.data);
    })
  }, [currentMonth]);

  useEffect(() => {
    api.get<Event[]>('/events/day', {
      params: {
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
        day: selectedDate.getDate(),
      }
    }).then(response => {
      const eventsFormatted = response.data.map(event => {
        const startUTCDate = utcToZonedTime(event.start_date, 'America/Sao_Paulo');
        const endUTCDate = utcToZonedTime(event.end_date, 'America/Sao_Paulo');

        return {
          ...event,
          startHourFormated: format(startUTCDate, 'HH:mm'),
          endHourFormatted: format(endUTCDate, 'HH:mm'),
        }
      })
      setEvents(eventsFormatted);
    })
  }, [selectedDate])

  const disabledDays = useMemo(() => {
    const dates = monthAvailability
      .filter(monthDay => monthDay.available === false)
      .map(monthDay => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();

        const date = new Date(year, month, monthDay.day);

        return date
      })

    return dates;
  }, [currentMonth, monthAvailability]);

  const selectedDateAsText = useMemo(() => {
    return format(selectedDate, "dd 'de' MMMM 'de' yyyy", {
      locale: ptBR,
    })
  }, [selectedDate])

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Aulas Programadas"
        >
          <Flex
            flex="1"
            p={"2"}
            bg="white"
            borderRadius="5px"
          >
            <Box flex="1" borderRadius="10">
              {!isWideVersion
                ? <Calendar>
                    <DayPicker
                      weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                      disabledDays={[...disabledDays]}
                      modifiers={{
                        available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] }
                      }}
                      onMonthChange={handleMonthChange}
                      selectedDays={selectedDate}
                      onDayClick={handleDateChange}
                      months={[
                        'Janeiro',
                        'Fevereiro',
                        'Março',
                        'Abril',
                        'Maio',
                        'Junho',
                        'Julho',
                        'Agosto',
                        'Setembro',
                        'Outubro',
                        'Novembro',
                        'Dezembro'
                      ]}
                    />
                  </Calendar>
                : <Text color="blue.800">{selectedDateAsText}</Text>
              }

              <Box
                h={["", "74vh"]}
                overflow="auto"
                paddingRight={["0", "2"]}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: "var(--chakra-colors-gray-400)",
                    borderRadius: '25px',
                  },
                }}
              >
                <SimpleGrid
                  flex="1"
                  m="1"
                  columns={[1,null,null,1,3,4]}
                  gap="2"
                >
                  {events.length === 0 && (
                    <Text color="blue.700">Não há aulas agendadas</Text>
                  )}

                  {events.map(event => (
                    <ClassTab
                      key={event.id}
                      startHour={event.startHourFormated}
                      endHour={event.endHourFormatted}
                      title={event.title}
                      teacher={event.user}
                      isCanceled={event.is_canceled}
                      eventId={event.id}
                      eventDescription={event.description_formatted || event.description}
                    />
                  ))}
                </SimpleGrid>
              </Box>
            </Box>
            { isWideVersion &&
              <Calendar>
                <DayPicker
                  weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                  disabledDays={[...disabledDays]}
                  modifiers={{
                    available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] }
                  }}
                  onMonthChange={handleMonthChange}
                  selectedDays={selectedDate}
                  onDayClick={handleDateChange}
                  months={[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                  ]}
                />
              </Calendar>
            }
          </Flex>
        </MainBase>
      </ContentBase>
    </Flex>
  )
}
