import {
  Box,
  Heading,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  Textarea
} from "@chakra-ui/react";
import { useCallback, useState } from "react";

import { Participation } from "../../pages/Gift";
import { api } from "../../services/api";

import { ButtonDefault } from "../ButtonDefault";

interface RemoveCreditModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedUsers: Participation[];
  uncheckUsers: () => void;
}

export function RemoveCreditModal({
  isOpen,
  onClose,
  selectedUsers,
  uncheckUsers,
}: RemoveCreditModalProps) {
  const [credit, setCredit] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [mailMessage, setMailMessage] = useState('');

  const toast = useToast();

  const handleChangeMessage = useCallback((event) => {
    setMailMessage(event.target.value);
  }, []);

  const handleChangeCredit = useCallback((event) => {
    setCredit(event.target.value);
  }, []);

  const handleRemoveCredit = useCallback(async () => {
    if (credit > 0) {
      setIsLoading(true)

      try {
        await api.post('/users/remove-credit', { credit, users: selectedUsers, mailMessage })

        toast({
          title: "Créditos removidos",
          description: "Créditos removidos com sucesso!",
          status: "success",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })

        uncheckUsers()
        setIsLoading(false)

        onClose()
      } catch {
        toast({
          title: "Opa...",
          description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
          status: "error",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })

        setIsLoading(false)
      }
    }
  }, [credit, onClose, selectedUsers, toast, uncheckUsers, mailMessage])

  return (
    <Modal
      size="6xl"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="blue.900">
          <Heading>Lista de Alunos</Heading>
        </ModalHeader>
        <ModalCloseButton color="blue.900" />
        <ModalBody my="2" fontSize="xl" color="blue.900">
          <Text mb="6">
            Informe a quantidade de créditos que deseja remover
            <Input
              name="credit"
              type="number"
              placeholder="0"
              onChange={handleChangeCredit}
              px="2"
              w="50px"
              mx="2"
              color="blue.900"
              borderColor="blue.900"
              focusBorderColor="blue.500"
              _hover={{
                borderColor: "blue.500"
              }}
            />
            .
          </Text>

          <Textarea
            mb="4"
            onChange={handleChangeMessage}
            resize="none"
            placeholder="Caso deseje, digite o motivo pelo qual os créditos desse(s) aluno(s) serão removidos."
            required
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-200)",
                borderRadius: '25px',
              },
            }}
          />

          <Text mb="6">
            Será removido os créditos dos alunos listados abaixo:
          </Text>

          <Box
            w="100%"
            h="50vh"
            overflow="auto"
            paddingRight="1"
            color="blue.900"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-200)",
                borderRadius: '25px',
              },
            }}
          >
            <Table>
              <Thead>
                <Tr bgColor="blue.50">
                  <Th minW="200px">Aluno</Th>
                  <Th minW="250px">E-mail</Th>
                  <Th>Data Cadastro</Th>
                  <Th>Aulas Matriculadas</Th>
                  <Th>Total de Crédito Gasto</Th>
                  <Th>Total Crédito Presenteado</Th>
                </Tr>
              </Thead>
              <Tbody>
                {selectedUsers.map(user => {
                  return (
                    <Tr key={user.user_id}>
                      <Td>
                        <Text fontWeight="bold">{user.name}</Text>
                      </Td>
                      <Td>{user.email}</Td>
                      <Td>{user.registeredDate}</Td>
                      <Td>{user.participation}</Td>
                      <Td>{user.total_spent}</Td>
                      <Td>{user.gift_credits}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>

        <ModalFooter
          flexDir={["column", "row"]}
          justifyContent="space-between"
        >
          <ButtonDefault
            w={["80%", "120px"]}
            text="Cancelar"
            info
            onClick={onClose}
            isLoading={isLoading}
          />
          <ButtonDefault
            w={["80%", "120px"]}
            text="Remover"
            attencion
            onClick={handleRemoveCredit}
            isLoading={isLoading}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
