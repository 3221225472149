import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Flex,
  HStack,
  VStack,
  SimpleGrid,
  Spinner,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import { CouponCodeExist } from "../../../utils/validations/CouponCodeExist";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type CouponData = {
  code: string;
  amount: string;
  limit: string;
  expiration_date: string;
  expirationFormated: string;
  used: string;
}

type UpdateCouponFormData = {
  code: string;
  amount: string;
  user_id: string;
}

type ValidationData = {
  ok: boolean;
  message: string;
}

const updateCouponFormSchema = Yup.object().shape({
  code: Yup.string()
    .required('Nome obrigatório')
    .test(
      'code-exists',
      'Já existe um cupom disponível com este código.',
      async (value, testContext: any) => (
        await CouponCodeExist(value, testContext.options.context.coupon_id)
      )
    ),
  amount: Yup.number().required('Percentual de desconto obrigatório')
    .min(1, 'No mínimo 1')
    .max(100, 'O percentual não deve ser superior a 100')
    .positive('O valor deve ser superior a 1'),
  limit: Yup.number().required('Quantidade de créditos obrigatório')
    .min(1, 'No mínimo 1 crédito')
    .positive('No mínimo 1 crédito')
    .integer('A quantidade de créditos deve ser um valor inteiro'),
  expiration_date: Yup.date(),
})

export function UpdateCoupon() {
  const toast = useToast();

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [couponData, setCouponData] = useState({} as CouponData);
  const [wasUsed, setWasUsed] = useState(false);

  const cancelRef = useRef(null)

  const { id: couponId } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get<CouponData>(`coupons/${couponId}`).then(
        response => {
          const expirationUTCDate = utcToZonedTime(response.data.expiration_date, 'America/Sao_Paulo');

          const coupon = {
            ...response.data,
            expirationFormated: format(expirationUTCDate, 'yyyy-MM-dd'),
          }

          setWasUsed(Number(coupon.used) > 0)

          setCouponData(coupon)

          setIsLoading(false)
        }
      );
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsLoading(false)
    }
  }, [toast, couponId])

  const updateCoupon = useMutation(async (coupon: UpdateCouponFormData) => {
    const response = await api.post(`/coupons/update/${couponId}`, coupon)

    return response.data.coupon;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('coupons')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(updateCouponFormSchema),
    context: { coupon_id: couponId }
  })

  const { errors } = formState

  const handleUpdateCoupon: SubmitHandler<UpdateCouponFormData> = async (values) => {
    try {
      await updateCoupon.mutateAsync(values);

      toast({
        title: "Cupom alterado",
        description: "Alteração realizada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleDelete = async () => {
    setIsOpenAlert(false)
    setIsSubmitting(true)
    try {
      const { data: canDelete } = await api.get<ValidationData>(`/coupons/valid/delete`, {
        params: {
          id: couponId,
        }
      })

      if (!canDelete.ok) {
        toast({
          title: "Exclusão não é permitida",
          description: canDelete.message,
          status: "error",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })
        setIsSubmitting(false)

        return
      }

      await api.delete(`/coupons/${couponId}`)

      toast({
        title: "Cupom excluído",
        description: "Cupom excluído com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setIsSubmitting(false)

      queryClient.invalidateQueries('coupons')

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <>
      <Flex direction="column" h="100vh">
        { !isWideVersion && <Header /> }

        <ContentBase>
          <Sidebar />

          <MainBase mainTitle="Alterar cupom">
            <Box
              as="form"
              h={["", "80vh"]}
              overflow="auto"
              paddingRight="1"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: "var(--chakra-colors-gray-400)",
                  borderRadius: '25px',
                },
              }}
              onSubmit={handleSubmit(handleUpdateCoupon)}
            >
              {isLoading
                ? (
                  <Flex justify="center">
                    <Spinner color="blue.900" size="xl" />
                  </Flex>
                )
                : (
                    <VStack spacing="8" color="blue.900">
                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <Input
                          nameInput="code"
                          textTransform="uppercase"
                          label="Cupom"
                          labelSize="lg"
                          error={errors.code}
                          defaultValue={couponData.code}
                          isRequired
                          isDisabled
                          _disabled={{
                            color: "gray.200"
                          }}
                          {...register('code')}
                        />
                        <Input
                          nameInput="amount"
                          textTransform="uppercase"
                          label="Desconto (%)"
                          labelSize="lg"
                          error={errors.amount}
                          defaultValue={couponData.amount}
                          isRequired
                          isDisabled
                          _disabled={{
                            color: "gray.200"
                          }}
                          {...register('amount')}
                        />

                        <Input
                          nameInput="limit"
                          type="number"
                          defaultValue={couponData.limit}
                          label="Limite de Uso"
                          labelSize="lg"
                          error={errors.limit}
                          isRequired
                          isDisabled
                          _disabled={{
                            color: "gray.200"
                          }}
                          {...register('limit')}
                        />

                        <Input
                          nameInput="expiration_date"
                          label="Data de Expiração"
                          type="date"
                          labelSize="lg"
                          error={errors.expiration_date}
                          defaultValue={couponData.expirationFormated}
                          isRequired
                          isDisabled
                          _disabled={{
                            color: "gray.200"
                          }}
                          {...register('expiration_date')}
                        />

                        <Input
                          nameInput="used"
                          type="number"
                          defaultValue={couponData.used}
                          label="Usados"
                          labelSize="lg"
                          error={errors.used}
                          isRequired
                          isDisabled
                          _disabled={{
                            color: "gray.200"
                          }}
                          {...register('used')}
                        />
                      </SimpleGrid>
                    </VStack>
                )
              }

              <Flex
                mt="8"
                justify={["normal", "space-between"]}
                flexDir={["column", "row"]}
              >
                <HStack spacing="4">
                  <ButtonDefault
                    text="Excluir Cupom"
                    w={["100%", "150px"]}
                    attencion
                    onClick={() => setIsOpenAlert(true)}
                    disabled={wasUsed}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />
                </HStack>

                <Flex
                  w={["100%", "auto"]}
                  mt={["6", "0"]}
                  flexDir={["column", "row"]}
                >
                  <Box w={["100%", "auto"]}>
                    <ButtonDefault
                      text="Cancelar"
                      w={["100%", "150px"]}
                      info
                      isLoading={formState.isSubmitting || isSubmitting}
                      onClick={() => window.close()}
                    />
                  </Box>

                  <ButtonDefault
                    type="submit"
                    text="Salvar"
                    w={["100%", "150px"]}
                    ml={["0", "4"]}
                    mt={["6", "0"]}
                    disabled
                    isLoading={formState.isSubmitting || isSubmitting}
                  />
                </Flex>
              </Flex>
            </Box>
          </MainBase>
        </ContentBase>
      </Flex>

      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpenAlert(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="bold" textColor="blue.900">
              Excluir cupom
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" textColor="blue.900">
              Tem certeza que deseja excluir este cupom?
            </AlertDialogBody>
            <AlertDialogBody textColor="red">
              Não será possível desfazer esta ação depois.
            </AlertDialogBody>

            <AlertDialogFooter flexDir={["column", "row"]}>
              <ButtonDefault
                text="Cancelar"
                w="100%"
                mb={["2", "0"]}
                info
                onClick={() => setIsOpenAlert(false)}
              />
              <ButtonDefault
                text="Excluir"
                w="100%"
                attencion
                onClick={handleDelete}
                ml={["0", "3"]}
              />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
