import { Image, Link } from '@chakra-ui/react';
import logoWWA from '../../assets/wwa-branco.png';

export function Logo() {
  return (
    <Link href="https://linktr.ee/WunderweltA" isExternal>
      <Image
        h="12"
        src={logoWWA}
        alt="Wunderwelt-A"
      />
    </Link>
  )
}
