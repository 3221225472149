import {
  Box,
  Flex,
  Text,
  Spinner,
  useBreakpointValue
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { Coupon, useCoupons } from "../../../services/hooks/useCoupons";
import MainBase from "../../../components/MainBase";
import ContentBase from "../../../components/ContentBase";
import MainActions from "../../../components/MainActions";
import { Column } from "react-table";
import { MainTable } from "../../../components/MainTable";
import { RemoveAccent } from "../../../utils/RemoveAccent";

export function CouponsList() {
  const [searchField, setSearchField] = useState('');
  const [allCoupons, setAllCoupons] = useState<Coupon[]>([]);

  const { data, isLoading, isFetching, error } = useCoupons()

  const fileName = `Lista-de-cupons-${Date.now()}.csv`;
  const csvHeaders = [
    {
      label: 'Cupom',
      key: 'code'
    },
    {
      label: 'Desconto (%)',
      key: 'amount'
    },
    {
      label: 'Data de Expiração',
      key: 'expirationFormated'
    },
    {
      label: 'Limite de Uso',
      key: 'limit'
    },
    {
      label: 'Usados',
      key: 'used'
    },
  ]

  useEffect(() => {
    if (data) {
      setAllCoupons(data)
    }
  }, [data])

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    if (data) {
      if (searchField) {
        const searchFieldWithoutAccent = RemoveAccent(searchField.toLowerCase())
        const couponsFiltered = data.filter(
          coupon => RemoveAccent(coupon.code.toLowerCase()).match(searchFieldWithoutAccent)
        )

        setAllCoupons(couponsFiltered)

        return
      }

      setAllCoupons(data)
    }
  }, [searchField, data])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const columns: Column<Coupon>[] = [
    {
      Header: "Cupom",
      accessor: "code",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Desconto (%)",
      accessor: "amount",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Data de Expiração",
      accessor: "expiration_date",
      //  @ts-ignore TYPE NEEDS FIXING
      formattedValue: "expirationFormated",
      isSortable: true,
    },
    {
      Header: "Limite de Uso",
      accessor: "limit",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Usados",
      accessor: "used",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
  ];

  const couponsActions = [
    {
      type: 'edit',
      url: '/coupons/update/<id>',
    }
  ]

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Cupons"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <MainActions
            handleChangeSearch={handleChangeSearch}
            handleOnPressEnter={handleOnPressEnter}
            handleSearch={handleSearch}
            searchPlaceHolder="Digite o código que deseja procurar..."
            createLink="/coupons/create"
            csvDownload={{
              csvData: allCoupons,
              csvHeaders: csvHeaders,
              csvFileName: fileName,
            }}
          />
          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : error ? (
              <Flex direction="column">
                <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter os cupons :(</Text>
                <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={allCoupons}
                  actions={couponsActions}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
