import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  VStack,
  SimpleGrid,
  Spinner,
  Switch,
  Textarea,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query';
import { Select as ChakraReactSelect } from "chakra-react-select";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import { TextAreaEditor } from "../../../components/TextAreaEditor";
import { ConvertDraftContentToText } from "../../../utils/ConvertDraftContentToText";

type CreateEventFormData = {
  name: string;
}

type LevelInfo = {
  value: string;
  label: string;
}

type EventData = {
  id: string;
  title: string;
  description: string;
  link: string;
  start_date: string;
  end_date: string;
  student_limit: string;
  instruction: string;
  is_canceled: boolean;
  teacher_id: string;
  name: string;
  credit: string;
  startDateFormated: string;
  startHourFormated: string;
  endHourFormatted: string;
  minimum_number_of_students: string;
  levels: LevelInfo[];
  request_subject?: boolean;
  has_highlight?: boolean;
  for_teachers: boolean;
  description_formatted: string;
  class_subject_id?: string;
  subject?: string;
}

type TeacherData = {
  id: string;
  name: string;
}

type LevelData = {
  id: string;
  name: string;
}

type ClassSubjectsData = {
  id: string;
  subject: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type TeacherSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

type LevelsSelectInputProps = {
  state: {
    value: SelectInputProps[]
  }
}

type ClassSubjectsSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

const createEventFormSchema = Yup.object().shape({
  title: Yup.string().required('Título obrigatório'),
  // description: Yup.string().required('Descrição obrigatória'),
  link: Yup.string()
    .required('Link obrigatório'),
  date: Yup.date()
    .required('Data obrigatória')
    .test(
      'min-date',
      `A data não deve ser inferior a ${format(new Date(), 'dd/MM/yyyy')}`,
      async (value) => {
        if (value) {
          return value && format(value, 'yyyy-MM-dd') >= format(new Date(), 'yyyy-MM-dd')
        }

        return true
      }
    ),
  start_time: Yup.string()
    .required('Início obrigatório')
    .test(
      "start_time",
      'A hora de início não pode ser inferior a hora atual',
      async (value, testContext: any) => {
        const { date } = testContext.parent
        if(date && format(date, 'dd/MM/yyyy') === format(new Date(), 'dd/MM/yyyy')) {
          if (value) {
            return value >= format(new Date(), 'HH:mm')
          }
        }
        return true
      }
    ),
  end_time: Yup.string()
    .required("Término obrigatório")
    .test(
      "end_time",
      'A hora de término não pode ser inferior a hora de início',
      async (value, testContext: any) => {
        const { date, start_time } = testContext.parent
        if(date && format(date, 'dd/MM/yyyy') === format(new Date(), 'dd/MM/yyyy')) {
          if (start_time && value) {
            return value > start_time
          }
        }
        return true
      }
    ),
  credit: Yup.number()
    .min(0, 'Não é permitido quantidade de créditos negativo')
    .integer('A quantidade de créditos deve ser um valor inteiro'),
  request_subject: Yup.boolean(),
  has_highlight: Yup.boolean(),
  for_teachers: Yup.boolean(),
  student_limit: Yup.number()
    .min(0, 'Não é permitido quantidade de alunos negativo')
    .integer('A quantidade de alunos deve ser um valor inteiro'),
  minimum_number_of_students: Yup.number()
    .min(0, 'Não é permitido valor negativo')
    .integer('O mínimo de alunos deve ser um valor inteiro')
    .test(
      "minimumValue",
      'O mínimo de alunos não pode ser superior ao limite de alunos',
      async (value, testContext: any) => {
        const { student_limit } = testContext.parent
        if(value && student_limit > 0) {
          return value <= student_limit
        }
        return true
      }
    ),
  instruction: Yup.string(),
})

export function CreateEvent() {
  const history = useHistory();
  const toast = useToast()
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = new URLSearchParams(location.search)
  const eventId = queryParams.get('id')

  const teacherInputRef = useRef<TeacherSelectInputProps>();
  const levelsInputRef = useRef<LevelsSelectInputProps>();
  const classSubjectsInputRef = useRef<ClassSubjectsSelectInputProps>()

  const [eventData, setEventData] = useState({} as EventData);
  const [teachersData, setTeachersData] = useState<TeacherData[]>([]);
  const [levelsData, setLevelsData] = useState<LevelData[]>([]);
  const [classSubjectsData, setClassSubjectsData] = useState<ClassSubjectsData[]>([])
  const [isLoading, setIsLoading] = useState(true);
  const [isCopy, setIsCopy] = useState(false);
  const [hasOrigin, setHasOrigin] = useState(false);
  const [forTeachers, setForTeachers] = useState(false);

  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const onEditorStateChange = (editorStates: EditorState) => {
    setEditorState(editorStates);
  };

  const dateNow = format(new Date(), 'yyyy-MM-dd');

  const handleChangeForTeachers = useCallback((event) => {
    setForTeachers(event.target.checked);
  }, [])

  useEffect(() => {
    const origin = queryParams.get('origin')

    if (origin) {
      setHasOrigin(true)
    }
  }, [queryParams])

  useEffect(() => {
    if (eventId) {
      setIsCopy(true)
      try {
        api.get<EventData>(`events/find/${eventId}`).then(
          response => {
            const { data } = response;
            const startUTCDate = utcToZonedTime(data.start_date, 'America/Sao_Paulo');
            const endUTCDate = utcToZonedTime(data.end_date, 'America/Sao_Paulo');

            setEventData({
              ...data,
              startDateFormated: format(startUTCDate, 'yyyy-MM-dd'),
              startHourFormated: format(startUTCDate, 'HH:mm'),
              endHourFormatted: format(endUTCDate, 'HH:mm'),
            })

            const contentBlock = htmlToDraft(data.description_formatted || data.description);

            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
              setEditorState(EditorState.createWithContent(contentState));
            }

            setIsLoading(false)
          }
        );
      } catch (err: any) {
        toast({
          title: "Opa...",
          description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
          status: "error",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }, [setIsLoading, setEventData, eventId, toast, setIsCopy])

  useEffect(() => {
    api.get("/users/list/teachers").then(response => setTeachersData(response.data))
  }, [])

  useEffect(() => {
    api.get("/levels").then(response => setLevelsData(response.data))
  }, [])

  useEffect(() => {
    api.get("/class-subjects").then(response => setClassSubjectsData(response.data))
  }, [])

  useEffect(() => {
    setForTeachers(eventData.for_teachers)
  }, [eventData.for_teachers])

  const createEvent = useMutation(async (event: CreateEventFormData) => {
    const response = await api.post('events', event)

    return response.data.event;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('events')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createEventFormSchema)
  })

  const { errors } = formState

  const handleCreateEvent: SubmitHandler<CreateEventFormData> = async (values: any) => {
    try {
      let levelsIds

      if (typeof levelsInputRef.current?.state.value === 'object') {
        levelsIds = levelsInputRef.current?.state.value.map(level => level.value)
      }

      const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      const editorContent = ConvertDraftContentToText(editorState)

      await createEvent.mutateAsync({
        ...values,
        description: editorContent,
        description_formatted: body,
        start_date: utcToZonedTime(
          `${format( values.date, 'yyyy-MM-dd' )} ${values.start_time}:00`,
          'America/Sao_Paulo'
        ).toISOString(),
        end_date: utcToZonedTime(
          `${format( values.date, 'yyyy-MM-dd' )} ${values.end_time}:00`,
          'America/Sao_Paulo'
        ).toISOString(),
        teacher_id: teacherInputRef.current?.state.value.value,
        levels: levelsIds?.toString(),
        class_subject_id: classSubjectsInputRef.current?.state.value.value,
      });

      toast({
        title: "Aula incluída",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/events')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase mainTitle="Incluir aula">
          <Box
            as="form"
            h={["", "85vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handleCreateEvent)}
          >
            {isLoading
            ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            )
            : (
              <VStack spacing="8" color="blue.900">
                <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                  <Input
                    nameInput="title"
                    label="Título"
                    labelSize="lg"
                    error={errors.title}
                    defaultValue={isCopy ? eventData.title : ''}
                    isRequired
                    {...register('title')}
                  />
                </SimpleGrid>

                <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                  <FormControl alignItems="center" isRequired>
                    <FormLabel htmlFor="link">
                      Descrição
                    </FormLabel>
                    <TextAreaEditor
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                    />
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                  <FormControl alignItems="center" isRequired>
                    <FormLabel htmlFor="link">
                      Link da aula
                    </FormLabel>
                    <Textarea
                      placeholder="https://web.zoom.us/&#10;ID da reunião: 999 9999 9999&#10;Senha de acesso: ********"
                      error={errors.link}
                      {...register('link')}
                      defaultValue={isCopy ? eventData.link : ''}
                      css={{
                        '&::-webkit-scrollbar': {
                          width: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: "var(--chakra-colors-gray-200)",
                          borderRadius: '25px',
                        },
                      }}
                    />
                  </FormControl>

                  <SimpleGrid minChildWidth="180px" spacing={["6", "8"]} w="100%">
                    <Input
                      nameInput="date"
                      label="Data"
                      type="date"
                      labelSize="lg"
                      error={errors.date}
                      defaultValue={isCopy ? eventData.startDateFormated : dateNow}
                      isRequired
                      {...register('date')}
                    />
                    <Input
                      nameInput="start_time"
                      type="time"
                      label="Horário do início"
                      labelSize="lg"
                      error={errors.start_time}
                      defaultValue={isCopy ? eventData.startHourFormated : ''}
                      isRequired

                      {...register('start_time')}
                    />
                    <Input
                      nameInput="end_time"
                      type="time"
                      label="Horário do término"
                      labelSize="lg"
                      error={errors.end_time}
                      defaultValue={isCopy ? eventData.endHourFormatted : ''}
                      isRequired
                      {...register('end_time')}
                    />
                  </SimpleGrid>
                </SimpleGrid>

                <SimpleGrid minChildWidth="200px" spacing={["6", "8"]} w="100%">
                  <Input
                    nameInput="credit"
                    type="number"
                    label="Quantidade de créditos"
                    labelSize="lg"
                    error={errors.credit}
                    defaultValue={isCopy ? eventData.credit : 1}
                    isRequired
                    {...register('credit')}
                  />
                  <FormControl
                    alignItems="center"
                  >
                    <FormLabel htmlFor="class-subject" mb="4">
                      Tema da aula
                    </FormLabel>
                    <ChakraReactSelect
                      options={
                        classSubjectsData.map(classSubject => {
                          return (
                            { value: classSubject.id, label: classSubject.subject }
                          )
                        })
                      }
                      placeholder="Selecione ..."
                      defaultValue={isCopy
                        ? {
                          label: eventData.subject,
                          value: eventData.class_subject_id
                        }
                        : ''
                      }
                      closeMenuOnSelect={true}
                      error={errors.classSubject}
                      ref={classSubjectsInputRef}
                    />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="request_subject" mb="0">
                      Solicitar assunto ?
                    </FormLabel>
                    <Switch
                      id="request_subject"
                      colorScheme="green"
                      defaultChecked={isCopy ? eventData.request_subject : false}
                      {...register('request_subject')}
                    />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="has_highlight" mb="0">
                      Destacar aula ?
                    </FormLabel>
                    <Switch
                      id="has_highlight"
                      colorScheme="green"
                      defaultChecked={isCopy ? eventData.has_highlight : false}
                      {...register('has_highlight')}
                    />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="has_highlight" mb="0">
                      Aula para professores ?
                    </FormLabel>
                    <Switch
                      id="for_teachers"
                      colorScheme="green"
                      defaultChecked={isCopy ? eventData.for_teachers : false}
                      {...register('for_teachers')}
                      onChange={handleChangeForTeachers}
                    />
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                  <Input
                    nameInput="student_limit"
                    type="number"
                    label="Limite de alunos"
                    labelSize="lg"
                    error={errors.student_limit}
                    defaultValue={isCopy ? eventData.student_limit : 0}
                    {...register('student_limit')}
                  />
                  <Input
                    nameInput="minimum_number_of_students"
                    type="number"
                    label="Mínimo de alunos"
                    labelSize="lg"
                    error={errors.minimum_number_of_students}
                    defaultValue={isCopy ? eventData.minimum_number_of_students : 0}
                    {...register('minimum_number_of_students')}
                  />
                  <FormControl alignItems="center" isRequired>
                    <FormLabel htmlFor="teacher" mb="4">
                      Professor
                    </FormLabel>
                    <ChakraReactSelect
                      options={
                        teachersData.map(teacher => {
                          return (
                            { value: teacher.id, label: teacher.name }
                          )
                        })
                      }
                      placeholder="Selecione o professor"
                      defaultValue={isCopy
                        ? {
                          label: eventData.name,
                          value: eventData.teacher_id
                        }
                        : ''
                      }
                      closeMenuOnSelect={true}
                      error={errors.teacher}
                      ref={teacherInputRef}
                    />
                  </FormControl>

                  <FormControl
                    alignItems="center"
                    isDisabled={forTeachers}
                  >
                    <FormLabel htmlFor="teacher" mb="4">
                      Nível da aula
                    </FormLabel>
                    <ChakraReactSelect
                      name="levels"
                      isMulti
                      options={
                        levelsData.map(level => {
                          return (
                            { value: level.id, label: level.name }
                          )
                        })
                      }
                      placeholder="Selecione ..."
                      defaultValue={
                        isCopy
                        ? eventData?.levels.map(level => ({
                          label: level.label,
                          value: level.value
                        }))
                        : ''
                      }
                      closeMenuOnSelect={false}
                      ref={levelsInputRef}
                    />
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                  <FormControl alignItems="center">
                    <FormLabel htmlFor="is_teacher">
                      Instruções
                    </FormLabel>
                    <Textarea
                      placeholder="Preencha com os passos que o aluno deve realizar para se preparar para a aula"
                      error={errors.instruction}
                      defaultValue={isCopy ? eventData.instruction : ''}
                      css={{
                        '&::-webkit-scrollbar': {
                          width: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: "var(--chakra-colors-gray-200)",
                          borderRadius: '25px',
                        },
                      }}
                      {...register('instruction')}
                    />
                  </FormControl>
                </SimpleGrid>
              </VStack>
            )}

            <Flex mt="8">
              <Flex
                w="100%"
                spacing={["", "4"]}
                flexDir={["column", "row"]}
                justifyContent={["normal", "flex-end"]}
              >
                <Box w={["100%", "auto"]}>
                  <Link to={hasOrigin ? "/monthly" : "/events"} >
                    <ButtonDefault text="Cancelar" w={["100%", "150px"]} info />
                  </Link>
                </Box>

                <ButtonDefault
                  type="submit"
                  text="Salvar"
                  w={["100%", "150px"]}
                  mt={["3", "0"]}
                  ml={["0", "4"]}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
