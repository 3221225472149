import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  SimpleGrid,
  Spinner,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";
import { Select as ChakraReactSelect } from "chakra-react-select";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import { LevelNameExist } from "../../../utils/validations/LevelNameExist";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type LevelData = {
  name: string;
  color: string;
  variant: string;
}

type UpdateLevelFormData = {
  name: string;
  color?: string;
  variant?: string;
}

type ValidationData = {
  ok: boolean;
  message: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type ColorsSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

type VariantsSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

const updateLevelFormSchema = Yup.object().shape({
  name: Yup.string()
    .required('Nome obrigatório')
    .test(
      'name-exists',
      'Nível já cadastrado, favor informar outro.',
      async (value, testContext: any) => (
        await LevelNameExist(value, testContext.options.context.level_id)
      )
    ),
})

export function UpdateLevel() {
  const toast = useToast();

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [levelData, setLevelData] = useState({} as LevelData);
  const [colorValue, setColorValue] = useState({} as SelectInputProps);
  const [variantValue, setVariantValue] = useState({} as SelectInputProps);

  const cancelRef = useRef(null)

  const colorInputRef = useRef<ColorsSelectInputProps>();
  const variantInputRef = useRef<VariantsSelectInputProps>();

  const colorOptions = useMemo(() => [
    { value: 'blue', label: 'Azul' },
    { value: 'yellow', label: 'Amarelo' },
    { value: 'green', label: 'Verde' },
    { value: 'red', label: 'Vermelho' },
  ], [])

  const variantOptions = useMemo(() => [
    { value: 'outline', label: 'Contorno' },
    { value: 'solid', label: 'Sólido' },
    { value: 'subtle', label: 'Sutil' },
  ], [])

  const { id: levelId } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get<LevelData>(`levels/${levelId}`).then(
        response => {
          const { data } = response;
          setColorValue(colorOptions.find(
            colorOption => colorOption.value === data.color
          ) as SelectInputProps);
          setVariantValue(variantOptions.find(
            variantOption => variantOption.value === data.variant
          ) as SelectInputProps);
          setLevelData(data);
          setIsLoading(false);
        }
      );
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsLoading(false)
    }
  }, [toast, levelId, colorOptions, variantOptions])

  const updateLevel = useMutation(async (level: UpdateLevelFormData) => {
    const response = await api.post(`/levels/update/${levelId}`, level)

    return response.data.level;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('levels')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(updateLevelFormSchema),
    context: { level_id: levelId }
  })

  const { errors } = formState

  const handleUpdateLevel: SubmitHandler<UpdateLevelFormData> = async (values) => {
    try {
      await updateLevel.mutateAsync({
        ...values,
        color: colorInputRef.current?.state.value.value,
        variant: variantInputRef.current?.state.value.value,
      });

      toast({
        title: "Nível alterado",
        description: "Alteração realizada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleDelete = async () => {
    setIsOpenAlert(false)
    setIsSubmitting(true)
    try {
      const { data: canDelete } = await api.get<ValidationData>(`/levels/valid/delete`, {
        params: {
          level_id: levelId,
        }
      })

      if (!canDelete.ok) {
        toast({
          title: "Exclusão não é permitida",
          description: canDelete.message,
          status: "error",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })
        setIsSubmitting(false)

        return
      }

      await api.delete(`/levels/${levelId}`)

      toast({
        title: "Nível excluído",
        description: "Nível excluído com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setIsSubmitting(false)

      queryClient.invalidateQueries('levels')

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <>
      <Flex direction="column" h="100vh">
        { !isWideVersion && <Header /> }

        <ContentBase>
          <Sidebar />

          <MainBase mainTitle="Alterar nível">
            <Box
              as="form"
              h={["", "80vh"]}
              overflow="auto"
              paddingRight="1"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: "var(--chakra-colors-gray-400)",
                  borderRadius: '25px',
                },
              }}
              onSubmit={handleSubmit(handleUpdateLevel)}
            >
              {isLoading
                ? (
                  <Flex justify="center">
                    <Spinner color="blue.900" size="xl" />
                  </Flex>
                )
                : (
                    <VStack spacing="8" color="blue.900">
                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <Input
                          nameInput="name"
                          textTransform="uppercase"
                          label="Nível"
                          labelSize="lg"
                          error={errors.name}
                          defaultValue={levelData.name}
                          isRequired
                          {...register('name')}
                        />

                        <FormControl alignItems="center" isRequired>
                          <FormLabel htmlFor="color" mb="4">
                            Cor
                          </FormLabel>
                          <ChakraReactSelect
                            options={
                              colorOptions.map(color => {
                                return (
                                  { value: color.value, label: color.label }
                                )
                              })
                            }
                            placeholder="Selecione uma cor"
                            closeMenuOnSelect={true}
                            defaultValue={colorValue}
                            error={errors.color}
                            ref={colorInputRef}
                          />
                        </FormControl>


                        <FormControl alignItems="center" isRequired>
                          <FormLabel htmlFor="variant" mb="4">
                            Variação
                          </FormLabel>
                          <ChakraReactSelect
                            options={
                              variantOptions.map(variant => {
                                return (
                                  { value: variant.value, label: variant.label }
                                )
                              })
                            }
                            placeholder="Selecione uma variação"
                            closeMenuOnSelect={true}
                            defaultValue={variantValue}
                            error={errors.color}
                            ref={variantInputRef}
                          />
                        </FormControl>
                      </SimpleGrid>
                    </VStack>
                )
              }

              <Flex
                mt="8"
                justify={["normal", "space-between"]}
                flexDir={["column", "row"]}
              >
                <HStack spacing="4">
                  <ButtonDefault
                    text="Excluir Nível"
                    w={["100%", "150px"]}
                    attencion
                    onClick={() => setIsOpenAlert(true)}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />
                </HStack>

                <Flex
                  w={["100%", "auto"]}
                  mt={["6", "0"]}
                  flexDir={["column", "row"]}
                >
                  <Box w={["100%", "auto"]}>
                    <ButtonDefault
                      text="Cancelar"
                      w={["100%", "150px"]}
                      info
                      isLoading={formState.isSubmitting || isSubmitting}
                      onClick={() => window.close()}
                    />
                  </Box>

                  <ButtonDefault
                    type="submit"
                    text="Salvar"
                    w={["100%", "150px"]}
                    ml={["0", "4"]}
                    mt={["6", "0"]}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />
                </Flex>
              </Flex>
            </Box>
          </MainBase>
        </ContentBase>
      </Flex>

      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpenAlert(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="bold" textColor="blue.900">
              Excluir nível
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" textColor="blue.900">
              Tem certeza que deseja excluir este nível?
            </AlertDialogBody>
            <AlertDialogBody textColor="red">
              Não será possível desfazer esta ação depois.
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonDefault text="Cancelar" w="100px" info onClick={() => setIsOpenAlert(false)} />
              <ButtonDefault text="Excluir" w="100px" attencion onClick={handleDelete} ml={3} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
