import {
  Heading,
  Flex,
  Icon,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdReportGmailerrorred } from 'react-icons/md';

import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ButtonDefault } from '../../components/ButtonDefault';
import ContentBase from '../../components/ContentBase';

export function Failure() {
  useEffect(() => {
    window.history.replaceState(null, '', '/failure');
  }, [])

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <Flex flex="1"
          direction="column"
          marginY={["2", null, null, "4"]}
          px={["6", null, null, "8"]}
          overflow={["auto", null, null, null, "inherit"]}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: "var(--chakra-colors-gray-400)",
              borderRadius: '25px',
            },
          }}
        >
          <Flex
            flexDir="column"
            w="100%"
            h="100%"
            p="8"
            justifyContent="center"
            alignItems="center"
            bg="whitesmoke"
            borderRadius={8}
            boxShadow="lg"
          >
            <Icon
              as={MdReportGmailerrorred}
              color="red"
              fontSize={["100", "150"]}
              mb="5"
            />

            <Heading
              color="blue.900"
              mb="5"
              fontSize={["3xl", "5xl"]}
              textAlign="center"
            >
              Parece que ocorreu uma falha com o pagamento
            </Heading>

            <Text
              color="blue.900"
              fontSize={["2xl", "3xl"]}
              textAlign="center"
              mb="10"
            >
              Tente novamente mais tarde. Enquanto isso, que tal conferir as aulas disponíveis?
            </Text>

            <Link
              to="/classes-list"
              href="/classes-list"
              w={["100%", "350px"]}
            >
              <ButtonDefault
                text="Aulas Disponíveis"
                fontSize={["2xl", "3xl"]}
                w={["100%", "350px"]}
                h="70"
              />
            </Link>
          </Flex>
        </Flex>
      </ContentBase>
    </Flex>
  );
}
