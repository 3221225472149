import {
  Box,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useBreakpointValue
} from "@chakra-ui/react";
import { useEffect } from "react";

import logoPraktika from '../../assets/praktika-branco.png';
import { useSidebarDrawer } from "../../contexts/SidebarDrawerContext";

import { SidebarNav } from "./SidebarNav";

export function Sidebar() {
  const { isOpen, onClose } = useSidebarDrawer()

  const isDrawerSidebar = useBreakpointValue({
    base: true,
    lg: false,
  })

  useEffect(() => {
    const sidebar = document.querySelector('.content-sidebar')

    if (sidebar) {
      sidebar.scrollTop = Number(localStorage.getItem('@praktika:scrollSidebar'));
    }
  }, [])

  if (isDrawerSidebar) {
    return (
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} >
        <DrawerOverlay>
          <DrawerContent bg="blue.900" p="4">
            <DrawerCloseButton mt="6" />
            <DrawerHeader>
              <Image
                h="20"
                src={logoPraktika}
                alt="PrAktiKa"
              />
            </DrawerHeader>

            <DrawerBody>
              <SidebarNav />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    )
  }

  return (
    <Box as="aside" pt="0" w="260px" pl="5" pr="1" bg="#1e2f50">
      <SidebarNav />
    </Box>
  );
}
