import { Image, Link, Stack, Text } from "@chakra-ui/react";
import { CgProfile } from "react-icons/cg";
import { FaChalkboardTeacher } from "react-icons/fa";
import {
  MdChairAlt,
  MdOutlineFactCheck,
  MdOutlineSchool
} from "react-icons/md";
import {
  RiBookMarkLine,
  RiBookReadLine,
  RiContactsBook2Line,
  RiContactsLine,
  RiCoupon3Line,
  RiDashboardLine,
  RiFolderUserLine,
  RiCoinsLine,
  RiListSettingsLine,
  RiShoppingCartLine,
  RiQuestionnaireLine,
  RiTeamLine,
  RiBookmark3Line,
} from "react-icons/ri";
import {
  GiReceiveMoney
} from "react-icons/gi";
import { SiBookstack } from "react-icons/si";
import { GoMegaphone } from "react-icons/go"

import logoPraktika from '../../assets/praktika-branco.png';
import { useAuth } from "../../services/hooks/useAuth";

import { ButtonDefault } from "../ButtonDefault";
import { Can } from "../Can";
import { Logo } from "./Logo";

import { NavLink } from "./NavLink";
import { NavSection } from "./NavSection";

export function SidebarNav() {
    const { signOut } = useAuth();

    return (
      <Stack
        className="content-sidebar"
        h="100%"
        maxH={[null, null, null, "100vh"]}
        py="2"
        spacing="5"
        align="flex-start"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: "var(--chakra-colors-gray-200)",
            borderRadius: '25px',
          },
        }}
      >
        <Link href="https://linktr.ee/WunderweltA" isExternal>
          <Image
            src={logoPraktika}
            alt="PrAktiKa"
            w="220px"
          />
        </Link>
        <NavSection title="GERAL">
          <Can isAdmin>
            <NavLink textAlign="left" icon={RiDashboardLine} href="/dashboard">Dashboard</NavLink>
          </Can>
          <NavLink textAlign="left" icon={RiBookReadLine} href="/classes-list" shouldMatchExactHref>Aulas disponíveis</NavLink>
          <Can not isCompany>
            <NavLink textAlign="left" icon={RiContactsBook2Line} href="/classes-registered" shouldMatchExactHref>Minhas aulas</NavLink>
            <NavLink textAlign="left" icon={RiShoppingCartLine} href="/purchase" shouldMatchExactHref>Adquirir créditos</NavLink>
          </Can>
          <Can isAdmin>
            <NavLink textAlign="left" icon={RiCoinsLine} href="/gift">Gerenciar créditos</NavLink>
          </Can>
        </NavSection>
        <Can isAdmin>
          <NavSection title="CADASTRO">
            <NavLink textAlign="left" icon={RiBookMarkLine} href="/monthly">Aulas do mês</NavLink>
            <NavLink textAlign="left" icon={RiBookMarkLine} href="/events">Aulas</NavLink>
            <NavLink textAlign="left" icon={RiContactsLine} href="/users">Usuários</NavLink>
            <NavLink textAlign="left" icon={RiTeamLine} href="/company">Grupos de empresas</NavLink>
            <NavLink textAlign="left" icon={SiBookstack} href="/products">Produtos</NavLink>
            <NavLink textAlign="left" icon={MdOutlineSchool} href="/levels">Níveis</NavLink>
            <NavLink textAlign="left" icon={RiBookmark3Line} href="/class-subjects">Temas das aulas</NavLink>
            <NavLink textAlign="left" icon={RiCoupon3Line} href="/coupons">Cupons</NavLink>
            <NavLink textAlign="left" icon={GoMegaphone} href="/promotions">Destaques & Promoções</NavLink>
            <NavLink textAlign="left" icon={RiListSettingsLine} href="/parameters">Parâmetros</NavLink>
            <NavLink textAlign="left" icon={RiQuestionnaireLine} href="/fqas">F.Q.A.</NavLink>
          </NavSection>
        </Can>
        <Can isCompany>
          <NavSection title="RELATÓRIO">
            <Can isAdmin>
              <NavLink textAlign="left" icon={FaChalkboardTeacher} href="/teachers">Aulas ministradas</NavLink>
              <NavLink textAlign="left" icon={RiFolderUserLine} href="/schedules">Usuários matriculados</NavLink>
              <NavLink textAlign="left" icon={MdChairAlt} href="/queues">Fila de espera</NavLink>
            </Can>
            <Can isCompany>
              <NavLink textAlign="left" icon={RiTeamLine} href="/squad-historic">Histórico de empresas</NavLink>
            </Can>
          </NavSection>
        </Can>
        <NavSection title="PERFIL">
          <NavLink textAlign="left" icon={CgProfile} href="/profile">Meu perfil</NavLink>
          <Can not isCompany>
            <NavLink textAlign="left" icon={MdOutlineFactCheck} href="/extract">Histórico</NavLink>
            <NavLink textAlign="left" icon={GiReceiveMoney} href="/my-credits">Meus Créditos</NavLink>
          </Can>
          <NavLink textAlign="left" icon={RiQuestionnaireLine} href="/support">Dúvidas</NavLink>
        </NavSection>

        <Stack
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          px="1"
        >
          <Text mt="2">
            <strong>PrAkTikA</strong> é um serviço oferecido pela
          </Text>
          <Logo />
        </Stack>

        <ButtonDefault
          w="95%"
          minH="40px"
          onClick={signOut}
          textAlign="center"
          text="Sair"
        />
      </Stack>
    )
}
