import {
  Heading,
  Flex,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';

import { ButtonDefault } from '../ButtonDefault';

import { PurchaseModal } from "./Modal";
import { useEffect, useState } from 'react';

interface PurchaseCardProps {
  product_id: string;
  title: string;
  description: string;
  amount: number;
  value: number;
  icon: IconType;
  discount?: number;
  originalValue?: number;
  originalAmount?: number;
}

export function PurchaseCard({
  product_id,
  title,
  description,
  amount,
  value,
  icon,
  discount,
  originalValue,
  originalAmount,
}: PurchaseCardProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [promotionValue, setPromotionValue] = useState(0)
  const [paymentValue, setPaymentValue] = useState(value)
  const [promotionAmount, setPromotionAmount] = useState(0)
  const [paymentAmount] = useState(amount)

  useEffect(() => {
    if (originalValue && originalValue > 0) {
      setPromotionValue(originalValue)
    } else if (discount) {
      setPromotionValue(value)
      setPaymentValue(value - (value * ( discount / 100 )))
    }
  }, [originalValue, discount, value])

  useEffect(() => {
    if (originalAmount && originalAmount > 0) {
      setPromotionAmount(originalAmount)
    }
  }, [originalAmount, amount])

  return (
    <Flex
      w="100%"
      h="100%"
      justify="space-between"
      p="8"
      alignItems="center"
      borderRadius={8}
      boxShadow="lg"
      flexDir="column"
      bg="white"
    >
      <Icon as={icon} color="blue.900" fontSize="50" mb="5" />

      <Heading h="87" color="blue.900" textAlign="center">{title}</Heading>

      <Flex
        w="150px"
        h="150px"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        marginY="4"
        bg="green.500"
        borderRadius="50%"
      >
        <Heading
          as={promotionValue > 0 ? "del" : "text"}
          size={promotionValue > 0 ? "sm" : "xl" }
          color="white"
          mb={promotionValue > 0 ? "1" : "0"}
        >
          R$ {promotionValue || paymentValue}
        </Heading>

        {promotionValue > 0 &&
          <Heading size="xl" color="orange">R$ {paymentValue}</Heading>
        }
      </Flex>

      <Text h="145px" color="blue.900" textAlign="center">
        {description}
      </Text>

      <Text
        as={promotionAmount > 0 ? "del" : "kbd" }
        fontSize={promotionAmount > 0 ? "sm" : "xl" }
        mt="4"
        color="blue.900"
      >
        {promotionAmount || paymentAmount} CRÉDITOS
      </Text>

      {promotionValue > 0 &&
        <Text
          as="kbd"
          fontSize="xl"
          color="orange"
        >
          {paymentAmount} CRÉDITOS
        </Text>
      }

      <ButtonDefault
        w="100%"
        mt="6"
        size="lg"
        text="Comprar"
        onClick={onOpen}
      />

      <PurchaseModal
        isOpen={isOpen}
        onClose={onClose}
        product_id={product_id}
        product_value={paymentValue.toString()}
      />
    </Flex>
  )
}
