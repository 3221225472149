import { EditorState, convertToRaw } from 'draft-js';

function ConvertDraftContentToText(draftEditorState: EditorState): string {
  const { blocks } = convertToRaw(draftEditorState.getCurrentContent());

  const mappedBlocks = blocks.map(
    block => (!block.text.trim() && "\n") || block.text,
  );

  const text = mappedBlocks.reduce((acc, block) => {
    let returned = acc;
    if (block === "\n") returned += block;
    else returned += `${block}\n`;
    return returned;
  }, "")

  return text
}

export { ConvertDraftContentToText }
