import { Icon, HStack, Text } from "@chakra-ui/react";
import { RiCoinsLine } from "react-icons/ri";
import { ButtonDefault } from "../ButtonDefault";

interface FooterProps {
  canceled?: boolean;
  waiting?: boolean;
  disabled?: boolean;
  onOpen: () => void;
  credit: string;
}

export function Footer({
  canceled = false,
  waiting = false,
  disabled = false,
  onOpen,
  credit,
} : FooterProps) {
  return (
    <HStack
      w="100%"
      ml="auto"
      alignItems="center"
      justifyContent="space-between"
      borderBottomRadius="5"
    >
      <Text color="blue.900">
        <Icon as={RiCoinsLine} mr="1" fill="gold" fontSize="30" />
        {credit}
      </Text>
      <ButtonDefault
        onClick={onOpen}
        warning={waiting}
        attencion={canceled}
        disabled={disabled}
        w={["80%", "120px"]}
        text={canceled ? 'Cancelar' : waiting ? 'Lista de Espera' : 'Inscrever-se'}
      />
    </HStack>
  )
}
