import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

import { Avatar, Box, Flex, Spinner, Stack, StackDivider, Text } from "@chakra-ui/react";

import { api } from "../../../services/api";
import CustonDrawer from "..";
import { DashboardDrawerContent } from "./DashboardDrawerContent";

interface DashboardDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  eventId: string;
  title: string;
  description: string;
  teacher: {
    name: string;
    email: string;
  }
}

type IUser = {
  area_code: string
  created_at: string
  credit: string
  document: string
  document_type: string
  email: string
  id: string
  inactivation_date?: string
  is_admin: boolean
  is_teacher: boolean
  name: string
  phone: string
  street_name: string
  street_number: string
  username: string
  zip_code: string
}

type ISchedule = {
  id: string;
  event_id: string;
  subject: string;
  user: IUser
}

export function DashboardDrawer({
  isOpen,
  onClose,
  eventId,
  title,
  description,
  teacher,
} : DashboardDrawerProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [schedulesData, setSchedulesData] = useState([] as ISchedule[]);
  const formattedDescription = ReactHtmlParser(description)

  useEffect(() => {
    if (isOpen) {
      api.get<ISchedule[]>(`schedules/students/${eventId}`).then(
        response => {
          const { data } = response;

          setSchedulesData(
            data,
          )

          setIsLoading(false)
        }
      );
    }
  }, [isOpen, title, eventId])

  return (
    <CustonDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={title}
    >
      { isLoading ? (
          <Flex justify="center">
            <Spinner color="blue.900" size="xl" />
          </Flex>
        ) : (
          <Stack
            spacing='24px'
            divider={<StackDivider borderColor='gray.200' />}
            mt='6px'
          >
            <Flex
              direction="column"
            >
              <Text
                fontSize="md"
              >
                {formattedDescription}
              </Text>

              <Flex
                mt="3"
                align="center"
                justifyContent="flex-end"
              >
                <Avatar size="sm" name={teacher.name} />

                <Box ml="2">
                  <Text
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    {teacher.name}
                  </Text>
                  <Text
                    color="blue.800"
                    fontSize="smaller"
                    mt="-6px"
                  >
                    {teacher.email}
                  </Text>
                </Box>
              </Flex>
            </Flex>
            {schedulesData.map(schedule => (
              <DashboardDrawerContent
                key={schedule.id}
                user={{id: schedule.user.id, name: schedule.user.name, mail: schedule.user.email}}
                subject={schedule.subject}
                eventId={eventId}
              />
            ))}
            {schedulesData.length <= 0 && (
              <Text
                fontWeight="bold"
                color="gray.500"
              >
                Não há aluno matriculado
              </Text>
            )}
          </Stack>
        )
      }
    </CustonDrawer>
  )
}
