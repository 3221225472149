import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  VStack,
  SimpleGrid,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";
import { Select as ChakraReactSelect } from "chakra-react-select";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import { LevelNameExist } from "../../../utils/validations/LevelNameExist";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type CreateLevelFormData = {
  name: string;
  color?: string;
  variant?: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type ColorsSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

type VariantsSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

const createLevelFormSchema = yup.object().shape({
  name: yup.string()
    .required('Nome obrigatório')
    .test(
      'name-exists',
      'Nível já cadastrado, favor informar outro.',
      async (value) => (
        await LevelNameExist(value)
      )
    ),
})

export function CreateLevel() {
  const history = useHistory();
  const toast = useToast()

  const colorInputRef = useRef<ColorsSelectInputProps>();
  const variantInputRef = useRef<VariantsSelectInputProps>();

  const colorOptions = [
    { value: 'blue', label: 'Azul' },
    { value: 'yellow', label: 'Amarelo' },
    { value: 'green', label: 'Verde' },
    { value: 'red', label: 'Vermelho' },
  ]

  const variantOptions = [
    { value: 'outline', label: 'Contorno' },
    { value: 'solid', label: 'Sólido' },
    { value: 'subtle', label: 'Sutil' },
  ]

  const createLevel = useMutation(async (level: CreateLevelFormData) => {
    const response = await api.post('levels', level)

    return response.data.level;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('levels')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createLevelFormSchema)
  })

  const { errors } = formState

  const handleCreateLevel: SubmitHandler<CreateLevelFormData> = async (values) => {
    try {
      await createLevel.mutateAsync({
        ...values,
        color: colorInputRef.current?.state.value.value,
        variant: variantInputRef.current?.state.value.value,
      });

      toast({
        title: "Nível incluído",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/levels')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase mainTitle="Incluir nível">
          <Box
            as="form"
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handleCreateLevel)}
          >
            <VStack spacing="8" color="blue.900">
              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="name"
                  label="Nome completo"
                  labelSize="lg"
                  error={errors.name}
                  isRequired
                  textTransform="uppercase"
                  autoFocus
                  {...register('name')}
                />

                <FormControl alignItems="center" isRequired>
                  <FormLabel htmlFor="color" mb="4">
                    Cor
                  </FormLabel>
                  <ChakraReactSelect
                    options={
                      colorOptions.map(color => {
                        return (
                          { value: color.value, label: color.label }
                        )
                      })
                    }
                    placeholder="Selecione uma cor"
                    closeMenuOnSelect={true}
                    error={errors.color}
                    ref={colorInputRef}
                  />
                </FormControl>

                <FormControl alignItems="center" isRequired>
                  <FormLabel htmlFor="variant" mb="4">
                    Variação
                  </FormLabel>
                  <ChakraReactSelect
                    options={
                      variantOptions.map(variant => {
                        return (
                          { value: variant.value, label: variant.label }
                        )
                      })
                    }
                    placeholder="Selecione uma variação"
                    closeMenuOnSelect={true}
                    error={errors.color}
                    ref={variantInputRef}
                  />
                </FormControl>
              </SimpleGrid>
            </VStack>

            <Flex mt="8">
              <Flex
                w="100%"
                spacing={["", "4"]}
                flexDir={["column", "row"]}
                justifyContent={["normal", "flex-end"]}
              >
                <Box w={["100%", "auto"]}>
                  <Link to="/levels" >
                    <ButtonDefault text="Cancelar" w={["100%", "150px"]} info />
                  </Link>
                </Box>

                <ButtonDefault
                  type="submit"
                  text="Salvar"
                  w={["100%", "150px"]}
                  mt={["3", "0"]}
                  ml={["0", "4"]}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
