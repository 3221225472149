import {
  Box,
  HStack,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { RiTimerLine, RiCalendarTodoLine } from 'react-icons/ri';

import { Tag } from "./Tag";

interface HeaderProps {
  title: string;
  date: string;
  hour: string;
  duration: string;
  levels?: {
    id: string,
    name: string
  }[]
}

export function Header({ title, date, hour, duration, levels }: HeaderProps) {
  return (
    <Stack
      alignItems="flex-start"
      spacing="auto"
      color="blue.900"
      borderTopRadius="5"
    >
      <Box>
        <Text fontSize="xx-large" textAlign={["center", "left"]}>
          <strong>{title}</strong>
        </Text>
        <Stack direction="row">
          {levels?.map(level => (
            <Tag id={level.id} tagName={level.name} />
          ))}
        </Stack>
      </Box>
      <HStack
        spacing="2"
        fontSize="md"
        color="blue.800"
        fontWeight="normal"
      >
        <Box display="flex" flexDir="row" alignItems="center">
          <Tooltip hasArrow label='Data da aula'>
            <Box>
              <RiCalendarTodoLine size="20"/>
            </Box>
          </Tooltip>
          <Text marginLeft="5px">
            {date} - {hour} (<strong>horário de Brasília</strong>)
          </Text>
        </Box>

        <Box display="flex" flexDir="row" alignItems="center">
          <Tooltip hasArrow label='Duração'>
            <Box>
              <RiTimerLine size="20"/>
            </Box>
          </Tooltip>

          <Text marginLeft="3px">
            {duration}min
          </Text>
        </Box>
      </HStack>
    </Stack>
  )
}
