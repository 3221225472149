import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'

import { AuthProvider } from './contexts/AuthContext';
import { SidebarDrawerProvider } from './contexts/SidebarDrawerContext';
import { Routes } from './routes';
import { queryClient } from './services/queryClient';

export function App() {
  return(
    <>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AuthProvider>
            <SidebarDrawerProvider>
              <Routes />
            </SidebarDrawerProvider>
          </AuthProvider>
        </Router>

        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  )
}