import { useQuery } from "react-query";

import { api } from "../api";

export type ClassSubject = {
  id: string;
  subject: string;
  quantity: number;
}

export async function getClassSubjects(): Promise<ClassSubject[]> {
  const response = await api.get<any>('/class-subjects')

  const { data } = response

  return data;
}

export function useClassSubjects() {
  return useQuery(['class-subjects'], () => getClassSubjects(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
