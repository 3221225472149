import {
  Box,
  Flex,
  HStack,
  VStack,
  SimpleGrid,
  Spinner,
  useToast,
  useBreakpointValue,
  FormLabel,
  Switch,
  FormControl,
} from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type ProductData = {
  name: string;
  description: string;
  value: number;
  amount: number;
  original_value: number;
  original_amount: number;
  is_active: boolean;
}

const updateProductFormSchema = Yup.object().shape({
  value: Yup.string()
    .required('Valor é obrigatório'),
})

export function UpdateProduct() {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState({} as ProductData);

  const { id: productId } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get<ProductData>(`products/find/${productId}`).then(
        response => {
          const { data } = response;

          setProductData(data)
          setIsLoading(false)
        }
      );
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsLoading(false)
    }
  }, [toast, productId])

  const updateProduct = useMutation(async (product: ProductData) => {
    product.original_amount = Number(product.original_amount)
    product.original_value = Number(product.original_value)
    product.value = Number(product.value)
    product.amount = Number(product.amount)

    const response = await api.post(`/products/update/${productId}`, product)

    return response.data.product;
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(updateProductFormSchema),
    context: { product_id: productId }
  })

  const { errors } = formState

  const handleUpdateProduct: SubmitHandler<ProductData> = async (values) => {
    try {
      await updateProduct.mutateAsync(values);

      toast({
        title: "Parâmetro alterado",
        description: "Alteração realizada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }
      <ContentBase>
        <Sidebar />
        <MainBase mainTitle="Alterar produto">
          <Box
            as="form"
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handleUpdateProduct)}
          >
            {isLoading
              ? (
                <Flex justify="center">
                  <Spinner color="blue.900" size="xl" />
                </Flex>
              )
              : (
                  <VStack spacing="8" color="blue.900">
                    <SimpleGrid
                      templateColumns={{ base: "1fr", md: "15% 25% 1fr" }}
                      minChildWidth="248px"
                      spacing={["6", "8"]}
                      w="100%"
                    >
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="is_active" mb="0">
                          Ativo ?
                        </FormLabel>
                        <Switch
                          id="is_active"
                          colorScheme="green"
                          defaultChecked={productData.is_active}
                          {...register('is_active')}
                        />
                      </FormControl>
                      <Input
                        nameInput="name"
                        label="Nome do produto"
                        labelSize="lg"
                        error={errors.name}
                        defaultValue={productData.name}
                        {...register('name')}
                      />
                      <Input
                        nameInput="description"
                        label="Descrição"
                        labelSize="lg"
                        error={errors.description}
                        defaultValue={productData.description}
                        isRequired
                        {...register('description')}
                      />
                    </SimpleGrid>

                    <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                      <Input
                        nameInput="value"
                        textTransform="uppercase"
                        label="Valor do produto"
                        labelSize="lg"
                        error={errors.value}
                        defaultValue={productData.value}
                        isRequired
                        {...register('value')}
                      />
                      <Input
                        nameInput="amount"
                        textTransform="uppercase"
                        label="Quantidade de créditos"
                        labelSize="lg"
                        error={errors.amount}
                        defaultValue={productData.amount}
                        isRequired
                        {...register('amount')}
                      />
                      <Input
                        nameInput="original_value"
                        label="Valor original para promoção"
                        labelSize="lg"
                        error={errors.original_value}
                        defaultValue={productData.original_value}
                        {...register('original_value')}
                      />
                      <Input
                        nameInput="original_amount"
                        label="Quantidade original para promoção"
                        labelSize="lg"
                        error={errors.original_amount}
                        defaultValue={productData.original_amount}
                        {...register('original_amount')}
                      />
                    </SimpleGrid>
                  </VStack>
              )
            }
            <Flex mt="8" justify="flex-end">
              <HStack spacing="4">
                <ButtonDefault
                  text="Cancelar"
                  w="150px"
                  info
                  isLoading={formState.isSubmitting}
                  onClick={() => window.close()}
                />
                <ButtonDefault
                  type="submit"
                  text="Salvar"
                  w="150px"
                  isLoading={formState.isSubmitting}
                />
              </HStack>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
