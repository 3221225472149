import { Button, ButtonProps, Text } from "@chakra-ui/react";

interface ButtonDefaultProps extends ButtonProps {
  text?: string;
  attencion?: boolean;
  warning?: boolean;
  unfocused?: boolean;
  info?: boolean;
  selected?: boolean;
}

export function ButtonDefault({
  text,
  attencion = false,
  warning = false,
  info = false,
  unfocused = false,
  selected = false,
  ...rest
}: ButtonDefaultProps) {
  if (rest.variant === 'outline') {
    return (
      <Button
        type="button"
        p="0"
        textColor={
          selected
          ? "#F9FAFD"
          : "green.500"
        }
        borderColor={
          selected
          ? "#F9FAFD"
          : "green.500"
        }
        background={
          selected
          ? "green.500"
          : "#F9FAFD"
        }
        _hover={{
          backgroundColor: selected 
            ? "#F9FAFD"
            : "green.500",
          textColor: selected
            ? "green.500"
            : "#F9FAFD",
          borderColor: selected
            ? "green.500"
            : "#F9FAFD",
        }}
        variant='outline'
        {...rest}
      >
        <Text>{text}</Text>
      </Button>
    )
  }
  return (
    <Button
      type="button"
      p="0"
      textColor={
        unfocused
        ? "gray.200"
        : "#FFF"
      }
      bg={
        attencion
        ? "red.500"
        : warning
        ? "#fdbb33"
        : info
        ? "blue.300"
        : unfocused
        ? "transparent"
        : "green.500"
      }
      borderColor={"green.500"}
      _hover={{
        backgroundColor: attencion
          ? "red.400"
          : warning
          ? "#e9ab2f"
          : info
          ? "blue.400"
          : unfocused
          ? "transparent"
          : "green.400"
      }}
      variant='outline'
      {...rest}
    >
      <Text>{text}</Text>
    </Button>
  );
}
