import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  VStack,
  SimpleGrid,
  Switch,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import { UserUsernameExist } from "../../../utils/validations/UserUsernameExist";
import { UserEmailExist } from "../../../utils/validations/UserEmailExist";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type CreateUserFormData = {
  name: string;
  email: string;
  username: string;
  is_admin: boolean;
  is_teacher: boolean;
  street_name: string;
  street_number: string;
  zip_code: string;
  area_code: string;
  phone: string;
  document: string;
  is_company: boolean;
  receive_email: boolean;
  receive_newsletter: boolean;
  birth_date: string;
}

const createUserFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string()
    .email('Digite um e-mail válido')
    .required('E-mail obrigatório')
    .test(
      'email-exists',
      'E-mail já cadastrado, favor informar outro.',
      async (value) => (
        await UserEmailExist(value)
      )
    ),
  username: yup.string()
    .required('Usuário obrigatório')
    .test(
      'username-exists',
      'Usuário indisponível, favor informar outro.',
      async (value) => (
        await UserUsernameExist(value)
      )
    ),
  is_admin: yup.boolean(),
  is_teacher: yup.boolean(),
  street_name: yup.string(),
  street_number: yup.string().when(['street_name'], {
    is: (street_name: string) => street_name.length > 0,
    then: yup.string()
      .required('O número deve ser informado')
      .matches(/^\d+$/, 'Digitar somente números.')
  }),
  zip_code: yup.string().when(['street_name'], {
    is: (street_name: string) => street_name.length > 0,
    then: yup.string()
      .required('CEP obrigatório')
      .length(8, 'O CEP deve conter 8 dígitos')
  }),
  area_code: yup.string()
    .when(['phone'], {
      is: (phone: string) => phone.length > 0,
      then: yup.string().length(2, 'DDD deve conter 2 dígitos'),
    })
    .test(
      'areaLenght',
      'DDD deve conter 2 dígitos',
      async(value) => {
        if (value) {
          if (value.length !== 2 ) {
            return false
          }
        }

        return true
      }
    ),
  phone: yup.string()
    .test(
      'phoneLenght',
      'O telefone deve conter 8 ou 9 dígitos',
      async(value, testContext) => {
        if (value) {
          if (value.length < 8 || value.length > 9) {
            return false
          }
        } else if (testContext.parent.area_code.length > 0) {
          return false
        }

        return true
      }
    )
    .test(
      'onlyNumber',
      'O telefone deve conter somente números',
      async(value, testContext) => {
        if (value) {
          if (!/^\d+$/.test(value)) {
            return false
          }
        }

        return true
      }
    ),
  document: yup.string()
    .test(
      'onlyNumber',
      'O documento deve conter somente números',
      async(value) => {
        if (value) {
          if (!/^\d+$/.test(value)) {
            return false
          }
        }

        return true
      }
    ),
  is_company: yup.boolean(),
  birth_date: yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value; // Converte string vazia em null
    })
    .max(new Date(), 'A data de nascimento não pode ser maior que a data atual')
    .notRequired(),
})

export function CreateUser() {
  const history = useHistory();
  const toast = useToast()

  const createUser = useMutation(async (user: CreateUserFormData) => {
    const response = await api.post('users', user)

    return response.data.user;
  }, {
    onSuccess: () => {
      queryClient.refetchQueries('users')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createUserFormSchema)
  })

  const { errors } = formState

  const handleCreateUser: SubmitHandler<CreateUserFormData> = async (values) => {
    try {
      await createUser.mutateAsync(values);

      toast({
        title: "Usuário incluído",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/users')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase mainTitle="Incluir usuário">
          <Box
            as="form"
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            onSubmit={handleSubmit(handleCreateUser)}
          >
            <VStack spacing="8" color="blue.900">
              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="name"
                  label="Nome completo"
                  labelSize="lg"
                  error={errors.name}
                  isRequired
                  {...register('name')}
                />
                <Input
                  nameInput="email"
                  label="E-mail"
                  labelSize="lg"
                  error={errors.email}
                  textTransform="lowercase"
                  isRequired
                  {...register('email')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  w="100%"
                  nameInput="username"
                  label="Usuário"
                  labelSize="lg"
                  error={errors.username}
                  textTransform="lowercase"
                  isRequired
                  {...register('username')}
                />
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="is_admin" mb="0">
                    Administrador ?
                  </FormLabel>
                  <Switch
                    id="is_admin"
                    colorScheme="green"
                    {...register('is_admin')}
                  />
                </FormControl>

                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="is_teacher" mb="0">
                    Professor ?
                  </FormLabel>
                  <Switch
                    id="is_teacher"
                    colorScheme="green"
                    {...register('is_teacher')}
                  />
                </FormControl>

                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="is_company" mb="0">
                    Empresa ?
                  </FormLabel>
                  <Switch
                    id="is_company"
                    colorScheme="green"
                    {...register('is_company')}
                  />
                </FormControl>
              </SimpleGrid>

              <div />

              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="street_name"
                  label="Endereço"
                  labelSize="lg"
                  error={errors.street_name}
                  {...register('street_name')}
                />

                <Input
                  nameInput="street_number"
                  label="Número"
                  labelSize="lg"
                  error={errors.street_number}
                  {...register('street_number')}
                />

                <Input
                  nameInput="zip_code"
                  label="CEP"
                  labelSize="lg"
                  error={errors.zip_code}
                  {...register('zip_code')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="area_code"
                  label="DDD"
                  labelSize="lg"
                  error={errors.area_code}
                  {...register('area_code')}
                />

                <Input
                  nameInput="phone"
                  label="Telefone"
                  labelSize="lg"
                  error={errors.phone}
                  {...register('phone')}
                />

                {/* <Input
                  nameInput="document_type"
                  label="Tipo Documento"
                  labelSize="lg"
                  error={errors.document_type}
                  {...register('document_type')}
                /> */}

                <Input
                  nameInput="document"
                  label={"Documento (CPF, CNPJ, ...)"}
                  labelSize="lg"
                  error={errors.document}
                  {...register('document')}
                />

                <Input
                  nameInput="birth_date"
                  type="date"
                  label="Data de nascimento"
                  labelSize="lg"
                  error={errors.birth_date}
                  {...register('birth_date')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="receive_email" mb="0">
                    Receber E-mail ?
                  </FormLabel>
                  <Switch
                    id="receive_email"
                    colorScheme="green"
                    isChecked
                    {...register('receive_email')}
                  />
                </FormControl>

                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="receive_newsletter" mb="0">
                    Receber Newsletter ?
                  </FormLabel>
                  <Switch
                    id="receive_newsletter"
                    colorScheme="green"
                    isChecked
                    {...register('receive_newsletter')}
                  />
                </FormControl>
              </SimpleGrid>
            </VStack>

            <Flex mt="8">
              <Flex
                w="100%"
                spacing={["", "4"]}
                flexDir={["column", "row"]}
                justifyContent={["normal", "flex-end"]}
              >
                <Box w={["100%", "auto"]}>
                  <Link to="/users" >
                    <ButtonDefault text="Cancelar" w={["100%", "150px"]} info />
                  </Link>
                </Box>

                <ButtonDefault
                  type="submit"
                  text="Salvar"
                  w={["100%", "150px"]}
                  mt={["3", "0"]}
                  ml={["0", "4"]}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
