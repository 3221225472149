import {
  Text,
  Flex,
  Button,
  Image,
  Stack,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useHistory } from 'react-router-dom';

import logoWWA from '../../assets/wwa-branco.png';
import logoPraktika from '../../assets/praktika-branco.png';
import { Input } from '../../components/Form/Input';
import { api } from '../../services/api';

type ResetPasswordData = {
  password: string;
  password_confirmation: string;
}

const signUpFormSchema = yup.object().shape({
  password: yup.string().required('Senha obrigatória').min(6, 'No mínimo 6 caracteres'),
  password_confirmation: yup.string().oneOf([
      null, yup.ref('password')
  ], 'As senhas devem ser iguais'),
})

export function ResetPassword() {
  const { register, handleSubmit, formState } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(signUpFormSchema)
  })

  const { errors } = formState;

  const history = useHistory()
  const toast = useToast()
  const location = useLocation();

  const handleReset: SubmitHandler<ResetPasswordData> = async (values) => {
    try {
      const queryParams = new URLSearchParams(location.search)
      const token = queryParams.get('token')

      await api.post(`/password/reset?token=${token}`, values);

      toast({
        title: "Senha redefinida",
        description: "Senha redefinida com sucesso!",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  return (
    <Flex
      w="100vw"
      h="100vh"
      maxW="100vw"
      maxH="100vh"
      justify="center"
      alignItems="center"
      css={{
        'background-image': 'linear-gradient(135deg, #1e2f50 0%, #3e72ad 99%)'
      }}
    >
      <Flex
          flexDir="column"
          w="100%"
          alignItems="center"
          justifyContent="center"
          p="8"
      >
        <Image
          src={logoPraktika}
          alt="PrAktiKa"
          w="400px"
        />

        <Flex
          as="form"
          w="100%"
          maxWidth={400}
          p="8"
          alignItems="center"
          bg="#ffffff10"
          backdropBlur="10px"
          zIndex="1"
          borderRadius={8}
          flexDir="column"
          onSubmit={handleSubmit(handleReset)}
        >
          <Text fontSize="4xl" mb="10">Redefinir Senha</Text>

          <Stack w="100%" spacing="4">
            <Input
              nameInput="password"
              type="password"
              label="Senha"
              error={errors.password}
              {...register('password')}
            />
            <Input
              nameInput="password_confirmation"
              type="password"
              label="Confirmar senha"
              error={errors.password_confirmation}
              {...register('password_confirmation')}
            />
          </Stack>

          <Button
            type="submit"
            w="100%"
            minH="12"
            mt="6"
            bg="#50b683"
            size="lg"
            isLoading={formState.isSubmitting}
            _hover={{
              backgroundColor: "#46a174"
            }}
          >
            Enviar
          </Button>
        </Flex>

        <VStack
          mt="4"
        >
          <Text>um serviço oferecido por</Text>
          <Image
            src={logoWWA}
            alt="Wunderwelt-A"
            w="200px"
          />
        </VStack>
      </Flex>
    </Flex>
  );
}
