import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Flex,
  HStack,
  VStack,
  SimpleGrid,
  Spinner,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import { ClassSubjectExist } from "../../../utils/validations/ClassSubjectExist";

type ClassSubjectData = {
  subject: string;
  quantity: string;
}

type UpdateClassSubjectFormData = {
  subject: string;
  quantity: string;
}

type ValidationData = {
  ok: boolean;
  message: string;
}

const updateClassSubjectFormSchema = Yup.object().shape({
  subject: Yup.string()
    .required('Tema obrigatório')
    .test(
      'subject-exists',
      'Tema já cadastrado, favor informar outro.',
      async (value, testContext: any) => (
        await ClassSubjectExist(value, testContext.options.context.class_subject_id)
      )
    ),
})

export function UpdateClassSubject() {
  const toast = useToast();

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [classSubjectData, setClassSubjectData] = useState({} as ClassSubjectData);

  const cancelRef = useRef(null)

  const { id: classSubjectId } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get<ClassSubjectData>(`class-subjects/find/${classSubjectId}`).then(
        response => {
          const { data } = response;
          setClassSubjectData(data);
          setIsLoading(false);
        }
      );
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsLoading(false)
    }
  }, [toast, classSubjectId])

  const updateClassSubject = useMutation(async (classSubject: UpdateClassSubjectFormData) => {
    const response = await api.post(`/class-subjects/update/${classSubjectId}`, classSubject)

    return response.data.classSubject;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('class-subjects')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(updateClassSubjectFormSchema),
    context: { class_subject_id: classSubjectId }
  })

  const { errors } = formState

  const handleUpdateClassSubject: SubmitHandler<UpdateClassSubjectFormData> = async (values) => {
    try {
      await updateClassSubject.mutateAsync({
        ...values,
      });

      toast({
        title: "Tema alterado",
        description: "Alteração realizada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      queryClient.invalidateQueries('class-subjects')
      queryClient.resetQueries('class-subjects')

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleDelete = async () => {
    setIsOpenAlert(false)
    setIsSubmitting(true)
    try {
      const { data: canDelete } = await api.get<ValidationData>(`/class-subjects/valid/delete`, {
        params: {
          class_subject_id: classSubjectId,
        }
      })

      if (!canDelete.ok) {
        toast({
          title: "Exclusão não é permitida",
          description: canDelete.message,
          status: "error",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })
        setIsSubmitting(false)

        return
      }

      await api.delete(`/class-subjects/${classSubjectId}`)

      toast({
        title: "Tema excluído",
        description: "Tema excluído com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setIsSubmitting(false)

      queryClient.invalidateQueries('class-subjects')

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <>
      <Flex direction="column" h="100vh">
        { !isWideVersion && <Header /> }

        <ContentBase>
          <Sidebar />

          <MainBase mainTitle="Alterar tema">
            <Box
              as="form"
              h={["", "80vh"]}
              overflow="auto"
              paddingRight="1"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: "var(--chakra-colors-gray-400)",
                  borderRadius: '25px',
                },
              }}
              onSubmit={handleSubmit(handleUpdateClassSubject)}
            >
              {isLoading
                ? (
                  <Flex justify="center">
                    <Spinner color="blue.900" size="xl" />
                  </Flex>
                )
                : (
                    <VStack spacing="8" color="blue.900">
                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <Input
                          nameInput="subject"
                          label="Tema"
                          labelSize="lg"
                          error={errors.subject}
                          defaultValue={classSubjectData.subject}
                          isRequired
                          {...register('subject')}
                        />

                        <Input
                          nameInput="quantity"
                          type="number"
                          label="Quantidade máxima por aluno"
                          labelSize="lg"
                          error={errors.quantity}
                          defaultValue={classSubjectData.quantity}
                          {...register('quantity')}
                        />
                      </SimpleGrid>
                    </VStack>
                )
              }

              <Flex
                mt="8"
                justify={["normal", "space-between"]}
                flexDir={["column", "row"]}
              >
                <HStack spacing="4">
                  <ButtonDefault
                    text="Excluir Tema"
                    w={["100%", "150px"]}
                    attencion
                    onClick={() => setIsOpenAlert(true)}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />
                </HStack>

                <Flex
                  w={["100%", "auto"]}
                  mt={["6", "0"]}
                  flexDir={["column", "row"]}
                >
                  <Box w={["100%", "auto"]}>
                    <ButtonDefault
                      text="Cancelar"
                      w={["100%", "150px"]}
                      info
                      isLoading={formState.isSubmitting || isSubmitting}
                      onClick={() => window.close()}
                    />
                  </Box>

                  <ButtonDefault
                    type="submit"
                    text="Salvar"
                    w={["100%", "150px"]}
                    ml={["0", "4"]}
                    mt={["6", "0"]}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />
                </Flex>
              </Flex>
            </Box>
          </MainBase>
        </ContentBase>
      </Flex>

      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpenAlert(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="bold" textColor="blue.900">
              Excluir tema
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" textColor="blue.900">
              Tem certeza que deseja excluir este tema?
            </AlertDialogBody>
            <AlertDialogBody textColor="red">
              Não será possível desfazer esta ação depois.
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonDefault text="Cancelar" w="100px" info onClick={() => setIsOpenAlert(false)} />
              <ButtonDefault text="Excluir" w="100px" attencion onClick={handleDelete} ml={3} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
