import { Box, Text, Textarea } from "@chakra-ui/react";
import ReactHtmlParser from "react-html-parser"

interface PurchaseBodyProps {
  description: string;
  hasBalance: boolean;
  hasSubject?: boolean;
  handleChangeSubject?: (event: any) => void;
  canSchedule?: boolean;
}

export function PurchaseBody({
  description,
  hasBalance,
  hasSubject = false,
  handleChangeSubject,
  canSchedule = false,
}: PurchaseBodyProps) {
  const formattedDescription = ReactHtmlParser(description)

  return (
    <>
      <Box marginY="1rem">
        <Text marginTop=".8rem" fontWeight={"bold"} fontSize={"sm"}>DESCRIÇÃO DA AULA</Text>
        <Text marginTop=".3rem" paddingX=".3rem">
          {formattedDescription}
        </Text>
      </Box>

      { hasBalance
        ? <>
            {hasSubject && canSchedule &&
              <Textarea
                mb="4"
                onChange={handleChangeSubject}
                resize="none"
                placeholder="Digite aqui qual assunto deseja ver nessa aula.&#10;Por exemplo: Compras no mercado, montar um curriculo, termos do dia a dia, entre outros..."
                required
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: "var(--chakra-colors-gray-200)",
                    borderRadius: '25px',
                  },
                }}
              />
            }

            { !canSchedule &&
              <Text color="red.500" fontSize="lg" mb="1rem">Não é possível realizar a sua inscrição nesta aula, pois você já está inscrito no máximo de aulas possíveis do mesmo tema dentro de uma semana.</Text>
            }

            <Text>Deseja confirmar a inscrição?</Text>

            <Box mt="1rem" fontSize={"small"} fontStyle={"italic"}>
              <Text>Os administradores analisam os alunos inscritos nas aulas.</Text>
              <Text>Esta analise tem objetivo de avaliar o nível da aula e do aluno e assim garantirmos uma melhor experiência.</Text>
              <Text>Caso encontremos alguma incompatibilidade, sua inscrição poderá ser cancelada.</Text>
              <Text>Mas não se preocupe, os seus créditos serão restituídos e você será notificado do cancelamento.</Text>
            </Box>
          </>
        : <>
            <Text color="red.500" fontSize="md">Infelizmente não possui saldo suficiente para fazer essa aula.</Text>
            <Text fontSize="md">Que tal comprar mais alguns créditos?</Text>
          </>
      }
    </>
  )
}
