import {
  Icon,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  Text,
  useToast
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RiCoinsLine } from "react-icons/ri";
import { useMutation } from "react-query";
import { useHistory } from "react-router";

import { api } from "../../../services/api";
import { useAuth } from '../../../services/hooks/useAuth';
import { queryClient } from "../../../services/queryClient";

import { ButtonDefault } from "../../ButtonDefault";
import { CanceledBody } from "./CanceledBody";
import { Header } from "./Header";
import { PurchaseBody } from "./PurchaseBody";
import { WaitingBody } from "./WaitingBody";
import { CheckErrorCode } from "../../../utils/CheckErrorCode";

type QueueProps = {
  eventId: string;
  sugestion: string;
}

type ScheduleProps = {
  eventId: string;
  subject: string;
}

interface CardModalPros {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  eventId: string;
  duration: string;
  credit: string;
  startDate: string;
  startHour: string;
  waiting?: boolean;
  canceled?: boolean;
  eventHasSubject?: boolean;
  startDateISO: string;
  classSubject?: string | null
}

export function CardModal({
  isOpen,
  onClose,
  title,
  description,
  credit,
  eventId,
  duration,
  startDate,
  startHour,
  waiting = false,
  canceled = false,
  eventHasSubject = false,
  startDateISO,
  classSubject = null
}: CardModalPros) {
  const [isLoading, setIsLoading] = useState(false);
  const [sugestion, setSugestion] = useState('');
  const [canSchedule, setCanSchedule] = useState(false);
  const [subject, setSubject] = useState('');
  const { user } = useAuth();
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    if (isOpen && !canceled) {
      setIsLoading(true)
      try {
        api.get<boolean>(`schedules/can-schedule`, {
          params: {
            eventId,
          }
        }).then(
          response => {
            const { data } = response;
            setCanSchedule(data);
            setIsLoading(false);
          }
        );
      } catch (err: any) {
        toast({
          title: "Opa...",
          description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
          status: "error",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })
        setIsLoading(false)
      }
    }
  }, [toast, isOpen, classSubject, canceled, eventId])

  const creditMessage = Number(credit) <= 1 ? `${credit} crédito` : `${credit} créditos`;

  const createQueue = useMutation(async ({ eventId, sugestion }: QueueProps ) => {
    await api.post(`/queues/${eventId}`, { event_id: eventId, sugestion })
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('events-listlist')
    }
  });

  const createSchedule = useMutation(async ({ eventId, subject }: ScheduleProps ) => {
    await api.post('/schedules', { event_id: eventId, subject });
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('events-listlist')
    }
  });

  const deleteSchedule = useMutation(async (eventId: string) => {
    await api.delete(`/schedules/${eventId}`)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('events-list')
      queryClient.invalidateQueries(['classes-registered', user.username])
    }
  });

  const hasBalance = useMemo(() => {
    return Number(credit) <= user.balance;
  }, [credit, user.balance]);

  const toastMessageError = useCallback(async (title = '', message = '', status = 'error') => (
    toast({
      title: title || "Opa...",
      description: message || "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
      status,
      duration: 3000, // 3 seconds,
      isClosable: true,
      position: "top-right",
    })
  ), [toast]);

  const handleQueue = useCallback(async () => {
    setIsLoading(true)
    try {
      await createQueue.mutateAsync({
        eventId, sugestion
      });

      toast({
        title: "Fila de espera",
        description: "Registro na fila de espera realizada com sucesso!",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setIsLoading(false)

      onClose()
    } catch {
      toastMessageError()
      setIsLoading(false)
    }
  }, [eventId, onClose, toast, sugestion, toastMessageError, createQueue]);

  const handleSubscribe = useCallback(async () => {
    setIsLoading(true)
    try {
      if (eventHasSubject && !subject) {
        toast({
          title: "Assunto obrigatório",
          description: "É necessário que seja informado qual assunto deseja ver na aula",
          status: "warning",
          duration: 4000, // 4 seconds,
          isClosable: true,
          position: "top-right",
        });

        setIsLoading(false);
        return
      }

      await createSchedule.mutateAsync({ eventId, subject });

      queryClient.resetQueries('events-list');

      toast({
        title: "Inscrição",
        description: "Inscrição na aula realizada com sucesso. Em breve receberá um e-mail com alguns instruções. Bons estudos!",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      });

      setIsLoading(false);

      onClose();
    } catch (error: any) {
      const { title, message, status } = CheckErrorCode(error.response.data?.code)

      toastMessageError(title, message, status);
      queryClient.resetQueries('events-list');
      setIsLoading(false);
      onClose();
    }
  }, [
    eventId,
    onClose,
    toast,
    toastMessageError,
    subject,
    createSchedule,
    eventHasSubject
  ])

  const handlePurchase = useCallback(() => {
    history.push('/purchase');
  }, [history]);

  const handleChangeSugestion = useCallback((event) => {
    setSugestion(event.target.value);
  }, []);

  const handleChangeSubject = useCallback((event) => {
    setSubject(event.target.value);
  }, []);

  const handleCanceled = useCallback( async () => {
    setIsLoading(true)
    try {
      await deleteSchedule.mutateAsync(eventId);

      toast({
        title: "Inscrição",
        description: "Inscrição na aula realizada com sucesso. Bons estudos!",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setIsLoading(false)

      onClose()
    } catch (err) {
      toastMessageError()
      setIsLoading(false)
    }
  }, [
    toastMessageError,
    onClose,
    toast,
    eventId,
    deleteSchedule
  ])

  return (
    <Modal
      size="2xl"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom=".3rem">
          <Header title={title} date={startDate} hour={startHour} duration={duration} />
        </ModalHeader>

        <ModalCloseButton color="blue.900" />

        { isLoading
          ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="sm" />
              </Flex>
          )
          : (
            <>
              <ModalBody my="2" fontSize="xl" color="blue.900" maxH="64vh" overflow="auto">
                {!canceled &&
                  <Text
                    fontSize={"md"}
                  >
                    Será deduzido <strong>
                      <Icon as={RiCoinsLine} mr="1" fill="gold" fontSize="20" />{creditMessage}
                    </strong> do saldo disponível.
                    <br />
                    Lembre-se que a data e hora estão conforme o <strong>horário de Brasília</strong>.
                  </Text>
                }

                {canceled &&
                  <CanceledBody
                    description={description}
                    startDate={startDate}
                    startDateISO={startDateISO}
                  />
                }

                {waiting &&
                  <WaitingBody
                    description={description}
                    hasBalance={hasBalance}
                    handleChangeSugestion={handleChangeSugestion}
                  />
                }

                { !waiting && !canceled &&
                  <PurchaseBody
                    description={description}
                    hasBalance={hasBalance}
                    hasSubject={eventHasSubject}
                    handleChangeSubject={handleChangeSubject}
                    canSchedule={canSchedule}
                  />
                }
              </ModalBody>
            </>
          )
        }
        <ModalFooter flexDir={["column", "row"]}>
          <ButtonDefault
            w="100%"
            mr={["0", "3"]}
            mb={["2", "0"]}
            py="7"
            info
            fontSize="large"
            text="Fechar"
            onClick={onClose}
            isLoading={isLoading}
          />
          { waiting
          ? <ButtonDefault
              w="100%"
              py="7"
              fontSize="large"
              text="Confirmar"
              onClick={handleQueue}
              isLoading={isLoading}
            />
          : canceled
          ? <ButtonDefault
              w="100%"
              py="7"
              fontSize="large"
              text="Cancelar inscrição"
              onClick={handleCanceled}
              isLoading={isLoading}
              attencion
            />
          : hasBalance
          ? <ButtonDefault
              w="100%"
              py="7"
              fontSize="large"
              text="Confirmar"
              onClick={handleSubscribe}
              isLoading={isLoading}
              disabled={!canSchedule}
            />
          : <ButtonDefault
              w="100%"
              py="7"
              fontSize="large"
              text="Comprar Créditos"
              onClick={handlePurchase}
              isLoading={isLoading}
            />}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
