import {
  Text,
  Flex,
  Image,
  Stack,
  Link as LinkChakra,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import logoWWA from '../../assets/wwa-branco.png';
import logoPraktika from '../../assets/praktika-branco.png';
import { api } from '../../services/api';

export function Unsubscribe() {
  const [validTypes] = useState(['all', 'news'])
  const location = useLocation();
  const { type } = useParams<{ type: string }>();

  const queryParams = new URLSearchParams(location.search)
  const user_id = queryParams.get('code')

  useEffect(() => {
    if (validTypes.includes(type) && user_id) {
      api.post(`/profile/unsubscribe/${type}`, { user_id })
    }
  }, [validTypes, type, user_id])

  return (
    <Flex
      w="100vw"
      h="100%"
      maxW="100vw"
      minH="100vh"
      justify="center"
      flexDir={["column", null, null, "row"]}
      css={{
        'background-image': 'linear-gradient(135deg, #1e2f50 0%, #3e72ad 99%)'
      }}
    >
      <Stack
        spacing="1"
        align="center"
      >
        <LinkChakra href="https://linktr.ee/WunderweltA" isExternal>
          <Image
            w={"250px"}
            src={logoPraktika}
            alt="PrAktikA"
          />
        </LinkChakra>
        <LinkChakra href="https://linktr.ee/WunderweltA" isExternal>
          <Image
            src={logoWWA}
            alt="Wunderwelt-A"
            w={"150px"}
          />
        </LinkChakra>
        <Text
          fontSize={'3xl'}
          paddingTop='3rem'
        >
          Sua inscrição foi cancelada!
        </Text>
        <Text>
          Caso deseje reativá-la no futuro, basta acessar as configurações do seu perfil.
        </Text>
      </Stack>
    </Flex>
  );
}
