import {
  Box,
  Flex,
  Text,
  Spinner,
  useBreakpointValue
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { Parameter, useParameters } from "../../../services/hooks/useParameters";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import MainActions from "../../../components/MainActions";
import { Column } from "react-table";
import { MainTable } from "../../../components/MainTable";
import { RemoveAccent } from "../../../utils/RemoveAccent";

export function ParametersList() {
  const [searchField, setSearchField] = useState('');
  const [allParameters, setAllParameters] = useState<Parameter[]>([]);

  const { data, isLoading, isFetching, error } = useParameters()

  useEffect(() => {
    if (data) {
      setAllParameters(data)
    }
  }, [data])

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    if (data) {
      if (searchField) {
        const searchFieldWithoutAccent = RemoveAccent(searchField.toLowerCase())
        const parametersFiltered = data.filter(
          parameter => RemoveAccent(parameter.description.toLowerCase()).match(searchFieldWithoutAccent)
        )

        setAllParameters(parametersFiltered)

        return
      }

      setAllParameters(data)
    }
  }, [searchField, data])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const columns: Column<Parameter>[] = [
    {
      Header: "Parâmetro",
      accessor: "reference",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Descrição",
      accessor: "description",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Valor",
      accessor: "value",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
  ];

  const parameterActions = [
    {
      type: 'edit',
      url: '/parameters/update/<id>',
    }
  ]

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Parâmetros"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <MainActions
            handleChangeSearch={handleChangeSearch}
            handleOnPressEnter={handleOnPressEnter}
            handleSearch={handleSearch}
            searchPlaceHolder="Digite a descrição que deseja procurar..."
          />

          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : error ? (
              <Flex direction="column">
                <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter os parâmetros :(</Text>
                <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={allParameters}
                  actions={parameterActions}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
