import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  VStack,
  SimpleGrid,
  Textarea,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query';
import { Select as ChakraReactSelect } from "chakra-react-select";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type CreatePromotionFormData = {
  name: string;
}

type CouponData = {
  id: string;
  code: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type CouponSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

const createPromotionFormSchema = Yup.object().shape({
  message: Yup.string().required('Mensagem obrigatória'),
  promotion_date: Yup.date()
    .required('Data obrigatória')
    .test(
      'min-date',
      `A data não deve ser inferior a ${format(new Date(), 'dd/MM/yyyy')}`,
      async (value) => {
        if (value) {
          return value && format(value, 'yyyy-MM-dd') >= format(new Date(), 'yyyy-MM-dd')
        }

        return true
      }
    ),
})

export function CreatePromotion() {
  const history = useHistory();
  const toast = useToast()

  const couponInputRef = useRef<CouponSelectInputProps>();

  const [couponsData, setCouponsData] = useState<CouponData[]>([]);

  const dateNow = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    api.get("/coupons/list/available").then(response => setCouponsData(response.data))
  }, [])

  const createPromotion = useMutation(async (promotion) => {
    const response = await api.post('promotions', promotion)

    return response.data.promotion;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('promotions')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createPromotionFormSchema)
  })

  const { errors } = formState

  const handleCreateEvent: SubmitHandler<CreatePromotionFormData> = async (values: any) => {
    try {
      await createPromotion.mutateAsync({
        ...values,
        promotion_date: utcToZonedTime(
          values.promotion_date,
          'America/Sao_Paulo'
        ).toISOString(),
        coupon_id: couponInputRef.current?.state.value.value,
      });

      toast({
        title: "Promoção incluída",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/promotions')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase mainTitle="Incluir promoção">
          <Box
            as="form"
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handleCreateEvent)}
          >
            <VStack spacing="8" color="blue.900">
              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <FormControl alignItems="center" isRequired>
                  <FormLabel htmlFor="message">
                    Mensagem
                  </FormLabel>
                  <Textarea
                    placeholder="Preencha com a mensagem adicional que será enviada no e-mail de newsletter"
                    error={errors.message}
                    defaultValue= ''
                    css={{
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: "var(--chakra-colors-gray-200)",
                        borderRadius: '25px',
                      },
                    }}
                    {...register('message')}
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="promotion_date"
                  label="Data da promoção"
                  type="date"
                  labelSize="lg"
                  error={errors.promotion_date}
                  defaultValue={dateNow}
                  isRequired
                  {...register('promotion_date')}
                />

                <FormControl alignItems="center">
                  <FormLabel htmlFor="coupon" mb="4">
                    Cupom
                  </FormLabel>
                  <ChakraReactSelect
                    options={
                      couponsData.map(coupon => {
                        return (
                          { value: coupon.id, label: coupon.code }
                        )
                      })
                    }
                    placeholder="Selecione o cupon"
                    defaultValue=''
                    closeMenuOnSelect={true}
                    error={errors.coupon}
                    ref={couponInputRef}
                  />
                </FormControl>
              </SimpleGrid>
            </VStack>

            <Flex mt="8">
              <Flex
                w="100%"
                spacing={["", "4"]}
                flexDir={["column", "row"]}
                justifyContent={["normal", "flex-end"]}
              >
                <Box w={["100%", "auto"]}>
                  <Link to="/promotions">
                    <ButtonDefault text="Cancelar" w={["100%", "150px"]} info />
                  </Link>
                </Box>

                <ButtonDefault
                  type="submit"
                  text="Salvar"
                  w={["100%", "150px"]}
                  mt={["3", "0"]}
                  ml={["0", "4"]}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
