import { Tag as ChakraTag, useBreakpointValue } from "@chakra-ui/react";

interface TagProps {
  id: string;
  tagName: string;
  tagColor: string;
  tagVariant: string;
}
// outline | subtle | solid
export function Tag({ id, tagName, tagColor, tagVariant, ...rest }: TagProps) {
  const isWideVersion = useBreakpointValue({
    base: true,
    sm: false,
  });

  if (isWideVersion) {
    return (
      <ChakraTag id={id} size="sm" variant={tagVariant} colorScheme={tagColor} {...rest}>
        {tagName}
      </ChakraTag>
    );
  };

  return (
    <ChakraTag id={id} size="md" variant={tagVariant} colorScheme={tagColor}>
      {tagName}
    </ChakraTag>
  );
}
