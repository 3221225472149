import { useQuery } from "react-query";
import { format, zonedTimeToUtc } from "date-fns-tz";

import { api } from "../api";

type CouponProps = {
  id: string;
  code: string;
}

export type Promotion = {
  id: string;
  message: string;
  coupon_id: string;
  user_id: string;
  promotion_date: string;
  created_at: string;
  updated_at: string;
  coupon: CouponProps;
  promotionDateFormatted: string;
  createdAtFormatted: string;
}

export async function getPromotions(): Promise<Promotion[]> {
  const response = await api.get<any>('/promotions')

  const { data } = response

  const promotions = data.map((promotion: Promotion) => {
    return {
      ...promotion,
      promotionDateFormatted: format(
        zonedTimeToUtc(promotion.promotion_date, 'America/Sao_Paulo'),
        'dd/MM/yyyy'
      ),
    }
  })

  return promotions;
}

export function usePromotions() {
  return useQuery(['promotions'], () => getPromotions(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
