import {
  Box,
  Flex,
  VStack,
  SimpleGrid,
  useToast,
  useBreakpointValue,
  FormLabel,
  Switch,
  FormControl,
} from "@chakra-ui/react";
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type CreateProductFormData = {
  name: string;
  description: string;
  value: number;
  amount: number;
  original_value?: number;
  original_amount?: number;
  is_active: boolean;
}

const createProductFormSchema = Yup.object().shape({
  name: Yup.string()
    .required('Nome do produto é obrigatório'),
  description: Yup.string()
    .required('Descrição do produto é obrigatória'),
  value: Yup.number()
    .required('Valor é obrigatório'),
  amount: Yup.number()
    .required('Quantidade é obrigatória'),
})

export function CreateProduct() {
  const history = useHistory();
  const toast = useToast();

  const createProduct = useMutation(async (product: CreateProductFormData) => {
    const response = await api.post('products', product)

    return response.data.product;
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createProductFormSchema)
  })

  const { errors } = formState

  const handleCreateProduct: SubmitHandler<CreateProductFormData> = async (values) => {
    try {
      values.amount = Number(values.amount)
      values.value = Number(values.value)
      values.original_value = Number(values.original_value)
      values.original_amount = Number(values.original_amount)
      await createProduct.mutateAsync(values);

      toast({
        title: "Produto incluído",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/products')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase mainTitle="Incluir produto">
          <Box
            as="form"
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handleCreateProduct)}
          >
            <VStack spacing="8" color="blue.900">
              <SimpleGrid
                templateColumns={{ base: "1fr", md: "15% 25% 1fr" }}
                minChildWidth="248px"
                spacing={["6", "8"]}
                w="100%"
              >
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="is_active" mb="0">
                    Ativo ?
                  </FormLabel>
                  <Switch
                    id="is_active"
                    colorScheme="green"
                    {...register('is_active')}
                  />
                </FormControl>
                <Input
                  nameInput="name"
                  label="Nome do produto"
                  labelSize="lg"
                  error={errors.name}
                  isRequired
                  {...register('name')}
                />
                <Input
                  nameInput="description"
                  label="Descrição"
                  labelSize="lg"
                  error={errors.description}
                  isRequired
                  {...register('description')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="value"
                  label="Valor do produto"
                  labelSize="lg"
                  error={errors.value}
                  isRequired
                  {...register('value')}
                />
                <Input
                  nameInput="amount"
                  label="Quantidade de créditos"
                  labelSize="lg"
                  error={errors.amount}
                  isRequired
                  {...register('amount')}
                />
                <Input
                  nameInput="original_value"
                  label="Valor original para promoção"
                  labelSize="lg"
                  error={errors.original_value}
                  {...register('original_value')}
                />
                <Input
                  nameInput="original_amount"
                  label="Quantidade original para promoção"
                  labelSize="lg"
                  error={errors.original_amount}
                  {...register('original_amount')}
                />
              </SimpleGrid>
            </VStack>

            <Flex mt="8">
              <Flex
                w="100%"
                spacing={["", "4"]}
                flexDir={["column", "row"]}
                justifyContent={["normal", "flex-end"]}
              >
                <Box w={["100%", "auto"]}>
                  <Link to="/products">
                    <ButtonDefault text="Cancelar" w={["100%", "150px"]} info />
                  </Link>
                </Box>

                <ButtonDefault
                  type="submit"
                  text="Salvar"
                  w={["100%", "150px"]}
                  mt={["3", "0"]}
                  ml={["0", "4"]}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
