import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  SimpleGrid,
  Spinner,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from 'react';
import {  useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from "react-query";
import { Select as ChakraReactSelect } from "chakra-react-select";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import { SearchInput } from "../../../components/SearchInput";
import { MainTable } from "../../../components/MainTable";
import { Column } from "react-table";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";

type UpdateCompanyFormData = {
  company_id: string;
  users: any[];
}

type UpdateCompanyMembersFormData = {
  name: string;
  color?: string;
  variant?: string;
}

type CompanyData = {
  id: string;
  name: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type CompanySelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

type CompanyMember = {
  is_member: boolean;
  isChecked: boolean;
  id: string;
  name: string;
  email: string;
  created_at: string;
  registeredDate: string;
}

export function UpdateCompany() {
  const toast = useToast();

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companiesData, setCompaniesData] = useState<CompanyData[]>([]);
  const [users, setUsers] = useState<CompanyMember[]>([]);
  const [showUsers, setShowUsers] = useState<CompanyMember[]>([]);
  const [searchField, setSearchField] = useState('');
  const [companyValue, setCompanyValue] = useState({} as SelectInputProps);

  const companyInputRef = useRef<CompanySelectInputProps>();
  const cancelRef = useRef(null)

  const { id: companyId } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true)
    api.get(`/company-members/companies/${companyId}`).then(response => {
      setCompaniesData(response.data)

      const currentCompany = response.data.find((company: CompanyData) => company.id === companyId)
      setCompanyValue({
        label: currentCompany ? currentCompany.name : '',
        value: currentCompany ? currentCompany.id : ''
      });
    })
  }, [companyId])

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get<CompanyMember[]>(`company-members/find/${companyId}/available`).then(
        response => {
          const { data } = response;

          const members = data.map((member: CompanyMember) => {
            const registeredUTCDate = utcToZonedTime(member.created_at, 'America/Sao_Paulo');

            return {
              ...member,
              isChecked: member.is_member,
              registeredDate: format(registeredUTCDate, 'dd/MM/yyyy'),
            }
          });

          setUsers(members);
          setShowUsers(members);
          setIsLoading(false);
        }
      );
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsLoading(false)
    }
  }, [toast, companyId])

  const updateCompanyMembers = useMutation(async (companyMembers: UpdateCompanyFormData) => {
    const response = await api.post(`/company-members/update`, companyMembers)

    return response.data;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('companies')
    }
  });

  const { handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    context: { level_id: companyId }
  })

  const handleChangeCheckbox = useCallback((e, id) => {
    const newUsers = [...users];
    const newShowUsers = [...showUsers];

    const userIndex = newUsers.findIndex(user => user.id === id);
    const showUserIndex = newShowUsers.findIndex(user => user.id === id);

    newUsers[userIndex].isChecked = e.target.checked;
    newShowUsers[showUserIndex].isChecked = e.target.checked;

    setUsers(newUsers)
    setShowUsers(newShowUsers)
  }, [users, showUsers]);

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value);
  }, []);

  const handleSearch = useCallback(() => {
    if (users) {
      if (searchField) {
        const usersFiltered = users.filter(user =>
          user.name.toLowerCase().match(searchField.toLocaleLowerCase())
        )

        setShowUsers(usersFiltered)

        return
      }

      setShowUsers(users)
    }
  }, [searchField, users])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSearch();
    }
  }, [handleSearch])

  const handleUpdateCompanyMembers: SubmitHandler<UpdateCompanyMembersFormData> = async () => {
    try {
      if (!companyInputRef.current?.state.value.value) {
        toast({
          title: "Campos obrigatórios",
          description: "É necessário selecionar uma empresa.",
          status: "warning",
          duration: 3000, // 3 seconds,
          isClosable: true,
          position: "top-right",
        })

        return
      }

      const members: any[] = []

      users.map(user => {
        if (user.isChecked) {
          members.push(user.id)
        }

        return true
      })

      await updateCompanyMembers.mutateAsync({
        company_id: companyInputRef.current.state.value.value,
        users: members
      })

      toast({
        title: "Grupo alterado",
        description: "Alteração realizada com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleDelete = async () => {
    setIsOpenAlert(false)
    setIsSubmitting(true)
    try {
      await api.delete(`/company-members/${companyId}`)

      toast({
        title: "Grupo excluído",
        description: "Grupo excluído com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      setIsSubmitting(false)

      queryClient.invalidateQueries('companies')

      window.close()
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const columns: Column<CompanyMember>[] = [
    {
      Header: "",
      accessor: "isChecked",
      //  @ts-ignore TYPE NEEDS FIXING
      type: "check",
      handlerCheck: handleChangeCheckbox,
      idCheckRef: 'id',
    },
    {
      Header: "Aluno",
      accessor: "name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "E-mail",
      accessor: "email",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Data Cadastro",
      accessor: "created_at",
      //  @ts-ignore TYPE NEEDS FIXING
      formattedValue: "registeredDate",
      isSortable: true,
    },
  ];

  return (
    <>
      <Flex direction="column" h="100vh">
        { !isWideVersion && <Header /> }

        <ContentBase>
          <Sidebar />

          <MainBase mainTitle="Alterar grupo">
            <Box
              as="form"
              h={["", "80vh"]}
              overflow="auto"
              paddingRight="1"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: "var(--chakra-colors-gray-400)",
                  borderRadius: '25px',
                },
              }}
              onSubmit={handleSubmit(handleUpdateCompanyMembers)}
            >
              {isLoading
                ? (
                  <Flex justify="center">
                    <Spinner color="blue.900" size="xl" />
                  </Flex>
                )
                : (
                  <>
                    <VStack spacing="8" color="blue.900" w="50%" mb="2rem">
                      <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                        <FormControl alignItems="center" isRequired>
                          <FormLabel htmlFor="company" mb="4">
                            Empresa
                          </FormLabel>
                          <ChakraReactSelect
                            options={
                              companiesData.map(company => {
                                return (
                                  { value: company.id, label: company.name }
                                )
                              })
                            }
                            placeholder="Selecione a empresa"
                            defaultValue={companyValue}
                            closeMenuOnSelect={true}
                            ref={companyInputRef}
                          />
                        </FormControl>
                      </SimpleGrid>
                    </VStack>

                    <Box>
                      <Flex
                        w={"100%"}
                        direction={["column", null, null, "row"]}
                        mb="10px"
                      >
                        <SearchInput
                          handleChangeSearch={handleChangeSearch}
                          handleOnPressEnter={handleOnPressEnter}
                          searchPlaceHolder="Digite o nome do aluno que deseja procurar..."
                          showIcon={false}
                        />

                        <ButtonDefault
                          w={["100%", null, null, "100px"]}
                          minW="100px"
                          mt={["2", null, null, "0"]}
                          text="Procurar"
                          onClick={handleSearch}
                        />
                      </Flex>
                      <MainTable
                        columns={columns}
                        data={showUsers}
                      />
                    </Box>
                  </>
                )
              }

              <Flex
                mt="8"
                justify={["normal", "space-between"]}
                flexDir={["column", "row"]}
              >
                <HStack spacing="4">
                  <ButtonDefault
                    text="Excluir Grupo"
                    w={["100%", "150px"]}
                    attencion
                    onClick={() => setIsOpenAlert(true)}
                    isLoading={formState.isSubmitting || isSubmitting}
                  />
                </HStack>

                <Flex
                  w={["100%", "auto"]}
                  mt={["6", "0"]}
                  flexDir={["column", "row"]}
                >
                  <Box w={["100%", "auto"]}>
                    <ButtonDefault
                      text="Cancelar"
                      w={["100%", "150px"]}
                      info
                      isLoading={formState.isSubmitting || isSubmitting}
                      onClick={() => window.close()}
                    />
                  </Box>

                  <ButtonDefault
                    type="submit"
                    text="Salvar"
                    w={["100%", "150px"]}
                    ml={["0", "4"]}
                    mt={["6", "0"]}
                    isLoading={formState.isSubmitting || isSubmitting}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  />
                </Flex>
              </Flex>
            </Box>
          </MainBase>
        </ContentBase>
      </Flex>

      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpenAlert(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="bold" textColor="blue.900">
              Excluir grupo
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" textColor="blue.900">
              Tem certeza que deseja excluir este grupo?
            </AlertDialogBody>
            <AlertDialogBody textColor="red">
              Não será possível desfazer esta ação depois.
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonDefault text="Cancelar" w="100px" info onClick={() => setIsOpenAlert(false)} />
              <ButtonDefault text="Excluir" w="100px" attencion onClick={handleDelete} ml={3} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
