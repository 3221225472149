import { useQuery } from "react-query";
import { differenceInMinutes } from "date-fns";
import pt from 'date-fns/locale/pt';
import { format, utcToZonedTime } from "date-fns-tz";

import { api } from "../api";

interface UseQueuesAvailableProps {
  userName: string;
}

type EventLevel = {
  id: string;
  level: {
    id: string;
    name: string;
    color: string;
    variant: string;
  }
}

type Queue = {
  id: string;
  event_id: string;
  startDateFormatted: string;
  startHourFormatted: string;
  endHourFormatted: string;
  duration: string;
  event: {
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    credit: string;
    event_levels: EventLevel[];
    description_formatted: string;
  };
  dayOfWeek: string;
}

type GetQueuesAvailableResponse = {
  queues: Queue[];
}

export async function getQueuesAvailable(): Promise<GetQueuesAvailableResponse> {
  const { data } = await api.get<any>('/queues/list-available')

  const queues = data.map((queue: Queue) => {
    const startDateTimeZone = utcToZonedTime(queue.event.start_date, 'America/Sao_Paulo')
    const endDateTimeZone = utcToZonedTime(queue.event.end_date, 'America/Sao_Paulo')

    return {
      ...queue,
      startDateFormatted: format(startDateTimeZone, 'dd MMM', { locale: pt }),
      startHourFormatted: format(startDateTimeZone, 'HH:mm'),
      duration: String(differenceInMinutes(endDateTimeZone, startDateTimeZone)),
      dayOfWeek: format(startDateTimeZone, 'ccc', { locale: pt }).toUpperCase(),
    }
  })

  return {
    queues,
  };
}

export function useQueuesAvailable({ userName }: UseQueuesAvailableProps) {
  return useQuery(['queues-available', userName], () => getQueuesAvailable(), {
    staleTime: 1000 * 60 * 10, // 10 min
  })
}
