import { ReactNode } from "react";
import { useCan } from "../services/hooks/useCan";

interface CanProps {
  children: ReactNode;
  isAdmin?: boolean;
  isTeacher?: boolean;
  isCompany?: boolean;
  not?: boolean;
}

export function Can({ children, isAdmin = false, isTeacher = false, isCompany = false, not = false }: CanProps) {
  const userCanSeeComponent = useCan({ isAdmin, isTeacher, isCompany, not });

  if (!userCanSeeComponent) {
    return null;
  }

  return (
    <>
      {children}
    </>
  )
}
