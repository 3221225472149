import { useQuery } from "react-query";
import { differenceInMinutes } from "date-fns";
import pt from 'date-fns/locale/pt';
import { format, utcToZonedTime } from "date-fns-tz";

import { api } from "../api";

export type Event = {
  id: string;
  title: string;
  description: string;
  start_date: string;
  startDateFormated: string;
  startHourFormated: string;
  endHourFormatted: string;
  end_date: string;
  duration: string;
  student_limit: string;
  registered_students: string;
  credit: string;
  minimum_number_of_students: string;
  levels: {
    id: string;
    level_id: string;
    level: {
      name: string;
      color: string;
      variant: string;
    };
  }[];
  waiting: boolean;
  request_subject: boolean;
  dayOfWeek: string;
  has_highlight: boolean;
  for_teachers: boolean;
  description_formatted: string;
  class_subject_id?: string;
}

export async function getClassesList(): Promise<Event[]> {
  const { data } = await api.get<any>('/events/available')

  const events = data.map((event: Event) => {
    const startDateTimeZone = utcToZonedTime(event.start_date, 'America/Sao_Paulo')
    const endDateTimeZone = utcToZonedTime(event.end_date, 'America/Sao_Paulo')

    return {
      ...event,
      startDateFormated: format(startDateTimeZone, 'dd MMM', { locale: pt }),
      startHourFormated: format(startDateTimeZone, 'HH:mm'),
      endHourFormatted: format(endDateTimeZone, 'HH:mm'),
      duration: String(differenceInMinutes(endDateTimeZone, startDateTimeZone)),
      waiting: Number(event.student_limit) <= Number(event.registered_students) && Number(event.student_limit) !== 0,
      dayOfWeek: format(startDateTimeZone, 'ccc', { locale: pt }).toUpperCase(),
    }
  })

  return events;
}

export function useClassesList() {
  return useQuery(['events-list'], () => getClassesList(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
