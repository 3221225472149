import { Box, Spinner, Text } from "@chakra-ui/react";
import { differenceInHours, parseISO } from "date-fns";
import { useState, useEffect, useMemo } from "react";
import ReactHtmlParser from "react-html-parser";

import { api } from "../../../services/api";

interface CanceledBodyProps {
  description: string;
  startDate: string;
  startDateISO: string;
}
type ParameterInfo = {
  value: string;
}

export function CanceledBody({ description, startDate, startDateISO }: CanceledBodyProps) {
  const formattedDescription = ReactHtmlParser(description)
  const [parameter, setParameter] = useState<ParameterInfo>();

  useEffect(() => {
    api.get('/parameters/consult/RefundTimeLimit')
      .then(response => {
        setParameter(response.data)
      })
  }, [])

  const canRefound = useMemo(() => {
    const hoursToStart = differenceInHours(parseISO(startDateISO), new Date())

    if (hoursToStart >= Number(parameter?.value)) {
      return true
    }

    return false
  }, [startDateISO, parameter])

  return (
    <>
    { !!parameter
      ? <>
          <Box marginY="1rem">
            <Text marginTop=".8rem" fontWeight={"bold"} fontSize={"sm"}>DESCRIÇÃO DA AULA</Text>
            <Text marginTop=".3rem" paddingX=".3rem">
              {formattedDescription}
            </Text>
          </Box>

          { !canRefound &&
            <Text mb="4" textColor="yellow.500">
              Lembrando que o prazo de {parameter.value} horas antes do início da aula foi excedido. Com isso, não ocorrerá o reembolso dos créditos dessa aula.
            </Text>
          }

          <Text>
            Tem certeza que deseja cancelar sua inscrição?
          </Text>
        </>
      : <Spinner size="sm" color="gray.500" ml="4" />
    }
    </>
  )
}
