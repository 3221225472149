import { forwardRef, ForwardRefRenderFunction, } from 'react'
import { Input as ChakraInput, FormLabel, FormControl, InputProps as ChakraInputProps, FormErrorMessage } from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

interface InputProps extends ChakraInputProps {
  nameInput: string;
  label?: string;
  labelSize?: string;
  error?: FieldError;
  onSystem?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps>
  = ({ nameInput, label, labelSize, error = null, onSystem = false, isRequired = false, isDisabled = false, ...rest }, ref) => {
  if (onSystem) {
    return (
      <FormControl isInvalid={!!error} isRequired={isRequired}>
        { !!label &&
          <FormLabel
            id={nameInput}
            fontSize="sm"
            htmlFor={nameInput}
            color="blue.900"
          >
            {label}
          </FormLabel>
        }

        <ChakraInput
          id={nameInput}
          name={nameInput}
          color="blue.900"
          focusBorderColor="blue.500"
          bgColor="transparent"
          variant="flushed"
          _hover={{
            borderColor: 'blue.500'
          }}
          size="lg"
          ref={ref}
          {...rest}
        />

        { !!error && (
          <FormErrorMessage>
            {error.message}
          </FormErrorMessage>
        )}
      </FormControl>
    )
  }

  return (
    <FormControl isInvalid={!!error} isRequired={isRequired} isDisabled={isDisabled}>
      { !!label &&
        <FormLabel
          id={nameInput}
          fontSize={labelSize}
          htmlFor={nameInput}
        >
          {label}
        </FormLabel>
      }

      <ChakraInput
        id={nameInput}
        name={nameInput}
        focusBorderColor="#50b683"
        bgColor="transparent"
        variant="flushed"
        _hover={{
          borderColor: '#50b683'
        }}
        size="lg"
        ref={ref}
        {...rest}
      />

      { !!error && (
        <FormErrorMessage>
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)
