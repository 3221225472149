import {
  Box,
  Flex,
  FormControl,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { AccordionList } from "../../components/AccordionList";
import ContentBase from "../../components/ContentBase";
import { Header } from "../../components/Header";
import MainBase from "../../components/MainBase";
import { Sidebar } from "../../components/Sidebar";
import { FQA, useFQAs } from "../../services/hooks/useFQA";
import { useAuth } from "../../services/hooks/useAuth";
import { api } from "../../services/api";
import { ButtonDefault } from "../../components/ButtonDefault";

type SendMailFormData = {
  subject: string;
  description: string;
}

const sendMailFormSchema = yup.object().shape({
  subject: yup.string().required('Assunto é obrigatório'),
  description: yup.string().required('Descrição é obrigatória'),
})

export function Support() {
  const [fqas, setFQAs] = useState<FQA[]>([]);

  const { data, isLoading, isFetching, error } = useFQAs();
  const { user } = useAuth()

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(sendMailFormSchema)
  })
  const { errors } = formState;

  const toast = useToast();

  const handlerSendMail: SubmitHandler<SendMailFormData> = async (values: SendMailFormData) => {
    try {
      await api.post('/fqas/support', {
        ...values,
        user
      })

      reset({
        subject: '',
        description: '',
      })

      toast({
        title: "Dúvida enviada",
        description: "Sua dúvida foi enviada com sucesso, em breve nosso time entrará em contato.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    } catch (err) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  useEffect(() => {
    if (data) {
      setFQAs(data)
    }
  }, [data])

  return (
    <Flex
      direction="column"
      mh="100vh"
    >
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="F.Q.A"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <Box
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : error ? (
              <Flex direction="column">
                <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter as F.Q.A's :(</Text>
                <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
              </Flex>
            ) : (
              <AccordionList
                items={fqas}
              />
            )}
          </Box>

          <Box
            as="form"
            w={["100%"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handlerSendMail)}
          >
            <VStack spacing="8" color="blue.900">
              <SimpleGrid spacing={"2"} w="100%">
                <Heading
                  as="h4"
                  mt="6"
                  size="md"
                >
                  Envie sua dúvida
                </Heading>
                <Input
                  nameInput="subject"
                  placeholder="Assunto"
                  labelSize="lg"
                  error={errors.subject}
                  isRequired
                  {...register('subject')}
                />

                <FormControl alignItems="center">
                  <Textarea
                    minH="80"
                    placeholder="Preencha com os detalhes da dúvida ou problema que possa estar enfrentando..."
                    error={errors.description}
                    css={{
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: "var(--chakra-colors-gray-200)",
                        borderRadius: '25px',
                      },
                    }}
                    {...register('description')}
                  />
                </FormControl>
              </SimpleGrid>
            </VStack>

            <Flex marginY="8">
              <Flex
                w="100%"
                spacing={["", "4"]}
                flexDir={["column", "row"]}
                justifyContent={["normal", "flex-end"]}
              >
                <ButtonDefault
                  type="submit"
                  text="Enviar"
                  w={["100%", "150px"]}
                  mt={["3", "0"]}
                  ml={["0", "4"]}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  )
}
