import { useQuery } from "react-query";
import { differenceInMinutes } from "date-fns";
import pt from 'date-fns/locale/pt';
import { format, utcToZonedTime } from "date-fns-tz";

import { api } from "../api";

interface UseClassesRegisteredProps {
  userName: string;
  filter?: string;
}

type Class = {
  id: string;
  title: string;
  description: string;
  start_date: string;
  startDateFormated: string;
  startHourFormated: string;
  endHourFormatted: string;
  end_date: string;
  duration: string;
  credit: string;
  levels: {
    id: string;
    level: {
      name: string;
      color: string;
      variant: string;
    };
  }[];
  isPrevious: boolean;
  dayOfWeek: string;
  has_highlight: boolean;
  for_teachers: boolean;
  description_formatted: string;
}

type GetClaseesRegisteredResponse = {
  classes: Class[];
}

export async function getClassesRegistered(): Promise<GetClaseesRegisteredResponse> {
  const { data } = await api.get<any>('/events/registered')

  const classes = data.map((classe: Class) => {
    const dateNowUTC = utcToZonedTime(new Date(), 'America/Sao_Paulo')
    const startDateTimeZone = utcToZonedTime(classe.start_date, 'America/Sao_Paulo')
    const endDateTimeZone = utcToZonedTime(classe.end_date, 'America/Sao_Paulo')

    return {
      ...classe,
      startDateFormated: format(startDateTimeZone, 'dd MMM', { locale: pt }),
      startHourFormated: format(startDateTimeZone, 'HH:mm'),
      endHourFormatted: format(endDateTimeZone, 'HH:mm'),
      duration: String(differenceInMinutes(endDateTimeZone, startDateTimeZone)),
      isPrevious: startDateTimeZone < dateNowUTC,
      dayOfWeek: format(startDateTimeZone, 'ccc', { locale: pt }).toUpperCase(),
    }
  })

  return {
    classes,
  };
}

export function useClassesRegistered({ userName }: UseClassesRegisteredProps) {
  return useQuery(['classes-registered', userName], () => getClassesRegistered(), {
    staleTime: 1000 * 60 * 10, // 10 min
  })
}
