import { Heading, Flex, SimpleGrid, Stack, FormControl, FormLabel, Switch, useToast, useBreakpointValue, useDisclosure, Spinner } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';

import { Input } from '../../components/Form/Input';
import { api } from '../../services/api';
import { queryClient } from '../../services/queryClient';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ButtonDefault } from '../../components/ButtonDefault';
import { useAuth } from '../../services/hooks/useAuth';
import { useHistory } from 'react-router';
import ContentBase from '../../components/ContentBase';
import { UnsubscribeAccountModal } from '../../components/UnsubscribeAccountModal';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';

export type ProfileFormData = {
  name: string;
  username: string;
  email: string;
  old_password?: string;
  password?: string;
  street_name: string;
  street_number: string;
  zip_code: string;
  area_code: string;
  phone: string;
  document: string;
  receive_newsletter?: boolean;
  receive_email?: boolean;
  birth_date: string | null;
}

const UsernameValidation = async (value: string | undefined): Promise<boolean> => {
  if (value) {
    const { data } = await api.get(`/profile/valid`, {
      params: {
        field: 'username',
        value,
      }
    })

    return !data
  }

  return true
}

const EmailValidation = async (value: string | undefined): Promise<boolean> => {
  if (value) {
    const { data } = await api.get(`/profile/valid`, {
      params: {
        field: 'email',
        value,
      }
    })

    return !data
  }

  return true
}

const PasswordValidation = async (value: string | undefined): Promise<boolean> => {
  if (value) {
    const { data } = await api.get('/profile/valid/password', {
      params: {
        password: value,
      }
    })

    return data
  }

  return true
}

const signUpFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  username: Yup.string().required('Usuário obrigatório')
    .test(
      'username-exists',
      'Usuário indisponível, favor informar outro.',
      async (value) => (
        await UsernameValidation(value)
      )
    ),
  email: Yup.string()
    .email('Necessário informar um e-mail valido')
    .required('E-mail obrigatório')
    .test(
      'email-exists',
      'E-mail já cadastrado, favor informar outro.',
      async (value) => (
        await EmailValidation(value)
      )
    ),
  old_password: Yup.string()
    .test(
      'password-match',
      'Senha digitada está incorreta.',
      async (value) => (
        await PasswordValidation(value)
      )
    ),
  password: Yup.string().when('old_password', {
    is: (val: string) => !!val.length,
    then: Yup.string().required('Obrigatório informar uma nova senha').min(6, 'No mínimo 6 dígitos'),
    otherwise: undefined,
  }),
  password_confirmation: Yup.string().oneOf([
    null, Yup.ref('password')
  ], 'O valor digitado deve ser igual a Nova senha'),
  birth_date: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value; // Converte string vazia em null
    })
    .max(new Date(), 'A data de nascimento não pode ser maior que a data atual')
    .notRequired(),
})

export function Profile() {
  const [birthDateFormatted, setBirthDateFormatted] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(true)

  const updateProfile = useMutation(async (user: ProfileFormData) => {
    await api.post('profile', user)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('users')
    }
  });

  const history = useHistory();

  const { user, updateUser } = useAuth()

  useEffect(() => {
    if (user.birth_date) {
      setBirthDateFormatted(format(new Date(user.birth_date), 'yyyy-MM-dd'))
    }
    setIsLoading(false)
  }, [user.birth_date])

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(signUpFormSchema)
  })

  const { errors } = formState;

  const toast = useToast()

  const handleUpdateProfile: SubmitHandler<ProfileFormData> = async (values) => {
    try {
      await updateProfile.mutateAsync(values);

      toast({
        title: "Perfil atualizado",
        description: "Os dados do perfil foram atualizados com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
      console.log(values)
      updateUser({
        name: values.name,
        email: values.email,
        username: values.username,
        area_code: values.area_code,
        document: values.document,
        phone: values.phone,
        street_name: values.street_name,
        street_number: values.street_number,
        zip_code: values.zip_code,
        receive_email: values.receive_email,
        receive_newsletter: values.receive_newsletter,
        birth_date: values.birth_date,
      })

      history.push("/")
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <Flex flex="1" my={["2", "4"]} px={["6", "8"]}>
          <Flex
            flex="1"
            borderRadius="5"
            alignItems="center"
            justifyContent="center"
          >
            <Flex
              as="form"
              w="100%"
              h="100%"
              m="0 auto"
              // maxWidth={900}
              p={["4", null, null, "8"]}
              alignItems="center"
              borderRadius={8}
              boxShadow="lg"
              flexDir="column"
              onSubmit={handleSubmit(handleUpdateProfile)}
              overflowY="auto"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: "var(--chakra-colors-gray-400)",
                  borderRadius: '25px',
                },
              }}
            >
              <Heading color="blue.900" mb={["4", "8"]}>Meu perfil</Heading>
              {
                isLoading
                ? (
                  <Flex justify="center">
                    <Spinner color="blue.900" size="xl" />
                  </Flex>
                )
                : (
                  <Stack w="100%" spacing="4">
                    <Input
                      nameInput="name"
                      type="text"
                      label="Nome Completo"
                      error={errors.name}
                      onSystem
                      isRequired
                      defaultValue={user.name}
                      {...register('name')}
                    />
                    <Input
                      nameInput="username"
                      type="text"
                      label="Usuário"
                      error={errors.username}
                      textTransform="lowercase"
                      onSystem
                      isRequired
                      defaultValue={user.username}
                      {...register('username')}
                    />
                    <Input
                      nameInput="email"
                      type="email"
                      label="E-mail"
                      error={errors.email}
                      textTransform="lowercase"
                      onSystem
                      isRequired
                      defaultValue={user.email}
                      {...register('email')}
                    />
                    <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                      <Input
                        nameInput="old_password"
                        type="password"
                        label="Senha atual"
                        error={errors.old_password}
                        onSystem
                        {...register('old_password')}
                      />
                      <Input
                        nameInput="password"
                        type="password"
                        label="Nova senha"
                        error={errors.password}
                        onSystem
                        {...register('password')}
                      />
                      <Input
                        nameInput="password_confirmation"
                        type="password"
                        label="Confirmar nova senha"
                        error={errors.password_confirmation}
                        onSystem
                        {...register('password_confirmation')}
                      />
                    </SimpleGrid>

                    <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                      <Input
                        nameInput="street_name"
                        label="Endereço"
                        error={errors.street_name}
                        defaultValue={user.street_name}
                        onSystem
                        {...register('street_name')}
                      />

                      <Input
                        nameInput="street_number"
                        label="Número"
                        error={errors.street_number}
                        defaultValue={user.street_number}
                        onSystem
                        {...register('street_number')}
                      />

                      <Input
                        nameInput="zip_code"
                        label="CEP"
                        error={errors.zip_code}
                        defaultValue={user.zip_code}
                        onSystem
                        {...register('zip_code')}
                      />
                    </SimpleGrid>

                    <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                      <Input
                        nameInput="area_code"
                        label="DDD"
                        error={errors.area_code}
                        defaultValue={user.area_code}
                        onSystem
                        {...register('area_code')}
                      />

                      <Input
                        nameInput="phone"
                        label="Telefone"
                        error={errors.phone}
                        defaultValue={user.phone}
                        onSystem
                        {...register('phone')}
                      />

                      <Input
                        nameInput="document"
                        label="CPF"
                        error={errors.document}
                        defaultValue={user.document}
                        onSystem
                        {...register('document')}
                      />

                      <Input
                        nameInput="birth_date"
                        label="Data de nascimento"
                        type="date"
                        error={errors.birth_date}
                        defaultValue={birthDateFormatted}
                        onSystem
                        {...register('birth_date')}
                      />
                    </SimpleGrid>
                    <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                      <FormControl display="flex" alignItems="center">
                        <Switch
                          id="receive_newsletter"
                          colorScheme="green"
                          defaultChecked={user.receive_newsletter}
                          {...register('receive_newsletter')}
                        />
                        <FormLabel htmlFor="receive_newsletter" ml="2" mb="0" textColor="blue.900">
                          Aceito receber por e-mail a newsletter semanal com a lista das próximas aulas mais relevantes
                        </FormLabel>
                      </FormControl>
                    </SimpleGrid>
                    <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                      <FormControl display="flex" alignItems="center">
                        <Switch
                          id="receive_email"
                          colorScheme="green"
                          defaultChecked={user.receive_email}
                          {...register('receive_email')}
                        />
                        <FormLabel htmlFor="receive_email" ml="2" mb="0" textColor="blue.900">
                          Aceito receber e-mails de lembrete que a plataforma entende como importante para me enviar
                        </FormLabel>
                      </FormControl>
                    </SimpleGrid>
                  </Stack>
                )
              }

              <ButtonDefault
                type="submit"
                w={["100%", null, null, "20%"]}
                minH="40px"
                mt="6"
                size="lg"
                isLoading={formState.isSubmitting}
                text="Salvar"
              />

              <ButtonDefault
                text="Excluir conta"
                onClick={onOpen}
                w={["100%", null, null, "10%"]}
                minH="40px"
                mt="6"
                size="xs"
                unfocused
                textDecor="underline"
              />
            </Flex>
          </Flex>
        </Flex>
      </ContentBase>

      <UnsubscribeAccountModal
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
}
