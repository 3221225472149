import {
  Box,
  ComponentWithAs,
  Flex,
  FlexProps,
  Icon,
} from "@chakra-ui/react"
import { RiAddLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ButtonDefault } from "../ButtonDefault";
import { DownloadCSV } from "../DownloadCSV";
import { SearchInput } from "../SearchInput";

type LabelKeyObject = {
  label: string;
  key: string;
}
type Headers = LabelKeyObject[] | string[];

type ICSVDownload = {
  csvData: Object[];
  csvHeaders: Headers | undefined
  csvFileName: string | undefined
  csvSeparator?: string | undefined;
}

interface MainActionsProps extends FlexProps {
  handleChangeSearch?: (event: any) => void;
  handleOnPressEnter?: (event: any) => void;
  handleSearch?: () => void;
  searchPlaceHolder?: string;
  createLink?: string;
  csvDownload?: ICSVDownload;
}

const MainActions: ComponentWithAs<"div", MainActionsProps> = ({
  children,
  handleChangeSearch,
  handleOnPressEnter,
  handleSearch,
  searchPlaceHolder = 'Procurar...',
  createLink,
  csvDownload,
  ...rest
}) => {
  return (
    <Flex
      w="100%"
      h={["", null, null, null, "5vh"]}
      borderRadius={8}
      mt={["1", null, null, "2"]}
      mb={["2", null, null, "4"]}
      {...rest}
    >
      <Flex
        w="100%"
        direction={["column", null, null, "row"]}
        alignItems={["inherit", null, null, "center"]}
        justifyContent={["inherit", null, null, "space-between"]}
      >
        {handleSearch &&
          <>
            <Flex
              w={createLink ? ["100%", null, null, "60%", "50%"] : "100%"}
              direction={["column", null, null, "row"]}
            >
              { handleChangeSearch && handleOnPressEnter &&
                <SearchInput
                  handleChangeSearch={handleChangeSearch}
                  handleOnPressEnter={handleOnPressEnter}
                  searchPlaceHolder={searchPlaceHolder}
                />
              }

              <ButtonDefault
                w={["100%", null, null, "100px"]}
                minW="100px"
                mt={["2", null, null, "0"]}
                text="Procurar"
                onClick={handleSearch}
              />
            </Flex>

            {(createLink || csvDownload) &&
              <Box>
                {createLink &&
                  <Link to={createLink}>
                    <ButtonDefault
                      w={["100%", null, null, "110px"]}
                      mr={[null, null, null, "2"]}
                      mt={["6", null, null, "0"]}
                      mb={["2", null, null, "0"]}
                      text="Incluir"
                      leftIcon={<Icon fontSize="20" as={RiAddLine} />}
                    />
                  </Link>
                }
                {csvDownload &&
                  <DownloadCSV
                    text={"Exportar"}
                    data={csvDownload.csvData}
                    headers={csvDownload.csvHeaders}
                    filename={csvDownload.csvFileName}
                    separator={csvDownload.csvSeparator}
                  />
                }
              </Box>
            }
          </>
        }
        {children}
      </Flex>
    </Flex>
  )
}

export default MainActions;
