import { useQuery } from "react-query";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { api } from "../api";
import { CouponContext, CouponContextData } from "../../contexts/CouponContext";
import { useContext } from "react";

export type Coupon = {
  id: string;
  code: string;
  amount: string;
  limit: string;
  expiration_date: string;
  used: string;
  expirationFormated: string;
}

export async function getCoupons(): Promise<Coupon[]> {
  const response = await api.get<any>('/coupons')

  const { data } = response

  const coupons = data.map((coupon: Coupon) => {
    const expirationUTCDate = utcToZonedTime(coupon.expiration_date, 'America/Sao_Paulo');
    return {
      ...coupon,
      expirationFormated: format(expirationUTCDate, 'dd/MM/yyyy'),
    }
  })

  return coupons;
}

export function useCoupons() {
  return useQuery(['coupons'], () => getCoupons(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}

export function useCoupon(): CouponContextData {
  const context = useContext(CouponContext)

  if (!context) {
    throw new Error('useAuth must be used within an CouponProvider');
  }

  return context;
}
