import {
  Box,
  Flex,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Icon,
  Text,
  Badge,
  Checkbox
} from "@chakra-ui/react";
import {
  MdNavigateBefore,
  MdOutlineFirstPage,
  MdOutlineLastPage,
  MdOutlineNavigateNext
} from "react-icons/md";
import {
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiFileCopy2Line,
  RiPencilLine,
} from "react-icons/ri";
import {
  Column,
  Row,
  TableInstance,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Link } from 'react-router-dom';
import { ButtonDefault } from "../ButtonDefault";

type DataTableProps<Data extends object> = {
  data: Data[];
  columns: Column<Data>[];
  actions?: {
    type: string;
    url: string;
  }[];
};

interface ITableInstance extends TableInstance {
  page: Array<Row>
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  pageCount: number;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  state: any;
}

export function MainTable<Data extends object>({
  data,
  columns,
  actions,
}: DataTableProps<Data>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable({ columns, data }, useSortBy, usePagination) as unknown as ITableInstance;

  return (
    <>
      <Box
        w="100%"
        h={["", null, null, "100%"]}
        maxW="100%"
        overflow="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: "var(--chakra-colors-gray-200)",
            borderRadius: '25px',
          },
        }}
        bg="white"
        borderRadius="5px"
      >
        <Table
          size='md'
          color="blue.900"
          {...getTableProps()}
        >
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr
                bgColor="blue.50"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  //  @ts-ignore TYPE NEEDS FIXING
                  if (column.type === "check") {
                    return <Th />
                  } else {
                    return <Th
                      maxW="148px"
                      //  @ts-ignore TYPE NEEDS FIXING
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      //  @ts-ignore TYPE NEEDS FIXING
                      isNumeric={column.isNumeric}
                    >
                      <Flex>
                        {column.render("Header")}
                        <Flex
                          pl="4px"
                        >
                          {/* @ts-ignore TYPE NEEDS FIXING */}
                          {column.isSorted ? (
                            //  @ts-ignore TYPE NEEDS FIXING
                            column.isSortedDesc ? (
                              <RiArrowUpSFill aria-label="sorted descending" size="15px" />
                            ) : (
                              <RiArrowDownSFill aria-label="sorted ascending" size="15px" />
                            )
                          ) : null}
                        </Flex>
                      </Flex>
                    </Th>
                  }
                })}

                {actions?.map((action) => (
                  <Th w="8"/>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  backgroundColor="white"
                  _hover={{
                    backgroundColor: "gray.10"
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    //  @ts-ignore TYPE NEEDS FIXING
                    if (cell.column.type === 'check') {
                      return <Td px="6">
                        <Checkbox
                          colorScheme="green"
                          isChecked={cell.value}
                          //  @ts-ignore TYPE NEEDS FIXING
                          onChange={(e) => cell.column.handlerCheck(e, row.original[cell.column.idCheckRef])}
                        />
                      </Td>
                    }

                    return <Td
                        fontSize="md"
                        {...cell.getCellProps()}
                        //  @ts-ignore TYPE NEEDS FIXING
                        isNumeric={cell.column.isNumeric}
                      >
                        {/* @ts-ignore TYPE NEEDS FIXING */}
                        {cell.column.setStatus
                          ? <Box>
                              <Text fontWeight="bold">
                                {/* @ts-ignore TYPE NEEDS FIXING */}
                                {cell.column.formattedValue ? row.original[cell.column.formattedValue]: cell.render("Cell")}
                              </Text>
                              {/* @ts-ignore TYPE NEEDS FIXING */}
                              { cell.column.setStatus.map((status) => (
                                // @ts-ignore TYPE NEEDS FIXING
                                row.original && row.original[status.field] &&
                                  <Badge mt= "1" variant="solid" bgColor={status.color}>{status.text}</Badge>
                                ))
                              }
                            </Box>
                          : <>
                              {/* @ts-ignore TYPE NEEDS FIXING */}
                              {cell.column.formattedValue ? row.original[cell.column.formattedValue]: cell.render("Cell")}
                            </>
                        }
                      </Td>
                  })}
                  {actions?.map((action) => {
                    if (action.type === 'copy') {
                      return (
                        <Td>
                          {/* @ts-ignore TYPE NEEDS FIXING */}
                          <Link to={action.url.replace('<id>', row.original.id)}>
                            <ButtonDefault
                              p="2"
                              color="white"
                              size="sm"
                              warning
                              leftIcon={<Icon fontSize="14" as={RiFileCopy2Line} />}
                              text="Copiar"
                            />
                          </Link>
                        </Td>
                      )
                    } else if (action.type === 'edit') {
                      return (
                        <Td>
                          {/* @ts-ignore TYPE NEEDS FIXING */}
                          <Link to={action.url.replace('<id>', row.original.id)} target={"_blank"}>
                            <ButtonDefault
                              p="2"
                              color="white"
                              size="sm"
                              info
                              leftIcon={<Icon fontSize="14" as={RiPencilLine} />}
                              text="Editar"
                            />
                          </Link>
                        </Td>
                      )
                    } else {
                      return (<Td />)
                    }
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      <Stack
        direction="row"
        spacing="6"
        mb={["6", null, null, "0"]}
        px="4"
        py="2"
        justify="space-between"
        align="center"
        bg="white"
        borderRadius="5px"
      >
        <Box color="blue.900">
          <strong>Página {pageOptions.length ? pageIndex + 1 : 0} de {pageOptions.length}</strong>
        </Box>
        <Stack
          direction="row"
          spacing="2"
          color="blue.900"
        >
          <IconButton
            icon={<MdOutlineFirstPage />}
            aria-label="First page"
            variant='ghost'
            size='sm'
            fontSize="25px"
            cursor="pointer"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />

          <IconButton
            icon={<MdNavigateBefore />}
            aria-label="Previous page"
            variant='ghost'
            size='sm'
            fontSize="25px"
            cursor="pointer"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />

          <IconButton
            icon={<MdOutlineNavigateNext />}
            aria-label="Next page"
            variant='ghost'
            size='sm'
            fontSize="25px"
            cursor="pointer"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          />

          <IconButton
            icon={<MdOutlineLastPage />}
            aria-label="Last page"
            variant='ghost'
            size='sm'
            fontSize="25px"
            cursor="pointer"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </Stack>
      </Stack>
      {/* <div className="pagination">
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div> */}
    </>
  );
}
