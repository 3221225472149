import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Box,
  Heading,
  AspectRatio,
} from "@chakra-ui/react";
import { FQA } from "../../services/hooks/useFQA";

interface AccordionListProps {
  items: FQA[]
}

export function AccordionList({ items }: AccordionListProps) {
  return (
    <Accordion
      allowToggle
      color="blue.900"
      mt="2"
      height={"100%"}
    >
      {items.map((item) => (
        <AccordionItem key={item.id}>
          <AccordionButton>
            <Box flex='1' textAlign='left'>
              <Heading as='h4' size='md'>
                {item.title}
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel>
            {item.description}

            { item.embed_id &&
              <AspectRatio
                ratio={2}
                mt="2"
              >
                <iframe
                  title="Embedded youtube"
                  src={`https://www.youtube.com/embed/${item.embed_id}`}
                  allowFullScreen
                />
              </AspectRatio>
            }
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
