import { api } from "../../services/api"

async function UserUsernameExist(value?: string, user_id?: string): Promise<boolean> {
  if (value) {
    const { data } = await api.get(`/users/valid`, {
      params: {
        field: 'username',
        value,
        user_id,
      }
    })

    return !data
  }

  return true
}

export { UserUsernameExist }
