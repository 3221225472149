import Papa from 'papaparse';
import { Icon } from "@chakra-ui/react";
import { RiFileExcel2Line } from "react-icons/ri";

import { ButtonDefault } from "../ButtonDefault";

type LabelKeyObject = {
  label: string;
  key: string;
}
type Headers = LabelKeyObject[] | string[];

interface DownloadCSVProps {
  text: string;
  data: Object[];
  headers?: Headers | undefined;
  separator?: string | undefined;
  filename?: string | undefined;
}

interface DataMapping {
  label: string;
  key: string;
}

interface DataObject {
  [key: string]: any;
}

export function DownloadCSV({
  text,
  data,
  headers,
  separator,
  filename
}: DownloadCSVProps) {
  const hasData = data.length > 0

  const csvData = mapDataToLabels(data, headers as DataMapping[])

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(csvData, {
      quotes: false, // Desativa as aspas globalmente
      delimiter: separator, // Delimitador padrão é vírgula
      header: true
    });

    // Criar um link e fazer download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename || "meu-csv.csv";
    link.click();
  };

  return (
    <ButtonDefault
      isDisabled={!hasData}
      text={text}
      w={["100%", null, null, "120px"]}
      info
      leftIcon={<Icon fontSize="20" as={RiFileExcel2Line} />}
      onClick={handleDownloadCSV}
    />
  );
}

function mapDataToLabels(dataArray: DataObject[], mapping: DataMapping[]): DataObject[] {
  return dataArray.map(data => {
    const result: DataObject = {};
    mapping.forEach(({ label, key }) => {
      if (key in data) {
        result[label] = data[key];
      }
    });
    return result;
  });
}
