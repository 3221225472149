import {
  Text,
  Flex,
  Button,
  Image,
  Stack,
  HStack,
  useToast,
  Divider,
  StackDivider,
  Heading,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import logoWWA from '../../assets/wwa-branco.png';
import logoPraktika from '../../assets/praktika-branco.png';
import signUp from '../../assets/signup.svg';
import { Input } from '../../components/Form/Input';
import { api } from '../../services/api';
import { queryClient } from '../../services/queryClient';
import { UserEmailExist } from '../../utils/validations/UserEmailExist';
import { UserUsernameExist } from '../../utils/validations/UserUsernameExist';

type SignUpFormData = {
  name: string;
  username: string;
  email: string;
  is_admin: boolean;
  is_teacher: boolean;
  password: string;
  password_confirmation: string;
}

const signUpFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  username: yup.string().required('Usuário obrigatório')
    .test(
      'username-exists',
      'Usuário indisponível, favor informar outro.',
      async (value) => (
        await UserUsernameExist(value)
      )
    ),
  email: yup.string()
    .email('Necessário informar um e-mail valido')
    .required('E-mail obrigatório')
    .test(
      'email-exists',
      'E-mail já cadastrado, favor informar outro.',
      async (value) => (
        await UserEmailExist(value)
      )
    ),
  password: yup.string().required('Senha obrigatória').min(6, 'No mínimo 6 caracteres'),
  password_confirmation: yup.string().oneOf([
    null, yup.ref('password')
  ], 'As senhas devem ser iguais'),
})

export function SignUp() {
  const createUser = useMutation(async (user: SignUpFormData) => {
    const response = await api.post('users/signUp', user)

    return response.data.user;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('users')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(signUpFormSchema)
  })

  const { errors } = formState;

  const history = useHistory()
  const toast = useToast()

  const handleSignUp: SubmitHandler<SignUpFormData> = async (values) => {
    try {
      await createUser.mutateAsync(values);

      toast({
        title: "Bem vindo!",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  return (
    <Flex
      w="100vw"
      h="100%"
      maxW="100vw"
      minH="100vh"
      justify="center"
      alignItems="center"
      flexDir={["column", null, null, "row"]}
      css={{
        'background-image': 'linear-gradient(135deg, #1e2f50 0%, #3e72ad 99%)'
      }}
    >
      <Flex
        flexDir="column"
        w={["100%", null, null, "50vw"]}
        alignItems={["center", null, null, "end"]}
        justifyContent="center"
        my="8"
        pl={["0", null, null, "4"]}
      >
        <HStack
          divider={<StackDivider borderColor='gray.400' />}
          spacing="4"
          pr={["0", null, null, "16"]}
        >
          <Image
            w={["150px", "200px"]}
            src={logoPraktika}
            alt="PrAktikA"
          />
          <Image
            src={logoWWA}
            alt="Wunderwelt-A"
            w={["150px", "220px"]}
          />
        </HStack>

        <Image
          src={signUp}
          alt="signUp"
          display={["none", null, null, "inherit"]}
        />
      </Flex>

      <Divider
        h="80vh"
        orientation='vertical'
        display={["none", null, null, "inherit"]}
      />

      <Flex
        flexDir="column"
        w={["100%", null, null, "50vw"]}
        alignItems="center"
        justifyContent="center"
        p="8"
      >
        <Flex
          as="form"
          w="100%"
          maxWidth={400}
          p="8"
          alignItems="center"
          bg="#ffffff10"
          backdropBlur="10px"
          zIndex="1"
          borderRadius={8}
          flexDir="column"
          onSubmit={handleSubmit(handleSignUp)}
          overflow="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: "var(--chakra-colors-gray-200)",
              borderRadius: '25px',
            },
          }}
        >
          <Heading mb="10">
            Faça seu cadastro
          </Heading>

          <Stack w="100%" spacing="4">
            <Input
              nameInput="name"
              type="text"
              label="Nome Completo"
              error={errors.name}
              {...register('name')}
            />
            <Input
              nameInput="username"
              type="text"
              label="Usuário"
              error={errors.username}
              textTransform="lowercase"
              {...register('username')}
            />
            <Input
              nameInput="email"
              type="email"
              label="E-mail"
              error={errors.email}
              textTransform="lowercase"
              {...register('email')}
            />
            <Input
              nameInput="password"
              type="password"
              label="Senha"
              error={errors.password}
              {...register('password')}
            />
            <Input
              nameInput="password_confirmation"
              type="password"
              label="Confirmar senha"
              error={errors.password_confirmation}
              {...register('password_confirmation')}
            />
          </Stack>

          <Button
            type="submit"
            w="100%"
            minH="12"
            mt="6"
            bg="#50b683"
            size="lg"
            isLoading={formState.isSubmitting}
            _hover={{
              backgroundColor: "#46a174"
            }}
          >
            Cadastrar
          </Button>

          <Link
            to="/"
          >
            <Text
              mt="8"
              fontSize="sm"
              bgColor="transparent"
              // rightIcon={<Icon fontSize="20" as={RiExternalLinkLine} />}
              _hover={{
                bgColor: "transparent",
                color: "#50b683"
              }}
            >
              Voltar
            </Text>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}
