import {
  Heading,
  Flex,
  SimpleGrid,
  Spinner,
  FormControl,
  Icon,
  Input,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import { FaGlasses } from 'react-icons/fa';
import { MdOutlineSchool } from 'react-icons/md';
import { RiBook2Line, RiCoupon3Line } from 'react-icons/ri';

import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ButtonDefault } from '../../components/ButtonDefault';
import { PurchaseCard } from '../../components/PurchaseCard';
import { Product } from '../../services/hooks/useProducts';
import ContentBase from '../../components/ContentBase';
import MainBase from '../../components/MainBase';
import { useCoupon } from '../../services/hooks/useCoupons';
import { api } from '../../services/api';

export function Purchase() {
  const [products, setProducts] = useState<Product[]>([]);
  const [couponCode, setCouponCode] = useState('');
  const [couponWasApplied, setCouponWasApplied] = useState(false);
  const [couponValue, setCouponValue] = useState(0);
  const [lastCoupon, setLastCoupon] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [columnsQuantity, setColumnsQuantity] = useState(0)

  const { valid, coupon } = useCoupon();

  const handleChangeCoupon = useCallback((event) => {
    setCouponCode(event.target.value)
  }, [])

  useEffect(() => {
    api.get("/products/active")
      .then(response => {
        const { data } = response

        setProducts(data)
        setColumnsQuantity(Math.min(4, (data.length + 1)))
        setIsLoading(false)
      })
      .catch(err => {
        setError(true)
      })
  }, [])

  useEffect(() => {
    if (coupon.code) {
      setCouponValue(Number(coupon.amount));
      setCouponWasApplied(true);
    } else {
      setCouponValue(0);
      setCouponWasApplied(false);
    }
  }, [coupon])

  const handleApplyCoupom = useCallback(async (e) => {
    e.preventDefault()

    if (lastCoupon !== couponCode) {
      await valid(couponCode)

      setLastCoupon(couponCode);
    }
  }, [couponCode, lastCoupon, valid])

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase>
          <Flex flex="1"
            direction="column"
            marginY={["2", null, null, "4"]}
            px={["6", null, null, "8"]}
            overflow={["auto", null, null, null, "inherit"]}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : error ? (
              <Flex direction="column">
                <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter os produtos a venda :(</Text>
                <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
              </Flex>
            ) : (
              <SimpleGrid flex="1" columns={[1,null,2,2,columnsQuantity]} gap="5">
                {products.map((product, index) => {
                  return (
                    <PurchaseCard
                      key={product.id}
                      product_id={product.id}
                      title={product.name}
                      description={product.description}
                      amount={product.amount}
                      value={product.value}
                      icon={index === 0 ? FaGlasses : index === 1 ? RiBook2Line : MdOutlineSchool}
                      discount={couponValue}
                      originalAmount={product.original_amount}
                      originalValue={product.original_value}
                    />
                  )
                })}

                <Flex
                  as="form"
                  w="100%"
                  m={[null, null, null, null, "auto"]}
                  maxH={[null, null, null, null, "80%"]}
                  justify="space-between"
                  p="8"
                  alignItems="center"
                  borderRadius={8}
                  boxShadow="lg"
                  flexDir="column"
                  bg="whitesmoke"
                  onSubmit={handleApplyCoupom}
                >
                  <Icon as={RiCoupon3Line} color="blue.900" fontSize="50" mb="5" />

                  <Heading color="blue.900" mb="5" textAlign="center">Aplicar cupom</Heading>

                  <Text h="145px" color="blue.900" textAlign="center">
                    Possui um cupom de desconto?
                    Então não perca tempo, informe o seu cupom abaixo e aproveite o desconto antes que acabe
                  </Text>

                  { couponWasApplied &&
                    <>
                      <Text as="kbd" color="blue" fontSize="xl">Cupom aplicado</Text>
                      <Text as="kbd" color="blue" fontSize="xl" mt="2" mb="4">{couponValue}% DE DESCONTO</Text>
                    </>
                  }

                  { (!couponWasApplied && lastCoupon) &&
                      <>
                      <Text as="kbd" color="red" fontSize="xl">Cumpo não está disponível</Text>
                    </>
                  }

                  <FormControl
                    alignItems="center"
                  >
                    <Input
                      color="blue.900"
                      placeholder="Coloque aqui seu cupom"
                      size="md"
                      textTransform="uppercase"
                      textAlign="center"
                      onChange={handleChangeCoupon}
                    />
                  </FormControl>

                  <ButtonDefault
                    type="submit"
                    w="100%"
                    mt="6"
                    size="lg"
                    text="Aplicar"
                  />
                </Flex>
              </SimpleGrid>
            )}
          </Flex>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
