import {
  Box,
  Flex,
  VStack,
  SimpleGrid,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";
import { format } from "date-fns";

import { ButtonDefault } from "../../../components/ButtonDefault";
import { Input } from "../../../components/Form/Input";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import { queryClient } from "../../../services/queryClient";
import { CouponCodeExist } from "../../../utils/validations/CouponCodeExist";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";

type CreateCouponFormData = {
  name: string;
}

const createCouponFormSchema = Yup.object().shape({
  code: Yup.string()
    .required('Cupom obrigatório')
    .test(
      'code-exists',
      'Já existe um cupom disponível com este código.',
      async (value) => (
        await CouponCodeExist(value)
      )
    ),
  amount: Yup.number().required('Percentual de desconto obrigatório')
    .min(1, 'No mínimo 1')
    .max(100, 'O percentual não deve ser superior a 100')
    .positive('O valor deve ser superior a 1'),
  limit: Yup.number().required('Quantidade de créditos obrigatório')
    .min(1, 'No mínimo 1 crédito')
    .positive('No mínimo 1 crédito')
    .integer('A quantidade de créditos deve ser um valor inteiro'),
  expiration_date: Yup.date(),
})

export function CreateCoupon() {
  const history = useHistory();
  const toast = useToast()

  const createCoupon = useMutation(async (coupon: CreateCouponFormData) => {
    const response = await api.post('coupons', coupon)

    return response.data.coupon;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('coupons')
    }
  });

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createCouponFormSchema)
  })

  const { errors } = formState

  const dateNow = format(new Date(), 'yyyy-MM-dd');

  const handleCreateCoupon: SubmitHandler<CreateCouponFormData> = async (values) => {
    try {
      await createCoupon.mutateAsync(values);

      toast({
        title: "Cupom incluído",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })

      history.push('/coupons')
    } catch (err: any) {
      toast({
        title: "Opa...",
        description: "Ocorreu uma instabilidade. Por favor tente novamente mais tarde.",
        status: "error",
        duration: 3000, // 3 seconds,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase mainTitle="Incluir cupom">
          <Box
            as="form"
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
            onSubmit={handleSubmit(handleCreateCoupon)}
          >
            <VStack spacing="8" color="blue.900">
              <SimpleGrid minChildWidth="248px" spacing={["6", "8"]} w="100%">
                <Input
                  nameInput="code"
                  label="Cupom"
                  labelSize="lg"
                  error={errors.code}
                  isRequired
                  textTransform="uppercase"
                  {...register('code')}
                />

                <Input
                  nameInput="amount"
                  type="number"
                  defaultValue={1}
                  label="Desconto (%)"
                  labelSize="lg"
                  error={errors.amount}
                  isRequired
                  {...register('amount')}
                />

                <Input
                  nameInput="limit"
                  type="number"
                  defaultValue={1}
                  label="Limite de Uso"
                  labelSize="lg"
                  error={errors.limit}
                  isRequired
                  {...register('limit')}
                />

                <Input
                  nameInput="expiration_date"
                  label="Data de Expiração"
                  type="date"
                  labelSize="lg"
                  error={errors.expiration_date}
                  defaultValue={dateNow}
                  isRequired
                  {...register('expiration_date')}
                />
              </SimpleGrid>
            </VStack>

            <Flex mt="8">
              <Flex
                w="100%"
                spacing={["", "4"]}
                flexDir={["column", "row"]}
                justifyContent={["normal", "flex-end"]}
              >
                <Box w={["100%", "auto"]}>
                  <Link to="/coupons" >
                    <ButtonDefault text="Cancelar" w={["100%", "150px"]} info />
                  </Link>
                </Box>

                <ButtonDefault
                  type="submit"
                  text="Salvar"
                  w={["100%", "150px"]}
                  mt={["3", "0"]}
                  ml={["0", "4"]}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
