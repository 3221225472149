import {
  Box,
  Stat,
  StatArrow,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react"

type Statement = {
  id: string;
  type: string;
  amount: string;
  description: string;
  operation_dateFormated: string;
}

interface ExtractTableProps {
  statements: Statement[];
}

export function ExtractTable({ statements }: ExtractTableProps) {
  return (
    <Box maxW="100%" overflow="auto">
      <Table maxW="100%" overflow="auto" variant="simple" mt="8" color="blue.900">
        <Thead>
          <Tr>
            <Th>Operação</Th>
            <Th>Créditos</Th>
            <Th>Data (horário de Brasília)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {statements.map(statement => (
            <Tr key={statement.id}>
              <Td>{statement.description}</Td>
              <Td>
                <Stat color={statement.type === 'deposit' ? "green.500" : "red.500"}>
                  <StatArrow type={statement.type === 'deposit' ? "increase" : "decrease"} />
                  {statement.amount}
                </Stat>
              </Td>
              <Td color="blue.900">{statement.operation_dateFormated}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
