import { useQuery } from "react-query";

import { api } from "../api";

export type FQA = {
  id: string;
  title: string;
  description?: string;
  embed_id: string;
}

export async function getFQAs(): Promise<FQA[]> {
  const response = await api.get<any>('/fqas')

  const { data } = response

  return data;
}

export function useFQAs() {
  return useQuery(['fqas'], () => getFQAs(), {
    staleTime: 1000 * 60 * 60, // 1 hour
  })
}
