import { useQuery } from "react-query";
import { format, zonedTimeToUtc } from "date-fns-tz";

import { api } from "../api";

export type User = {
  id: string;
  name: string;
  email: string;
  is_admin: boolean;
  is_teacher: boolean;
  inactivation_date?: string;
  createdAt: string;
  isStudentFormatted: string;
  isAdminFormated: string;
  isTeacherFormated: string;
  inactivationDateFormated: string;
  street_name: string;
  street_number: string;
  zip_code: string;
  area_code: string;
  phone: string;
  document_type: string;
  document: string;
  created_at: string;
  createdAtFormatted: string;
  hours: {
    amount: string;
  }[];
  credit: string;
  receive_email: boolean;
  receiveEmail: string;
  birth_date: string;
  birthDateFormatted: string;
}

export async function getUsers(): Promise<User[]> {
  const response = await api.get<any>('/users')

  const { data } = response

  const users = data.map((user: User) => {
    return {
      ...user,
      isStudentFormatted: user.is_admin || user.is_teacher ? 'Não' : 'Sim',
      isAdminFormated: user.is_admin ? 'Sim' : 'Não',
      isTeacherFormated: user.is_teacher ? 'Sim' : 'Não',
      inactivationDateFormated: user.inactivation_date
        ? format(
            zonedTimeToUtc(user.inactivation_date, 'America/Sao_Paulo'),
            'dd/MM/yyyy HH:mm'
          )
        : '  /  /    ',
      createdAtFormatted: format(
        zonedTimeToUtc(user.created_at, 'America/Sao_Paulo'),
        'dd/MM/yyyy'
      ),
      birthDateFormatted: user.birth_date
        ? format(new Date(user.birth_date), 'dd/MM/yyyy')
        : '  /  /    ',
      receiveEmail: user.receive_email ? 'Sim' : 'Não',
    }
  })

  return users;
}

export function useUsers() {
  return useQuery(['users'], () => getUsers(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
