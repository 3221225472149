import { ElementType } from "react";
import { Link as ChackraLink, Icon, Text, LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

interface NavLinkProps extends ChakraLinkProps {
    icon: ElementType;
    children: string;
    href: string;
    shouldMatchExactHref?: boolean;
}

export function NavLink({ icon, children, href, shouldMatchExactHref = false, ...rest }: NavLinkProps) {
    const location = useLocation()
    const { pathname } = location

    let isActive = false;

    if (shouldMatchExactHref && (pathname === href || pathname === rest.as)) {
        isActive = true;
    }

    if (!shouldMatchExactHref &&
        (pathname.startsWith(String(href)) ||
        pathname.startsWith(String(rest.as)))
    ) {
        isActive = true;
    }

    return (
        <Link
          to={href}
          onClick={() => (localStorage.setItem(
            '@praktika:scrollSidebar',
            String(document.querySelector('.content-sidebar')?.scrollTop)
          ))}
        >
            <ChackraLink as="span" color={isActive ? "#50b683" : "gray.50"} display="flex" align="center" {...rest}>
                <Icon as={icon} fontSize="20" />
                <Text ml="4" fontWeight="medium">{children}</Text>
            </ChackraLink>
        </Link>
    )
}
