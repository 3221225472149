import {
  Box,
  Flex,
  Text,
  Spinner,
  useBreakpointValue
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import MainActions from "../../../components/MainActions";
import { Column } from "react-table";
import { MainTable } from "../../../components/MainTable";
import { RemoveAccent } from "../../../utils/RemoveAccent";
import { ClassSubject, useClassSubjects } from "../../../services/hooks/useClassSubjects";

export function ClassSubjectsList() {
  const [searchField, setSearchField] = useState('');
  const [allClassSubjects, setAllClassSubjects] = useState<ClassSubject[]>([]);

  const { data, isLoading, isFetching, error } = useClassSubjects()

  const fileName = `Lista-de-temas-${Date.now()}.csv`;
  const csvHeaders = [
    {
      label: 'Tema',
      key: 'subject'
    },
    {
      label: 'Quantidade por aluno',
      key: 'quantity'
    }
  ]

  useEffect(() => {
    if (data) {
      setAllClassSubjects(data)
    }
  }, [data])

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    if (data) {
      if (searchField) {
        const searchFieldWithoutAccent = RemoveAccent(searchField.toLowerCase())
        const classSubjectsFiltered = data.filter(
          classSubject => RemoveAccent(classSubject.subject.toLowerCase()).match(searchFieldWithoutAccent)
        )

        setAllClassSubjects(classSubjectsFiltered)

        return
      }

      setAllClassSubjects(data)
    }
  }, [searchField, data])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const columns: Column<ClassSubject>[] = [
    {
      Header: "Tema",
      accessor: "subject",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Quantidade por aluno",
      accessor: "quantity",
    }
  ];

  const classSubjectsActions = [
    {
      type: 'edit',
      url: '/class-subjects/update/<id>',
    }
  ]

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Temas para aulas"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <MainActions
            handleChangeSearch={handleChangeSearch}
            handleOnPressEnter={handleOnPressEnter}
            handleSearch={handleSearch}
            searchPlaceHolder="Digite o tema que deseja procurar..."
            createLink="/class-subjects/create"
            csvDownload={{
              csvData: allClassSubjects,
              csvHeaders: csvHeaders,
              csvFileName: fileName,
            }}
          />

          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : error ? (
              <Flex direction="column">
                <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter os temas :(</Text>
                <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={allClassSubjects}
                  actions={classSubjectsActions}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
