import { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser"
import {
  Flex,
  Box,
  Text,
  useBreakpointValue,
  Spinner,
  Divider
} from "@chakra-ui/react";

import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { api } from "../../services/api";

import 'react-day-picker/lib/style.css';
import ContentBase from "../../components/ContentBase";
import MainBase from "../../components/MainBase";
import { useParams } from "react-router-dom";

type NotificationProps = {
  id: string;
  title: string;
  content: string;
  created_at: string;
}

export function Notification() {
  const [notification, setNotification] = useState<NotificationProps>({} as NotificationProps);
  const [isLoading, setIsLoading] = useState(true);

  const { id: notificationId } = useParams<{ id: string }>();

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get(`/notifications/${notificationId}`)
        .then(response => {
          setNotification(response.data);
          setIsLoading(false)
        })
      api.post(`/notifications/${notificationId}`)
    } catch (err) {
      setIsLoading(false)
    }
  }, [notificationId]);

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Informações"
        >
          <Box
            h={["", "86vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
          >
            {isLoading
              ? (
                <Flex justify="center">
                  <Spinner color="blue.900" size="xl" />
                </Flex>
              )
              : (
                <Flex flex="1" my={["2", "4"]} px={["2", "8"]}>
                  <Flex
                    flex="1"
                    borderRadius="5"
                    flexDir={'column'}
                    alignItems="center"
                    background={'white'}
                    maxW={["100%", null, null, "100%"]}
                    margin={'0 auto'}
                    px={["2", "6"]}
                    overflow="auto"
                  >
                    <Text
                      mt={2}
                      color={'blue.900'}
                    >
                      Segue abaixo modelo do e-mail que foi enviado para você
                    </Text>
                    <Divider my={4} maxW="80%"/>
                    <Box
                      background={'white'}
                      color={'blue.900'}
                      maxW="100%"
                    >
                      <Text
                        fontSize={'lg'}
                        fontWeight={'bold'}
                        mb={4}
                      >
                        {notification.title}
                      </Text>
                      { ReactHtmlParser(notification.content) }
                    </Box>
                  </Flex>
                </Flex>
              )
            }
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  )
}
