import {
  Box,
  Flex,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { api } from "../../../services/api";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import MainActions from "../../../components/MainActions";
import { MainTable } from "../../../components/MainTable";
import { Column } from "react-table";

type MemberHistoric = {
  company_name: string;
  student_name: string;
  student_email: string;
  current_balance: number;
  gift_credit: number;
  credits_expired: number;
}

export function SquadEvents() {
  const [searchField, setSearchField] = useState('');
  const [membersHistorics, setMembersHistorics] = useState<MemberHistoric[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fileName = 'historico-da-equipe.csv'

  const csvHeaders = [
    {
      label: 'Empresa',
      key: 'company_name'
    },
    {
      label: 'Aluno',
      key: 'student_name'
    },
    {
      label: 'E-mail',
      key: 'student_email'
    },
    {
      label: 'Saldo Atual',
      key: 'current_balance'
    },
    {
      label: 'Créditos Recebidos',
      key: 'gift_credit'
    },

    {
      label: 'Créditos Expirados',
      key: 'credits_expired'
    }
  ]

  const handleChangeSearch = useCallback((event) => {
    setSearchField(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    setIsLoading(true)

    api.get<any[]>('/company-members/members/historic', {
      params: {
        name: searchField
      }
    }).then(response => {
      setMembersHistorics(response.data);

      setIsLoading(false)
    })
  }, [searchField])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const columns: Column<MemberHistoric>[] = [
    {
      Header: "Empresa",
      accessor: "company_name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Aluno",
      accessor: "student_name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "E-mail",
      accessor: "student_email",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Saldo Atual",
      accessor: "current_balance",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Créditos Recebidos",
      accessor: "gift_credit",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Créditos Expirados",
      accessor: "credits_expired",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
  ];

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Histórico de empresas"
          isLoading={isLoading}
        >
          <MainActions
            handleChangeSearch={handleChangeSearch}
            handleOnPressEnter={handleOnPressEnter}
            handleSearch={handleSearch}
            searchPlaceHolder="Digite o nome da empresa ou usuário que deseja procurar..."
            csvDownload={{
              csvData: membersHistorics,
              csvHeaders: csvHeaders,
              csvFileName: fileName,
            }}
          />

          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={membersHistorics}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
