import { Route as ReactDOMRoute, RouteProps as ReactDOMRouteProps, Redirect } from 'react-router-dom';
import { useAuth } from '../services/hooks/useAuth';

interface RouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean;
    isAdmin?: boolean;
    isCompany?: boolean;
    isOpen?: boolean;
    component: React.ComponentType;
}

export function Route({
  isPrivate = false,
  isAdmin = false,
  isCompany = false,
  isOpen = false,
  component: Component,
  ...rest
}: RouteProps) {
    const { isAuthentivated, user } = useAuth();

    return (
        <ReactDOMRoute
            {...rest}
            render={({ location }) => {
                // Rota privada e estou autenticado E rota de admin e sou admin
                return (isPrivate && isAuthentivated && isAdmin && user.isAdmin) || (isOpen)
                  ? (<Component />)
                  // Rota privada e estou autenticado E rota de admin e não sou admin
                  : isPrivate && isAuthentivated && isAdmin && !user.isAdmin
                    ? (<Redirect to={{ pathname: '/classes-list', state: { from: location }}}/>)
                    // Rota privada e estou autenticado
                    : isPrivate && isAuthentivated && !isAdmin
                      ? (<Component />)
                      // Rota privada e não estou autenticado
                      : isPrivate && !isAuthentivated
                        ? (<Redirect to={{ pathname: '/', state: { from: location }}} />)
                        // Rota não é privada e estou autenticado
                        : !isPrivate && isAuthentivated
                          ? (<Redirect to={{ pathname: '/classes-list', state: { from: location }}} />)
                          : (<Component />)
            }}
        />
    );
}
