import { useQuery } from "react-query";

import { api } from "../api";

export type Level = {
  id: string;
  name: string;
  color: string;
  variant: string;
}

export async function getLevels(): Promise<Level[]> {
  const response = await api.get<any>('/levels')

  const { data } = response

  return data;
}

export function useLevels() {
  return useQuery(['levels'], () => getLevels(), {
    staleTime: 1000 * 10, // 10 seconds
  })
}
