import {
  Box,
  Flex,
  SimpleGrid,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  useBreakpointValue
} from "@chakra-ui/react";
import { useEffect } from "react";

import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { ClassCard } from "../../components/Card"
import { queryClient } from "../../services/queryClient";
import { useAuth } from "../../services/hooks/useAuth";
import { useClassesRegistered } from "../../services/hooks/useClassesRegistered";
import { useQueuesAvailable } from "../../services/hooks/useQueuesAvailable";
import ContentBase from "../../components/ContentBase";
import MainBase from "../../components/MainBase";

export function ClassesRegistered() {
  const { user } = useAuth();
  const userName = user.username;

  const isSmallVersion = useBreakpointValue({
    base: false,
    xl: true,
  })

  useEffect(() => {
    queryClient.resetQueries(['classes-registered', userName])
  }, [userName])

  useEffect(() => {
    queryClient.resetQueries(['queues-available', userName])
  }, [userName])

  const {
    data,
    isLoading,
    isFetching,
    error,
  } = useClassesRegistered({ userName });

  const {
    data: dataQueue,
    isLoading: isLoadingQueue,
    error: errorQueue,
  } = useQueuesAvailable({ userName })

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Minhas aulas"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <Tabs isFitted={!isSmallVersion}>
            <TabList>
              <Tab _focus={{outline: "none"}} textColor="#5a677f">Próximas aulas</Tab>
              <Tab _focus={{outline: "none"}} textColor="#5a677f">Aulas passadas</Tab>
              <Tab _focus={{outline: "none"}} textColor="#5a677f">Fila de espera</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box
                  h={["65vh", "73vh"]}
                  overflow="auto"
                  paddingRight="1"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: "var(--chakra-colors-gray-400)",
                      borderRadius: '25px',
                    },
                  }}
                >
                  { isLoading ? (
                    <Flex justify="center">
                      <Spinner color="blue.900" size="xl" />
                    </Flex>
                  ) : error ? (
                    <Flex direction="column">
                      <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter as aulas disponíveis :(</Text>
                      <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
                    </Flex>
                  ) : (
                    <SimpleGrid flex="1" columns={[1,null,2,2,3,4]} gap="2">
                      {data?.classes.map(event => !event.isPrevious && (
                        <ClassCard
                          key={event.id}
                          eventId={event.id}
                          title={event.title}
                          levels={event.levels}
                          description={event.description}
                          date={event.startDateFormated}
                          startHour={event.startHourFormated}
                          duration={event.duration}
                          canceled
                          credit={event.credit}
                          startDateISO={event.start_date}
                          dayOfWeek={event.dayOfWeek}
                          setHighlight={event.has_highlight}
                          forTeachers={event.for_teachers}
                          descriptionFormatted={event.description_formatted || event.description}
                        />
                      ))}
                    </SimpleGrid>
                  )}
                </Box>
              </TabPanel>

              <TabPanel
                px="0"
              >
                <Box
                  h={["65vh", "73vh"]}
                  overflow="auto"
                  paddingRight="1"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: "var(--chakra-colors-gray-400)",
                      borderRadius: '25px',
                    },
                  }}
                >
                  { isLoading ? (
                    <Flex justify="center">
                      <Spinner />
                    </Flex>
                  ) : error ? (
                    <Flex direction="column">
                      <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter as aulas passadas :(</Text>
                      <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
                    </Flex>
                  ) : (
                    <SimpleGrid flex="1" columns={[1,null,2,2,3,4]} gap="2">
                      {data?.classes.map(event => event.isPrevious && (
                        <ClassCard
                          key={event.id}
                          eventId={event.id}
                          title={event.title}
                          levels={event.levels}
                          description={event.description}
                          date={event.startDateFormated}
                          startHour={event.startHourFormated}
                          duration={event.duration}
                          credit={event.credit}
                          disabled
                          canceled
                          startDateISO={event.start_date}
                          dayOfWeek={event.dayOfWeek}
                          descriptionFormatted={event.description_formatted || event.description}
                        />
                      ))}
                    </SimpleGrid>
                  )}
                </Box>
              </TabPanel>

              <TabPanel>
                <Box
                  h={["65vh", "73vh"]}
                  overflow="auto"
                  paddingRight="1"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: "var(--chakra-colors-gray-400)",
                      borderRadius: '25px',
                    },
                  }}
                >
                  { isLoadingQueue ? (
                    <Flex justify="center">
                      <Spinner />
                    </Flex>
                  ) : errorQueue ? (
                    <Flex direction="column">
                      <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter as aulas em fila de espera :(</Text>
                      <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
                    </Flex>
                  ) : (
                    <SimpleGrid flex="1" columns={[1,null,2,2,3,4]} gap="2">
                      {dataQueue?.queues.map(queue => (
                        <ClassCard
                          key={queue.id}
                          eventId={queue.event_id}
                          title={queue.event.title}
                          levels={queue.event.event_levels}
                          description={queue.event.description}
                          date={queue.startDateFormatted}
                          startHour={queue.startHourFormatted}
                          duration={queue.duration}
                          credit={queue.event.credit}
                          disabled
                          canceled
                          startDateISO={queue.event.start_date}
                          dayOfWeek={queue.dayOfWeek}
                          descriptionFormatted={queue.event.description_formatted || queue.event.description}
                        />
                      ))}
                    </SimpleGrid>
                  )}
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </MainBase>
      </ContentBase>
    </Flex>
  )
}
